import React, { Key, ReactNode } from "react";

import queryString from "query-string";

import Button from "antd/es/button";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Col from "antd/es/col";

import { FilterDropdownProps, FilterValue } from "antd/es/table/interface";

import SearchOutlined from "@ant-design/icons/SearchOutlined";

const filteredInfoFromLocation = (location: any): Record<string, FilterValue | null> => {
    const filteredInfo = queryString.parse(location.search) as Record<string, FilterValue | null>;
    return filteredInfo;
};

const filterDropdown = (setSearch?: (text: string) => void): ((props: FilterDropdownProps) => ReactNode) => {
    const onSearch = (selectedKeys: Key[]) => {
        if (setSearch) {
            const search = selectedKeys.length > 0 ? selectedKeys[0].toString() : "";
            setSearch(search);
        }
    };

    return ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => (
        <div
            style={{ padding: 8, width: 256, display: "flex", flexDirection: "column" }}
            onKeyDown={e => e.stopPropagation()}
        >
            <div style={{ marginBottom: 8 }}>
                <Input
                    placeholder={"Search"}
                    value={selectedKeys[0]}
                    allowClear
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={
                        () => {
                            onSearch(selectedKeys);
                            confirm();
                        }
                    }
                />
            </div>
            <div>
                <Row gutter={8}>
                    <Col span={10}>
                        <Button
                            type="primary"
                            size="small"
                            block
                            icon={<SearchOutlined />}
                            onClick={
                                () => {
                                    onSearch(selectedKeys);
                                    confirm();
                                }
                            }
                        >
                            Search
                        </Button>
                    </Col>
                    <Col span={8}>
                        <Button
                            size="small"
                            type="default"
                            block
                            onClick={
                                () => {
                                    onSearch(selectedKeys);
                                    confirm({ closeDropdown: false });
                                }
                            }
                        >
                            Filter
                        </Button>
                    </Col>
                    <Col span={6}>
                        <Button
                            size="small"
                            type="link"
                            block
                            disabled={selectedKeys.length === 0}
                            onClick={
                                () => {
                                    if (clearFilters) {
                                        clearFilters();
                                    }
                                    if (setSearch) {
                                        setSearch("");
                                    }
                                }
                            }
                        >
                            Reset
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const filterIcon = (filtered: boolean) => (
    <Button
        size="small"
        type={filtered ? "primary" : "default"}
        icon={<SearchOutlined />}
    />
);

type FilterRecordFieldExtractor<T> = (record: T) => string;

const filter = <T, >(getter: FilterRecordFieldExtractor<T>): ((value: string | number | boolean, record: T) => boolean) => {
    return (value, record): boolean => {
        return !!value &&
            !!getter(record) &&
            getter(record).toLowerCase().includes(value.toString().toLowerCase());
    };
};

const TableUtils = {
    filteredInfoFromLocation,
    filterDropdown,
    filterIcon,
    filter
};

export default TableUtils;
