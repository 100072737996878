import React, { useState, useMemo } from 'react';

import { capitalize } from '../../utils';
import { TermDistribution } from '../../utils/tools';

import Checkbox from 'antd/es/checkbox';
import TextArea from 'antd/es/input/TextArea';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Button, { ButtonType } from 'antd/es/button';

import BuildOutlined from '@ant-design/icons/BuildOutlined';
import ArrowDownOutlined from '@ant-design/icons/ArrowDownOutlined';

import HelpMenu from '../HelpMenu';
import Tool, { ToolOutput, ToolSection } from './Tool';
import Select from 'antd/es/select';

interface TermsDistributionAction {
    label: string;
    type?: ButtonType;
    onClick: (output: string[]) => boolean;
}

interface TermsDistributionToolProps {
    kind: 'query' | 'ranking';
    actions?: TermsDistributionAction[];
}

const buildExample = (kind: 'query' | 'ranking', itemSeparator: string): string[] => {
    const combinations = `a,1c2d${itemSeparator}c4d,3b`;
    const groups = [['X, Y'], ['Z', '2z'], ['W'], ['T']];

    const td = new TermDistribution();
    td.query = kind === 'query';

    const outputs = td.build(combinations, groups);

    return [
        `Combinations: ${combinations}`,
        `Input: ${groups.map((g) => g.join(', ')).join('\n       ')}`,
        `Output: ${outputs.join('\n        ')}`,
    ];
};

const TermsDistributionTool: React.FC<TermsDistributionToolProps> = ({ kind, actions }) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [itemSeparator, setItemSeparator] = useState<string>(kind === 'ranking' ? '#' : ',');
    const [combinations, setCombinations] = useState<string>('1');
    const [proximity, setProximity] = useState<undefined | string>(undefined);
    const [reverse, setReverse] = useState<boolean>(false);
    const [text, setText] = useState<string>('');
    const [output, setOutput] = useState<undefined | string[]>(undefined);

    const helpMenu = useMemo(
        () => (
            <HelpMenu
                placement="bottom"
                sections={[
                    {
                        label: (
                            <>
                                Max number of combinated elements or fixed combinations. Fixed
                                combinations format uses letters (a,b,c,...) for groups and wanted
                                proximity in-between. Combinations are separated by{' '}
                                {`'${itemSeparator}'`}.<br />
                                For example:
                            </>
                        ),
                        items: buildExample(kind, itemSeparator),
                    },
                ]}
            />
        ),
        [kind, itemSeparator]
    );

    const process = () => {
        const td = new TermDistribution();

        td.query = kind === 'query';
        td.reverse = reverse;

        const items = text.split(/\n/).map((item) =>
            item
                .split(itemSeparator)
                .map((it) => it.trim())
                .filter((it) => !!it && it.length > 0)
        );

        const outputs = td.build(combinations, items, proximity);
        setOutput(outputs);
    };

    const title = `${capitalize(kind)} Terms Distribution`;

    const show = () => {
        setVisible(true);
    };

    const hide = () => {
        setVisible(false);
        setCombinations('1');
        setProximity(undefined);
        setReverse(false);
        setText('');
        setOutput(undefined);
    };

    return (
        <Tool
            title={title}
            width={'80vw'}
            icon={<BuildOutlined />}
            visible={visible}
            onShow={show}
            onHide={hide}
            actions={
                actions &&
                actions.map((action, i) => (
                    <Button
                        key={i}
                        type={action.type}
                        disabled={!output}
                        onClick={() => action.onClick(output || []) && hide()}
                    >
                        {action.label}
                    </Button>
                ))
            }
        >
            <div className="patsearch-tool">
                <ToolSection className="ant-form ant-form-horizontal">
                    <Row className="section" gutter={16}>
                        <Col flex="auto">
                            <Form.Item label="Combination">
                                <Input
                                    value={combinations}
                                    onChange={(event) => setCombinations(event.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col>{helpMenu}</Col>
                        <Col flex="200px">
                            <Form.Item label="Proximity">
                                <Input
                                    allowClear
                                    value={proximity}
                                    onChange={(event) => setProximity(event.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Reverse">
                                <Checkbox
                                    checked={reverse}
                                    onChange={(event) => setReverse(event.target.checked)}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Sep">
                                <Select
                                    value={itemSeparator}
                                    options={[
                                        {
                                            value: '#',
                                            label: '#',
                                        },
                                        {
                                            value: ',',
                                            label: ',',
                                        },
                                        {
                                            value: '-',
                                            label: '-',
                                        },
                                        {
                                            value: '_',
                                            label: '_',
                                        },
                                        {
                                            value: '&',
                                            label: '&',
                                        },
                                    ]}
                                    style={{ width: '58px' }}
                                    onChange={(sep) => setItemSeparator(sep)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </ToolSection>

                <ToolSection>
                    <TextArea rows={5} value={text} onChange={(e) => setText(e.target.value)} />
                </ToolSection>

                <ToolSection flex centered>
                    <Button type="primary" onClick={process} icon={<ArrowDownOutlined />}>
                        Create Terms Distribution
                    </Button>
                </ToolSection>

                <ToolOutput values={output} separator={kind === 'query' ? '\u00A0' : ';'} />
            </div>
        </Tool>
    );
};

export default TermsDistributionTool;
