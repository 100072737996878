import React, { useCallback, useEffect, useRef, useState, } from 'react';

import update from 'immutability-helper';

import Form, { FormInstance } from 'antd/es/form';
import Input from 'antd/es/input';
import Select from 'antd/es/select';

import DragOutlined from '@ant-design/icons/DragOutlined';
import TaskFormItem, { ParamItemProps } from '../../tasks/TaskFormItem';
import {
    DndProvider,
    DropTargetMonitor,
    useDrag,
    useDrop
} from 'react-dnd';
import { XYCoord } from 'dnd-core';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface FilterInputProps {
    initialValue?: any;
    value?: any;
    onChange?: (data: any) => void;
    onSetValue?: (value: string | null | undefined) => void;
}

const FilterInput: React.FC<FilterInputProps> = ({
    initialValue,
    value,
    onChange,
    onSetValue
}) => {
    /*
     * ACHTUNG(lb): onSetValue must be a memoraized callback to prevent maximum update depth exceed.
     */
    useEffect(() => {
        const inputValue = value && value.value && value.value.text;
        if (onSetValue) {
            onSetValue(inputValue);
        }
    }, [value, onSetValue]);

    const _onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value
            ? { text: event.target.value }
            : null;
        if (onChange) {
            onChange({ value: v });
        }
        if (onSetValue) {
            onSetValue(event.target.value);
        }
    };

    const inputValue = value && value.value && value.value.text;

    return (
        <Input
            defaultValue={inputValue}
            onChange={_onInputChange}
        />
    );
};

interface FilterSelectValue {
    label: string;
    value: string;
}

interface FilterOption {
    name: string;
    value: string;
    values: FilterSelectValue[];
}

interface FilterSelectProps {
    id: any;
    name: string;
    index: number;
    options: FilterOption[];
    items: FilterSelectValue[];
    onChange: (name: string, value: string) => void;
    onMove: (dragIndex: number, hoverIndex: number) => void
}

const FilterOptionsValues = {
    AUTHORITY_ASC: 'pat_auth_ranking asc',
    AUTHORITY_DESC: 'pat_auth_ranking desc',
    KIND_CODE_ASC: 'pat_kind_code_ranking_a_b asc',
    KIND_CODE_DESC: 'pat_kind_code_ranking_b_a asc',
    FILING_DATE_ASC: 'pat_filing_date asc',
    FILING_DATE_DESC: 'pat_filing_date desc',
    PUBLICATION_DATE_ASC: 'pat_publn_date asc',
    PUBLICATION_DATE_DESC: 'pat_publn_date desc',
};

const FilterOptionsDef: FilterOption[] = [
    {
        name: 'authority',
        value: 'disabled',
        values: [
            {
                label: 'Disabled',
                value: 'disabled'
            },
            {
                label: 'Ascending (US, EP, WO, Others)',
                value: FilterOptionsValues.AUTHORITY_ASC
            },
            {
                label: 'Descending (Others, WO, EP, US)',
                value: FilterOptionsValues.AUTHORITY_DESC
            },
        ]
    },
    {
        name: 'kind_code',
        value: 'disabled',
        values: [
            {
                label: 'Disabled',
                value: 'disabled'
            },
            {
                label: 'Ascending (B, A, Others)',
                value: FilterOptionsValues.KIND_CODE_ASC
            },
            {
                label: 'Descending (A, B, Others)',
                value: FilterOptionsValues.KIND_CODE_DESC
            }
        ]
    },
    {
        name: 'filing_date',
        value: 'disabled',
        values: [
            {
                label: 'Disabled',
                value: 'disabled'
            },
            {
                label: 'Newer First',
                value: FilterOptionsValues.FILING_DATE_DESC
            },
            {
                label: 'Older First',
                value: FilterOptionsValues.FILING_DATE_ASC
            }
        ]
    },
    {
        name: 'publication_date',
        value: 'disabled',
        values: [
            {
                label: 'Disabled',
                value: 'disabled'
            },
            {
                label: 'Newer First',
                value: FilterOptionsValues.PUBLICATION_DATE_DESC
            },
            {
                label: 'Older First',
                value: FilterOptionsValues.PUBLICATION_DATE_ASC
            }
        ]
    }
];

const getOption = (options: FilterOption[], key: string): FilterOption => {
    const opt = options.find(o => o.name === key);
    if (!opt) {
        return {
            name: key,
            value: 'disabled',
            values: []
        };
    }

    return opt;
};

const addOption = (options: FilterOption[], key: string, value: string) => {
    // debugger;
    let opt = options.find(o => o.name === key);
    if (!opt) {
        const defOpt = FilterOptionsDef.find(o => o.name === key);
        opt = defOpt ? { ...defOpt } : { name: key, value, values: [] };
        options.push(opt);
    }
    opt.value = value;
};

const updateOption = (options: FilterOption[], key: string, value: string): FilterOption[] => {
    const opt = options.find(o => o.name === key);
    if (opt) {
        opt.value = value;
    }

    return options;
};

const filterToString = (options: FilterOption[]): string => JSON.stringify(options.filter(opt => opt.value !== 'disabled').map(opt => opt.value));

const filterFromString = (value: string | null | undefined): FilterOption[] => {
    const json = value ? JSON.parse(value) as string[] : [];
    const options: FilterOption[] = [];
    let notFound = ['authority', 'kind_code', 'filing_date', 'publication_date'];

    // debugger;
    json.forEach(v => {
        switch (v) {
            case FilterOptionsValues.AUTHORITY_ASC:
            case FilterOptionsValues.AUTHORITY_DESC:
                addOption(options, 'authority', v);
                notFound = notFound.filter(name => name !== 'authority');
                break;
            case FilterOptionsValues.KIND_CODE_ASC:
            case FilterOptionsValues.KIND_CODE_DESC:
                addOption(options, 'kind_code', v);
                notFound = notFound.filter(name => name !== 'kind_code');
                break;
            case FilterOptionsValues.FILING_DATE_ASC:
            case FilterOptionsValues.FILING_DATE_DESC:
                addOption(options, 'filing_date', v);
                notFound = notFound.filter(name => name !== 'filing_date');
                break;
            case FilterOptionsValues.PUBLICATION_DATE_ASC:
            case FilterOptionsValues.PUBLICATION_DATE_DESC:
                addOption(options, 'publication_date', v);
                notFound = notFound.filter(name => name !== 'publication_date');
                break;
        }
    });

    notFound.forEach(name => addOption(options, name, 'disabled'));

    return options;
};

interface DragItem {
    index: number
    id: string
    type: string
}

const FilterSelectWrapperStyle = {
    // border: '1px dashed gray',
    // padding: '0.5rem 1rem',
    // backgroundColor: '#fed0d0',
    // paddingTop: '8px',
    // paddingBottom: '8px',
    // marginBottom: '4px',
    cursor: 'move',
};

const FilterSelect: React.FC<FilterSelectProps> = ({ id, name, index, options, items = [], onChange, onMove }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId, isOver, dropClassName }, drop] = useDrop({
        accept: 'FilterSelect',
        collect(monitor) {
            const { index: dragIndex }: any = monitor.getItem() || {};
            return {
                handlerId: monitor.getHandlerId(),
                isOver: (dragIndex !== index) && monitor.isOver(),
                dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
            };
        },
        hover(item: DragItem, monitor: DropTargetMonitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            // Determine mouse position
            const clientOffset = monitor.getClientOffset();

            // Get pixels to the top
            const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            // Time to actually perform the action
            onMove(dragIndex, hoverIndex);

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: 'FilterSelect',
        item: () => {
            return { id, index };
        },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        }),
    });

    drag(drop(ref));

    const opacity = isDragging ? 0 : 1;
    const label = (
        <div className='drag-label'>
            <span className='drag-icon'>
                <DragOutlined />
            </span>
            <span>
                {TaskFormItem.labelFromName(name)}
            </span>
        </div>
    );
    return (
        <div ref={ref} data-handler-id={handlerId}>
            <Form.Item
                className={`ant-row ant-form-item drop-target ${isOver ? dropClassName : ''}`}
                style={{ ...FilterSelectWrapperStyle, opacity }}
                label={label}>
                <Select value={getOption(options, name).value} onChange={(value) => onChange(name, value)}>
                    {
                        items.map(item => (
                            <Select.Option value={item.value} key={item.value}>
                                {item.label}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
        </div>
    );
};

const TaskFormFilterItem: React.FC<ParamItemProps & { form: FormInstance }> = ({ form, name, input, hiddenFields }) => {
    const [options, setOptions] = useState<FilterOption[]>([...FilterOptionsDef]);

    const moveOption = useCallback((dragIndex: number, hoverIndex: number) => {
        const dragOption = options[dragIndex];
        const newOptions = update(options, {
            $splice: [[dragIndex, 1], [hoverIndex, 0, dragOption]]
        });
        form.setFieldsValue({ [name]: { value: { text: filterToString(newOptions) } } });
        setOptions(newOptions);
    }, [options, name, form]);

    const onSetValue = useCallback((value) => {
        setOptions(filterFromString(value));
    }, [setOptions]);

    const label = TaskFormItem.labelFromName(name);

    const updateFormItem = (key: string, value: string) => {
        const newOptions = updateOption([...options], key, value);
        form.setFieldsValue({ [name]: { value: { text: filterToString(newOptions) } } });
        setOptions(newOptions);
    };

    return (
        <div className='filter-input'>
            <TaskFormItem.Concealer name={name} hiddenFields={hiddenFields}>
                <DndProvider backend={HTML5Backend}>
                    {
                        options.map(
                            (option, index) => (
                                <FilterSelect
                                    id={`filter_${option.name}`}
                                    key={`filter_${option.name}`}
                                    index={index}
                                    name={option.name}
                                    options={options}
                                    items={option.values}
                                    onChange={updateFormItem}
                                    onMove={moveOption}
                                />
                            )
                        )
                    }
                </DndProvider>

                <TaskFormItem.Concealer name={name} hiddenFields={[name]}>
                    <Form.Item label={label} name={name}>
                        <FilterInput
                            initialValue={input.defaultValue}
                            onSetValue={onSetValue}
                        />
                    </Form.Item>
                </TaskFormItem.Concealer>

            </TaskFormItem.Concealer>
        </div>
    );
};

export { TaskFormFilterItem };
