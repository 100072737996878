import { useEffect, useState } from "react";

import Form from "antd/es/form";
import message from "antd/es/message";

type FetchFn<T> = (id: any) => Promise<T>;

type ConfigurationFn = () => Promise<any>;

export const useDForm = <T>(id: any, configurationFn: ConfigurationFn, fetchFn: FetchFn<T>) => {
    const [configuration, setConfiguration] = useState([]);
    const [initialValues, setInitialValues] = useState<T>();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    useEffect(() => {
        let isSubsxribed = true;
        configurationFn().then(setConfiguration);

        if (id) {
            setIsLoading(true);
            setHasError(false);

            fetchFn(id)
                .then(
                    (data: any) => {
                        setInitialValues(data);
                        form.resetFields();
                    }
                )
                .catch(
                    (error) => {
                        message.error(`Error: ${error.message}`);
                        setHasError(true);
                    }
                )
                .finally(
                    () => setIsLoading(false)
                );
        }

        return () => {
            isSubsxribed = false;
        };
    }, [id, configurationFn, fetchFn, form]);

    return { form, isLoading, hasError, configuration, initialValues };
};
