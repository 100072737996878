import React from "react";

import Popover from "antd/es/popover";
import Tag from "antd/es/tag";

import { Grammar } from "./grammar";
import { GrammarItem } from "./types";

interface RankingGrammarPreviewProps {
    grammar: Grammar | null | undefined;
    group: number;
    value: number | string;
}

interface GrammarItemElementProps {
    item: GrammarItem;
}

const GrammarItemElement: React.FC<GrammarItemElementProps> = ({ item }) => {
    let color = "default";
    if (item.weight === 0.0) {
        color = "warning";
    }
    if (item.weight < 0.0) {
        color = "error";
    }
    if (item.weight > 100.0) {
        color = "processing";
    }

    return (
        <Tag color={color}>
            {item.weight}
        </Tag>
    );
};

interface GrammarItemTableProps {
    items: GrammarItem[];
}

const GrammarItemTable: React.FC<GrammarItemTableProps> = ({ items }) => {
    return (
        <div className="grammar-item-table-wrapper">
            <div className="ant-table ant-table-small ant-table-bordered">
                <div className="ant-table-container">
                    <div className="ant-table-content">
                        <table className="grammar-item-table">
                            <tbody className="ant-table-tbody">
                                {
                                    items.map((item, idx) => (
                                        <tr key={idx} className="ant-table-row ant-table-row-level-0">
                                            <td className="grammar-item-text ant-table-cell">
                                                {item.text}
                                            </td>
                                            <td className="grammar-item-weight ant-table-cell">
                                                <GrammarItemElement item={item} />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const RankingGrammarPreview: React.FC<RankingGrammarPreviewProps> = ({ grammar, group, value }) => {
    if (!grammar || !grammar.hasGroup(group)) {
        return (
            <div>
                {value}
            </div>
        );
    }

    const items: GrammarItem[] = grammar[group];
    return (
        <Popover
            content={<GrammarItemTable items={items} />}
            trigger={["click"]}
            placement="topRight"
            destroyTooltipOnHide={{ keepParent: false }}
        >
            <div className="ranking-value-with-group">
                {value}
            </div>
        </Popover>
    );
};

export default RankingGrammarPreview;
