import React, { ReactNode, useContext, useEffect, useState } from 'react';

import groupBy from 'lodash/groupBy';

import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { AppContext } from '../../contexts';

import { Id, Parameter, ParameterViewProps, ParamTask, UserProject } from '../../constants/types';
import { ColumnsType, SortOrder } from 'antd/lib/table/interface';

import {
    deleteParameter,
    getParameters,
    downloadParameterFile,
    saveParamAndValue,
    saveAsNewParamAndValue,
    copyParam,
} from '../../api';

import Table from 'antd/es/table';
import message from 'antd/es/message';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Tooltip from 'antd/es/tooltip';
import Modal from 'antd/es/modal';

import EditOutlined from '@ant-design/icons/EditOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';
import LikeTwoTone from '@ant-design/icons/LikeTwoTone';
import SendOutlined from '@ant-design/icons/SendOutlined';

import { PageWrapper } from '../../components/PageWrapper';

import Link from '../../components/Link';
import DeleteButton from '../../components/DeleteButton';

import { ParameterPreview } from '../../components/parameters';
import Formatter from '../../components/Formatter';
import Metadata from '../../components/Metadata';
import TableUtils from '../../components/TableUtils';
import R2Highlighter from '../../components/R2Highlighter';
import { useFilters } from '../../hooks/useFilters';
import Typography from 'antd/es/typography';

const CRUBS = [{ label: 'Parameters' }];

interface ParameterGroup extends Parameter {
    children?: Parameter[];
}

const showTotalText = (total: number) => `Found ${total} parameters`;

const sorter = (a: Parameter, b: Parameter) => (a.id || 0) - (b.id || 0);

const rowClassName = (record: ParameterGroup, index: number, indent: number) =>
    record.parentId === record.id && indent > 0 ? 'success' : '';

interface ParameterDescItemProps {
    label: string;
    content: ReactNode | string | null;
}

const ParameterDescItem: React.FC<ParameterDescItemProps> = ({ label, content }) => (
    <div className="desc-item">
        <div className="desc-item-label">{label}</div>
        <div className="desc-item-content">{content}</div>
    </div>
);

interface ParamTaskItemProps {
    paramTask: ParamTask;
}

const ParamTaskItem: React.FC<ParamTaskItemProps> = ({ paramTask }) => (
    <Tooltip title={paramTask.task.label}>
        <div className="param-task-item">{paramTask.task.id}</div>
    </Tooltip>
);

interface ParamTaskItemsProps {
    paramTasks: ParamTask[] | undefined | null;
    type: 'IN' | 'OUT';
}

const ParamTaskItems: React.FC<ParamTaskItemsProps> = ({ paramTasks, type }) => {
    const filtered = paramTasks ? paramTasks.filter((pt: ParamTask) => pt.type === type) : ([] as ParamTask[]);
    return (
        <>
            {filtered.length > 0
                ? filtered.map((pt: ParamTask, i: number) => <ParamTaskItem paramTask={pt} key={i} />)
                : '---'}
        </>
    );
};

interface ParameterCardProps extends ParameterViewProps {
    search: string;
}

const ParameterCard: React.FC<ParameterCardProps> = ({ param, search }) => {
    const navigate = useNavigate();

    const label = <R2Highlighter search={search} text={param.label} />;

    const header = (
        <Space>
            <Link label={label} onClick={() => navigate(`/parameters/${param.id}`)} />
            {param.relevant ? <LikeTwoTone /> : null}
        </Space>
    );

    return (
        <div className="space-between parameter-card">
            <div className="desc">
                <div className="desc-header">{header}</div>
                <div className="desc-items">
                    <ParameterDescItem label="Created" content={Formatter.datetime(param.created)} />
                    <ParameterDescItem label="Modified" content={Formatter.datetime(param.modified)} />
                </div>
                <div className="desc-items">
                    <ParameterDescItem
                        label="As Input"
                        content={<ParamTaskItems paramTasks={param.tasks} type={'IN'} />}
                    />
                    <ParameterDescItem
                        label="As Output"
                        content={<ParamTaskItems paramTasks={param.tasks} type={'OUT'} />}
                    />
                </div>
            </div>
            <div>
                <Metadata obj={param} />
                <ParameterPreview param={param} />
            </div>
        </div>
    );
};

const labelRender = (search: string) => (value: string, record: Parameter) =>
    <ParameterCard param={record} search={search} />;

const typeFilter = (value: string | number | boolean, record: Parameter) => record.paramType.name === value;

const getGroupedParameters = (projectId: Id | undefined) => async () => {
    const params = await getParameters({ params: { projectId } }).then((ps) =>
        ps.filter((param) => !param.paramType.internal)
    );

    const grouped: ParameterGroup[] = [];

    Object.entries(groupBy(params, 'parentId')).forEach(([_, ps]) => {
        const p = ps.pop() as ParameterGroup;
        if (ps.length > 0) {
            p.children = ps;
        }
        grouped.push(p);
    });

    return grouped;
};

interface MoveParamModalProps {
    param?: Parameter | null;
    onCancel: () => void;
    onMove: (project: UserProject) => void;
    onCopy: (project: UserProject) => void;
}

const MoveParamModal: React.FC<MoveParamModalProps> = ({ param, onCancel, onMove, onCopy }) => {
    const { projects, activeProjectId, activeProject } = useContext(AppContext);
    const [selectedProject, setSelectedProject] = useState<UserProject | null | undefined>(activeProject);

    useEffect(() => {
        setSelectedProject((value) => {
            return activeProject;
        });
    }, [activeProject]);

    const title = (
        <Typography.Text ellipsis={true}>
            {param ? `Move Parameter "#${param.id} - ${param.label}"` : 'Move Parameter'}
        </Typography.Text>
    );

    // const title = param ? `Move Parameter "#${param.id} - ${param.label}"` : 'Move Parameter';

    const cancel = () => {
        setSelectedProject(activeProject);
        onCancel();
    };

    const disabled = !!selectedProject && !!activeProject && selectedProject.value === activeProject.value;

    const move = () => {
        if (!selectedProject) {
            return;
        }
        const project = { ...selectedProject };
        cancel();
        onMove(project);
    };

    const copy = () => {
        if (!selectedProject) {
            return;
        }
        const project = { ...selectedProject };
        cancel();
        onCopy(project);
    };

    const footer = (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <Button onClick={cancel}>Cancel</Button>
            <Space>
                <Button type="primary" ghost={true} disabled={disabled} onClick={move}>
                    Move
                </Button>
                <Button type="primary" disabled={disabled} onClick={copy}>
                    Copy
                </Button>
            </Space>
        </div>
    );

    const selectedRowKeys = selectedProject ? [selectedProject.value] : [];

    const columns: ColumnsType<UserProject> = [
        {
            title: 'Label',
            dataIndex: 'label',
            render: (label, record) => {
                return record.value === activeProjectId ? (
                    <span>
                        <strong>{label}</strong>
                    </span>
                ) : (
                    <span>{label}</span>
                );
            },
        },
    ];

    const selectRow = (project: UserProject) => {
        setSelectedProject(project);
    };

    return (
        <Modal
            width="720px"
            visible={!!param}
            title={title}
            footer={footer}
            onCancel={cancel}
            className="move-copy-dialog"
        >
            <>
                {
                    <Table
                        size="small"
                        rowKey="value"
                        bordered={false}
                        pagination={false}
                        scroll={{ y: 256 }}
                        rowSelection={{
                            type: 'radio',
                            selectedRowKeys,
                            onChange: (selectedKeys: React.Key[], selectedRows: UserProject[], info) => {
                                setSelectedProject(selectedRows[0]);
                            },
                        }}
                        onRow={(record, rowIndex) => {
                            return {
                                onClick: () => selectRow(record),
                            };
                        }}
                        columns={columns}
                        dataSource={projects}
                    />
                }
            </>
        </Modal>
    );
};

const Parameters: React.VFC = () => {
    const [search, setSearch] = useState<string>('');
    const { activeProjectId, paramTypes, downloadToken } = useContext(AppContext);
    const { filteredInfo, onFilterChange } = useFilters<ParameterGroup>();
    const [selectedToMove, setSelectedToMove] = useState<Parameter | null>(null);
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { data } = useQuery(['params', activeProjectId], getGroupedParameters(activeProjectId), {
        initialData: [],
        refetchInterval: 60000,
        notifyOnChangeProps: ['data'],
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: true,
    });

    const handleParameterDelete = (id: Id) => {
        deleteParameter(id)
            .then(() => {
                message.success('Parameter deleted.');
                queryClient.invalidateQueries('params');
            })
            .catch(() => message.error('Something went wrong, try again!'));
    };

    const _moveParam = (project: UserProject) => {
        if (selectedToMove) {
            const param = { ...selectedToMove };
            param.project = { id: project.value };
            saveParamAndValue(param)
                .then(() => {
                    queryClient.invalidateQueries('params');
                    message.success(`Parameter moved to project "${project.label}"`);
                })
                .catch(() => message.error('Something went wrong, try again!'));
        }
    };

    const _copyParam = (project: UserProject) => {
        if (selectedToMove) {
            const param = { ...selectedToMove } as Parameter;
            param.project = { id: project.value };
            copyParam(param)
                .then(() => {
                    message.success(`Parameter copied to project "${project.label}"`);
                })
                .catch(() => message.error('Something went wrong, try again!'));
        }
    };

    const typeFilters = paramTypes
        .filter((paramType) => !paramType.internal)
        .map((paramType) => ({ text: paramType.name, value: paramType.name }));

    const columns: ColumnsType<ParameterGroup> = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 100,
            sorter,
            defaultSortOrder: 'descend' as SortOrder,
            filteredValue: filteredInfo.id || null,
        },
        {
            title: 'Label',
            dataIndex: 'label',
            ellipsis: true,
            filterDropdown: TableUtils.filterDropdown(setSearch),
            filterIcon: TableUtils.filterIcon,
            onFilter: TableUtils.filter((record) => record.label),
            render: labelRender(search),
            filteredValue: filteredInfo.label || null,
        },
        {
            title: 'Type',
            width: 175,
            dataIndex: ['paramType', 'name'],
            filters: typeFilters,
            onFilter: typeFilter,
            filteredValue: filteredInfo['paramType.name'] || null,
        },
        {
            dataIndex: 'actions',
            width: 120,
            render: (_: any, record: Parameter) => (
                <div className="actions">
                    {record.paramType.fileLike && (
                        <Button
                            size="small"
                            onClick={() => record.id && downloadParameterFile(record.id, downloadToken)}
                            icon={<CloudDownloadOutlined />}
                        />
                    )}
                    <Button size="small" onClick={() => navigate(`/parameters/${record.id}`)} icon={<EditOutlined />} />
                    <Button
                        size="small"
                        type="primary"
                        ghost
                        onClick={() => {
                            setSelectedToMove(record);
                        }}
                        icon={<SendOutlined />}
                    />

                    <DeleteButton onConfirm={() => record.id && handleParameterDelete(record.id)} />
                </div>
            ),
        },
    ];

    const actions = (
        <Button
            onClick={() => navigate('/parameters/new')}
            type="primary"
            icon={<PlusOutlined />}
            disabled={!activeProjectId}
        >
            New Parameter
        </Button>
    );

    return (
        <PageWrapper crumbs={CRUBS} actions={actions}>
            <Table
                className="params-table"
                bordered
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={data}
                indentSize={16}
                pagination={{
                    defaultPageSize: 20,
                    responsive: true,
                    size: 'default',
                    showTotal: showTotalText,
                    showSizeChanger: true,
                }}
                rowClassName={rowClassName}
                onChange={onFilterChange}
            />
            <MoveParamModal
                param={selectedToMove}
                onCancel={() => setSelectedToMove(null)}
                onMove={_moveParam}
                onCopy={_copyParam}
            />
        </PageWrapper>
    );
};

export default Parameters;
