import React, { useState } from "react";

import Descriptions from "antd/es/descriptions";
import Form from "antd/es/form";
import Input from "antd/es/input";
import message from "antd/es/message";
import Spin from "antd/es/spin";

import { FormLayout } from "../../Form";

import { Parameter, ParamType, ProjectId } from "../../../constants/types";

import { getParameter, getParameterFile } from "../../../api";

import set_utils from "../../../utils/set_utils";

import { saveNewPatentSet, patentSetFromString } from "./utils";

import ParameterSelect from "../inputs/ParameterSelect";

import { PatentSetToolProps } from "./PatentSetTool";

import Tool from "../../tools/Tool";

const saveMergedPatentSet = (
    label: string,
    paramType: ParamType,
    projectId: ProjectId,
    patentSet: Set<string>
) => saveNewPatentSet(
    label,
    paramType,
    projectId,
    patentSet,
    "Saved Merged Patent Set"
);

const pathStyle = (selected: boolean) => ({
    fill: selected ? "rgb(231, 247, 255)" : "rgb(245, 245, 245)",
    stroke: selected ? "rgb(24, 144, 255)" : "rgb(160, 160, 160)",
});

const computeMergeOutcomeSize = (sourceIds: Set<string>, otherIds: Set<string>, intersectionIds: Set<string>, sourceSelected: boolean, otherSelected: boolean, intersectionSelected: boolean): number => {
    let total = 0;

    const leftSize = sourceIds.size - intersectionIds.size;
    const centerSize = intersectionIds.size;
    const rightSize = otherIds.size - intersectionIds.size;

    if (sourceSelected) {
        total += leftSize;
    }

    if (intersectionSelected) {
        total += centerSize;
    }

    if (otherSelected) {
        total += rightSize;
    }

    return total;
};

const MergeTool: React.FC<PatentSetToolProps> = ({ param, patentIds }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [label, setLabel] = useState<string>(`${param.label}-Merged`);
    const [other, setOther] = useState<Parameter | null>(null);
    const [otherIds, setOtherIds] = useState<Set<string>>(new Set());
    const [intersectionIds, setIntersectionIds] = useState<Set<string>>(new Set());

    const [sourceSelected, setSourceSelected] = useState<boolean>(true);
    const [otherSelected, setOtherSelected] = useState<boolean>(true);
    const [intersectionSelected, setIntersectionSelected] = useState<boolean>(true);

    const loadOther = async ({ id }: { id: number }) => {
        setLoading(true);
        try {
            const newOther = await getParameter(id);
            const data = await getParameterFile(newOther.id!);
            const others = patentSetFromString(data);
            setOther(newOther);
            setOtherIds(others);
            setIntersectionIds(set_utils.intersection(patentIds, others));
        } catch (err) {
            message.error(`Unable to get parameter #${id}`);
        } finally {
            setLoading(false);
        }
    };

    const getMergedSet = (): Set<string> => {
        const left = set_utils.subtract(patentIds, intersectionIds);
        const right = set_utils.subtract(otherIds, intersectionIds);

        const out = new Set<string>();
        if (sourceSelected) {
            set_utils.union_(out, left);
        }
        if (intersectionSelected) {
            set_utils.union_(out, intersectionIds);
        }
        if (otherSelected) {
            set_utils.union_(out, right);
        }
        return out;
    };

    const clear = () => {
        setOther(null);
        setOtherIds(new Set());
        setIntersectionIds(new Set());
        setSourceSelected(true);
        setOtherSelected(true);
        setIntersectionSelected(true);
    };

    const save = () => saveMergedPatentSet(
        label,
        param.paramType,
        param.project,
        getMergedSet()
    );

    const mergeOutcomeSize = computeMergeOutcomeSize(
        patentIds,
        otherIds,
        intersectionIds,
        sourceSelected,
        otherSelected,
        intersectionSelected
    );

    return (
        <Tool
            title="Merge"
            label="Merge"
            onSave={save}
            onHide={clear}
        >
            <Form
                labelCol={FormLayout.labelCol}
                wrapperCol={FormLayout.wrapperCol}
                className="r2-form"
            >
                <Form.Item label={"Label"} >
                    <Input
                        value={label}
                        onChange={(event) => setLabel(event.target.value)}
                    />
                </Form.Item>

                <Form.Item label={"Other"} >
                    <ParameterSelect
                        paramType="PatentSet"
                        value={other}
                        onChange={loadOther}
                    />
                </Form.Item>
            </Form>

            <Spin spinning={loading}>
                <svg viewBox="-400 0 800 125" className="merge-tool-graphic">
                    <circle
                        className="merge-tool-graphic-item"
                        style={pathStyle(sourceSelected)}
                        cx="-24.0"
                        cy="56.0"
                        r="48"
                        onClick={() => setSourceSelected(!sourceSelected)} />
                    <circle
                        className="merge-tool-graphic-item"
                        style={pathStyle(otherSelected)}
                        cx="24"
                        cy="56.0"
                        r="48"
                        onClick={() => setOtherSelected(!otherSelected)} />
                    <path
                        className="merge-tool-graphic-item"
                        d="M 24 56 C 24 73.767 14.347 89.279 0.0 97.579 C -15.653 89.279 -24.0 73.767 -24.0 56.0 C -24.0 38.233 -15.653 22.721 0.0 14.421 C 14.347 22.721 24.0 38.233 24.0 56.0 Z"
                        style={pathStyle(intersectionSelected)}
                        onClick={() => setIntersectionSelected(!intersectionSelected)} />
                    <text
                        className="merge-tool-text-item"
                        y="48.0">
                        <tspan
                            className="label"
                            x="-220.0"
                            dy="0.6em">
                            {param.label}
                        </tspan>
                        <tspan x="-220.0" dy="1.2em">
                            {`[${patentIds.size}]`}
                        </tspan>
                    </text>
                    <text
                        className="merge-tool-text-item"
                        y="48.0">
                        <tspan
                            className="label"
                            x="220.0"
                            dy="0.6em"
                            width="200">
                            {other && other.label}
                        </tspan>
                        <tspan x="220.0" dy="1.2em">
                            {other && `[${otherIds.size}]`}
                        </tspan>
                    </text>
                    <text
                        className="merge-tool-text-item"
                        x="-44.0"
                        y="60.0">
                        {patentIds.size - intersectionIds.size}
                    </text>
                    <text
                        className="merge-tool-text-item"
                        x="44.0"
                        y="60.0">
                        {otherIds.size - intersectionIds.size}
                    </text>
                    <text
                        className="merge-tool-text-item"
                        x="0.0"
                        y="60.0">
                        {intersectionIds.size}
                    </text>
                </svg>

                <Descriptions
                    title="After Merge Status"
                    column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                    size="small">
                    <Descriptions.Item label="Number of equals documents">
                        <strong>
                            {intersectionIds.size}
                        </strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="Number of new documents">
                        <strong>
                            {otherIds.size - intersectionIds.size}
                        </strong>
                    </Descriptions.Item>
                    <Descriptions.Item label="After Merge Size">
                        <strong>
                            {mergeOutcomeSize}
                        </strong>
                    </Descriptions.Item>
                </Descriptions>
            </Spin>
        </Tool>
    );
};

export default MergeTool;
