export const intersection = <T>(first: Set<T>, second: Set<T>): Set<T> => {
    const out = new Set<T>();
    const [smaller, bigger] = first.size < second.size
        ? [first, second]
        : [second, first];

    smaller.forEach((el) => {
        if (bigger.has(el)) {
            out.add(el);
        }
    });

    return out;
};

/*
* In place union
*/
export const union_ = <T>(first: Set<T>, second: Set<T>): Set<T> => {
    second.forEach(elem => first.add(elem));
    return first;
};

export const union = <T>(first: Set<T>, second: Set<T>): Set<T> => {
    const out = new Set(first);
    return union_(out, second);
};

export const subtract = <T>(first: Set<T>, second: Set<T>): Set<T> => {
    const out = new Set(first);
    second.forEach(elem => out.delete(elem));
    return out;
};

export default {
    intersection,
    union_,
    union,
    subtract,
};
