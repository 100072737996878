import React from 'react';

import InputNumber from 'antd/es/input-number';
import Select from 'antd/es/select';

interface NumberProps {
    initialValue: any;
    value: any;
    values: string[];
    onChange: (data: any) => void
}

const Number: React.FC<NumberProps> = ({ initialValue, value, values, onChange }) => {
    const _onInputChange = (newValue: any) => {
        const v = newValue ? { value: newValue } : null;
        onChange({ value: v });
    };

    const _onSelectChange = (v: string) => {
        onChange({ value: { value: parseFloat(v) } });
    };

    const inputValue = value && value.value && value.value.value;
    const hasValues = values && (values.length > 0);

    return hasValues
        ? (
            <Select
                defaultValue={initialValue}
                value={inputValue}
                onChange={_onSelectChange}>
                {
                    values.map((v, index) => <Select.Option value={v} key={index}>{v}</Select.Option>)
                }
            </Select>
        ) : (
            <InputNumber
                defaultValue={initialValue}
                value={inputValue}
                onChange={_onInputChange}
            />
        );
};

export default Number;
