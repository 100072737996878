import React from "react";

import Highlighter from "react-highlight-words";

interface R2HighlighterProps {
    search?: string;
    text: string;
}

const R2Highlighter: React.FC<R2HighlighterProps> = ({ search, text }) => {
    return search
        ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#FFFF54', padding: 0 }}
                autoEscape
                searchWords={[search]}
                textToHighlight={text}
            />
        )
        : (
            <>
                {text}
            </>
        );
};

export default R2Highlighter;
