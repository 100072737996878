import React, { HTMLAttributes, PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';

import Button from 'antd/es/button';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import Tooltip from 'antd/es/tooltip';

import CopyOutlined from '@ant-design/icons/CopyOutlined';

import { VerticalScrollShadow } from '../ScrollShadow';

interface ToolFooterProps {
    saving?: boolean;
    onCancel?: () => void;
    onSave?: () => Promise<void>;
    actions?: ReactNode[];
}

const ToolFooter: React.FC<ToolFooterProps> = ({ saving = false, onCancel, onSave, actions }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
            <div>
                {onCancel && <Button onClick={onCancel}>Cancel</Button>}
            </div>
            <div>
                {onSave && <Button type='primary' onClick={onSave} loading={saving}>Save</Button>}
                {actions && actions.map((action, i) => <React.Fragment key={i}>{action}</React.Fragment>)}
            </div>
        </div>
    );
};

interface ToolProps {
    title: string;
    label?: string;
    icon?: ReactNode;
    visible?: boolean;
    width?: string | number;
    actions?: ReactNode[];

    onShow?: () => void;
    onSave?: () => Promise<void>;
    onHide?: () => void;
    // onClose?: () => void;
    // onCancel?: () => void;
}

const Tool: React.FC<React.PropsWithChildren<ToolProps>> = ({
    title,
    label,
    icon,
    visible: controlledVisible,
    width = 800,
    actions,

    onShow,
    onSave,
    onHide,

    children
}) => {
    const [visible, setVisible] = useState<boolean>(!!controlledVisible);
    const [saving, setSaving] = useState<boolean>(false);

    /*
     * This effect should only works when component is used in controlled mode
     */
    useEffect(() => {
        if (controlledVisible === undefined) {
            return;
        }

        setVisible(controlledVisible);
    }, [controlledVisible]);

    const _setVisible = (value: boolean) => {
        if (controlledVisible === undefined) {
            setVisible(value);
        }
    };

    const _show = () => {
        if (onShow) {
            onShow();
        }
    };

    const _showModal = () => {
        _setVisible(true);
        _show();
    };

    const _hide = () => {
        if (onHide) {
            onHide();
        }
    };

    const _cancel = () => {
        _setVisible(false);
        _hide();
    };

    const _save = onSave
        ? () => {
            setSaving(true);
            return onSave()
                .then(() => {
                    _hide();
                })
                .catch((error) => {
                    message.error(error);
                })
                .finally(() => {
                    setSaving(false);
                });
        }
        : undefined;

    return (
        <>
            <Button
                onClick={_showModal}
                icon={icon}
            >
                {label && `${label} Tool`}
            </Button>
            <Modal
                title={`${title} Tool`}
                width={width}
                visible={visible}
                confirmLoading={saving}
                destroyOnClose={true}
                onCancel={_cancel}
                footer={
                    <ToolFooter
                        saving={saving}
                        onCancel={_cancel}
                        onSave={_save}
                        actions={actions}
                    />
                }
            >
                {children}
            </Modal>
        </>
    );
};

interface ToolSectionProps extends HTMLAttributes<HTMLElement> {
    flex?: boolean;
    centered?: boolean;
}

const ToolSection: React.FC<PropsWithChildren<ToolSectionProps>> = ({
    className,
    flex = false,
    centered = false,
    children,
    ...rest
}) => {
    const cls = ['section', flex ? 'flex' : null, centered ? 'centered' : null, className ? className : null]
        .filter(el => !!el)
        .join(' ');

    return (
        <div className={cls} {...rest}>
            {children}
        </div >
    );
};

interface ToolOutputProps {
    values: null | undefined | string[];
    separator?: string
}

const toolOutputMinHeight = '48px';
const toolOutputMaxHeight = '64px';

const ToolOutput: React.FC<ToolOutputProps> = ({ values = [], separator = '' }) => {
    const [copied, setCopied] = useState<boolean>(false);

    useEffect(() => {
        let isSubscribed = true;
        if (copied) {
            setTimeout(() => {
                if (isSubscribed) {
                    setCopied(false);
                }
            }, 5000);
        }

        return () => {
            isSubscribed = false;
        };
    }, [copied]);

    const copyToClipboard = () => {
        if (values) {
            navigator.clipboard.writeText(values.join('\n'));
            setCopied(true);
        }
    };

    const tooltipTitle = !copied
        ? 'Copy To Clipboard'
        : 'Copied';

    return values && values.length > 0
        ? (
            <ToolSection className='tool-output'>
                <VerticalScrollShadow style={{ minHeight: toolOutputMinHeight, maxHeight: toolOutputMaxHeight }}>
                    <div style={{ minHeight: toolOutputMinHeight, maxHeight: toolOutputMaxHeight }}>
                        {
                            values.map(
                                (value, i) => <div className='item' key={i}>{value}{separator}</div>
                            )
                        }
                    </div>
                </VerticalScrollShadow>

                <Tooltip title={tooltipTitle}>
                    <Button
                        className='tool-output-copy'
                        type='link'
                        icon={<CopyOutlined />}
                        onClick={copyToClipboard}
                    />
                </Tooltip>
            </ToolSection>
        )
        : (
            <ToolSection className='tool-output disabled' style={{ minHeight: toolOutputMinHeight }} />
        );
};

export { Tool as default, ToolSection, ToolOutput };
