import React, { useState, useEffect, useContext, useMemo, useRef } from "react";

import range from "lodash/range";
import { capitalize } from "../utils";

import Button from "antd/es/button";
import Tabs from "antd/es/tabs";
import Collapse from "antd/es/collapse";
import Image from "antd/es/image";

import CloseOutlined from "@ant-design/icons/CloseOutlined";
import { fetchPatentGalleryMeta, PatentGalleryMeta, patentImageUrl } from "../api";
import { AppContext } from "../contexts";

interface PatentImageProps {
    patent_id: string;
    provider: string;
    page: number;
    label?: string;
    preview?: boolean;
    closable?: boolean;
    onClick?: () => void;
    onClose?: () => void;
}

const PatentImage: React.FC<PatentImageProps> = ({ patent_id, provider, page, label, preview = true, closable = false, onClick, onClose }) => {
    const { downloadToken } = useContext(AppContext);
    const [src, setSrc] = useState<string | undefined>();

    useEffect(() => {
        if (downloadToken) {
            const imageSrc = patentImageUrl(provider, patent_id, page, downloadToken.id);
            setSrc(imageSrc);
        }
    }, [provider, patent_id, page, downloadToken]);

    return (
        <div className="patent-image-wrapper">
            {closable && <div className="close-button"><Button type="default" shape="circle" size="small" icon={<CloseOutlined />} onClick={onClose} /></div>}
            <div className="patent-image-container" style={{ cursor: onClick || preview ? "pointer" : "default" }}>
                <Image
                    preview={preview}
                    src={src}
                    onClick={() => onClick && onClick()}
                >
                </Image>
                {label &&
                    <div className="label">
                        {label}
                    </div>
                }
            </div>
        </div>
    );
};

interface PatentImageGalleryProps {
    provider: string;
    id: string;
    preview?: boolean;
    onClick?: (id: string, provider: string, page: number) => void
}

const PatentImageGallery: React.FC<PatentImageGalleryProps> = ({ provider, id, preview = true, onClick }) => {
    const [meta, setMeta] = useState<PatentGalleryMeta | null>(null);
    const isSubscribed = useRef(true);

    // Unmount, remove subscription
    useEffect(() => {
        return () => {
            isSubscribed.current = false;
        };
    }, []);

    useEffect(() => {
        if (id) {
            const fetch = async () => {
                const m = await fetchPatentGalleryMeta(provider, id);
                if (isSubscribed.current) {
                    setMeta(m);
                }
            };

            fetch();
        }
    }, [provider, id]);

    if (!meta || meta.number_of_pages === 0) {
        return null;
    }

    const header = `${capitalize(provider)} Images (${meta.number_of_pages})`;

    const sections = meta.sections.map((section, i) =>
        <Tabs.TabPane tab={section.name} key={section.name}>
            <div className="gallery-section">
                <Image.PreviewGroup>
                    {
                        range(section.start_page, section.end_page + 1).map(
                            (page) =>
                                <PatentImage key={page}
                                    patent_id={id}
                                    provider={provider}
                                    page={page}
                                    label={`${page}`}
                                    preview={preview}
                                    onClick={() => onClick && onClick(id, provider, page)}
                                />
                        )
                    }
                </Image.PreviewGroup>
            </div>
        </Tabs.TabPane>
    );

    return (
        <Collapse
            className="patent-image-gallery"
        >
            <Collapse.Panel header={header} key="1">
                <Tabs defaultActiveKey="DRAWINGS">
                    {sections}
                </Tabs>
            </Collapse.Panel>
        </Collapse>
    );
};

export { patentImageUrl, PatentImageGallery as default, PatentImage };
