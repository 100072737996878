import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { Range, RankingRelevance } from './types';

export const UNIX_TIME_ORIGIN = "1970-01-01 00:00:00";

export const PATSEARCH_API_KEY_HEADER = "X-Patsearch-API-Key";

export const MONACO_CONFIG: monaco.editor.IStandaloneEditorConstructionOptions = {
    wordWrap: 'on',
    detectIndentation: false,
    tabSize: 8,
    renderWhitespace: 'all',
    insertSpaces: false,
    useTabStops: true,
    fontSize: 13,
    theme: "r2",
};

export const RANKING_GROUP_RELEVANCE_CONFIG: Map<number, RankingRelevance> = new Map([
    [
        1,
        {
            id: 1,
            label: "Base",
            baseValue: 5,
            validityrange: new Range(0, 10, "<=")
        }
    ],
    [
        2,
        {
            id: 2,
            label: "Distinctive",
            baseValue: 45,
            validityrange: new Range(10, 100)
        }
    ],
    [
        3,
        {
            id: 3,
            label: "Fundamental",
            baseValue: 450,
            validityrange: new Range(100, 1000)
        }
    ],
    [
        4,
        {
            id: 4,
            label: "Negative",
            baseValue: -10,
            validityrange: new Range(Number.NEGATIVE_INFINITY, -1)
        }
    ]
]);
