import { useRef } from "react";
import { Task } from "../../constants/types";

type TaskFormSaveAction = () => Promise<boolean>;
type TaskFormAfterSaveAction = (task: Task) => void;

export class TaskFormInstance {
    save: TaskFormSaveAction;

    saveAndRun: TaskFormSaveAction;

    onTaskSaved: TaskFormAfterSaveAction;

    constructor() {
        this.save = () => Promise.resolve(false);
        this.saveAndRun = () => Promise.resolve(false);
        this.onTaskSaved = (task: Task) => null;
    }

    setSave(f: TaskFormSaveAction): void {
        this.save = f;
    }

    setSaveAndRun(f: TaskFormSaveAction): void {
        this.saveAndRun = f;
    }

    setOnTaskSaved(f: TaskFormAfterSaveAction): void {
        this.onTaskSaved = f;
    }
}

const useTaskForm = (form?: TaskFormInstance): [TaskFormInstance] => {
    const ref = useRef<TaskFormInstance | null>(null);
    if (!ref.current) {
        ref.current = new TaskFormInstance();
    }

    return [ref.current];
};

export default useTaskForm;
