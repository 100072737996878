import React from 'react';
import { RankingReportPatent, RankingReportImage, ImageRotation } from './types';

import Upload from 'antd/es/upload';

import message from 'antd/es/message';
import Button from 'antd/es/button';
import Space from 'antd/es/space';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import RotateLeftOutlined from '@ant-design/icons/RotateLeftOutlined';
import RotateRightOutlined from '@ant-design/icons/RotateRightOutlined';

import PatentImageGallery, { patentImageUrl } from '../../PatentImageGallery';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { imageName, imageSrc } from './utils';

const UploadButton: React.FC = () => (
    <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
    </div>
);

export interface PatentGalleryProps {
    patent: RankingReportPatent;
    token: string | null | undefined;
    onAdd: (image: RankingReportImage) => void;
    onRotate: (imageIndex: number, rotation: ImageRotation) => void;
    onRemove: (imageIndex: number) => void;
}

const PatentGallery: React.FC<PatentGalleryProps> = ({
    patent,
    token,
    onAdd,
    onRotate,
    onRemove,
}) => {
    const fileList = patent.images.map((image: RankingReportImage, index: number) => {
        return {
            uid: image.uid,
            index,
            name: imageName(image),
            status: 'done',
            url: imageSrc(image, token),
            originFileObj: image.data,
        };
    }) as UploadFile<any>[];

    const addImageFromUser = (file: RcFile) => {
        if (!file.type.startsWith('image')) {
            message.error(`${file.name}: unsupported file type ${file.type}`);
            return false;
        }

        const name = `${file.uid}-${file.name}`;

        onAdd({
            uid: file.uid,
            data: new File([file], name, { type: file.type }),
        });
        return false;
    };

    const addImageFromProvider = async (id: string, provider: string, page: number) => {
        const uid = `${provider}-${page}`;
        if (!patent.images.map((image) => image.uid).includes(uid)) {
            const url = patentImageUrl(provider, id, page);
            const response = await fetch(url);
            const blob = await response.blob();
            const metadata = {
                type: 'image/png',
            };
            const data = new File([blob], `${id}-${provider}-${page}.png`, metadata);
            onAdd({ uid, data });
        }
    };

    const handleRotate = (file: UploadFile, rotation: ImageRotation) => {
        const index = patent.images.findIndex(
            (image: RankingReportImage) => image.uid === file.uid
        );
        onRotate(index, rotation);
    };

    const handleRemove = (file: UploadFile) => {
        onRemove(patent.images.findIndex((image: RankingReportImage) => image.uid === file.uid));
    };

    return (
        <div className="patent-gallery-wrapper">
            <div className="patent-gallery-container">
                <div className="selected-images-wrapper">
                    <div className="selected-images">
                        <Upload
                            showUploadList={{
                                showPreviewIcon: false,
                                showDownloadIcon: false,
                            }}
                            beforeUpload={addImageFromUser}
                            listType="picture-card"
                            fileList={fileList}
                            itemRender={(_, file, __, actions) => {
                                return (
                                    <div className="ant-upload-list-picture-card-container">
                                        <div className="ant-upload-list-item ant-upload-list-item-list-type-picture-card">
                                            <span className="ant-upload-list-item-thumbnail ant-upload-span">
                                                <img
                                                    className="ant-upload-list-item-image"
                                                    src={file.url}
                                                />
                                            </span>
                                            <span className="patent-image-toolbar">
                                                <Space>
                                                    <Button
                                                        size="small"
                                                        icon={<RotateLeftOutlined />}
                                                        onClick={() =>
                                                            handleRotate(file, ImageRotation.Left)
                                                        }
                                                    />
                                                    <Button
                                                        size="small"
                                                        icon={<RotateRightOutlined />}
                                                        onClick={() =>
                                                            handleRotate(file, ImageRotation.Right)
                                                        }
                                                    />
                                                </Space>
                                                <Space>
                                                    <Button
                                                        size="small"
                                                        danger
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => actions.remove()}
                                                    />
                                                </Space>
                                            </span>
                                        </div>
                                    </div>
                                );
                            }}
                            onRemove={handleRemove}
                        >
                            <UploadButton />
                        </Upload>
                    </div>
                </div>

                <div className="patent-gallery-providers">
                    <PatentImageGallery
                        id={patent.id}
                        provider="epo"
                        preview={false}
                        onClick={addImageFromProvider}
                    />
                    <br />
                    <PatentImageGallery
                        id={patent.id}
                        provider="google"
                        preview={false}
                        onClick={addImageFromProvider}
                    />
                </div>
            </div>
        </div>
    );
};

export default PatentGallery;
