import React from "react";

import { TaskFormRendererProps } from "../types";

import Collapse from "antd/es/collapse";
import TaskFormItem from "../TaskFormItem";
import { TaskFormFilterItem } from "../../parameters/inputs/FilterInput";

const SolrSetFilterForm: React.FC<TaskFormRendererProps> = ({
    form,
    requiredInputs,
    otherInputs,
    advancedInputs,
    hiddenFields
}) => {
    const filtersName = "filters";

    const _requiredInputs = requiredInputs.filter(([name, input]) => name !== filtersName);
    const _otherInputs = otherInputs.filter(([name, input]) => name !== filtersName);
    const _advancedInputs = advancedInputs.filter(([name, input]) => name !== filtersName);

    const filters = requiredInputs.find(([name, input]) => name === filtersName) ||
        otherInputs.find(([name, input]) => name === filtersName) ||
        advancedInputs.find(([name, input]) => name === filtersName);

    return (
        <>
            {
                _requiredInputs.map(([name, input]) => <TaskFormItem.Param key={name} name={name} input={input} hiddenFields={hiddenFields} />)
            }

            {
                _otherInputs.map(([name, input]) => <TaskFormItem.Param key={name} name={name} input={input} hiddenFields={hiddenFields} />)
            }

            {filters && <TaskFormFilterItem form={form} name={filtersName} input={filters[1]} hiddenFields={[]} />}

            {
                _advancedInputs.length > 0 &&
                <div className="advanced-params">
                    <Collapse
                        ghost
                        expandIconPosition="left"
                        style={{ marginBottom: "24px", }}>
                        <Collapse.Panel header={
                            <div className="header">
                                Advanced settings
                            </div>
                        }
                            key="1">
                            {
                                advancedInputs.map(([name, input]) => <TaskFormItem.Param key={name} name={name} input={input} hiddenFields={hiddenFields} />)
                            }
                        </Collapse.Panel>
                    </Collapse>
                </div>
            }
        </>
    );
};

export default SolrSetFilterForm;
