/* eslint-disable max-classes-per-file */

import toNumber from "lodash/toNumber";
import isArray from "lodash/isArray";
import flatten from "lodash/flatten";
import zip from "lodash/zip";

const apex = `\\s*[｀゛⁗″‴‷‘‛⸂“‟˝⸌’”⸃⸍⸊⸉′‵׳٬״‶՛՚՝༌་΄˝⸂"']+\\s*`;
const hyphen = "[-֊־‐˗‑‒–—―⸺⸻﹘﹣]";
const dots = "\\s*[⸱⸳.‥…⁖⁘•⸪⸫⸬⸭・﹒．･𐄁]\\s*";
const slash = "\\s*[/︳׀।᜵]\\s*";
const adding = `\\s*[+⁺₊⊕˖]\\s*`;
const star = "\\s*[*⊛꘎﹡꙳]\\s*";
const coma = "\\s*,\\s*";
// eslint-disable-next-line no-useless-escape
const parenthesis_left = "\\s*[\(\\[⁽₍〈❨❪❬❮❰❲⟨⟮（﹙｟［]\\s*";
// eslint-disable-next-line no-useless-escape
const parenthesis_right = "\\s*[\)\\]⁾₎〉❩❫❭❯❱❳⟩⟯）﹚｠］]\\s*";

const tokens = [
    apex,
    parenthesis_left,
    parenthesis_right,
    hyphen,
    dots,
    slash,
    adding,
    star,
    coma,
];

const regs = tokens.map(token => new RegExp(token, "g"));

const placeholder = (idx: number): string => `#@${idx}@#`;

export const formulaToRegex = (input: string): string => {
    let formula = input.trim();
    let prefix = "";
    let postfix = "";

    if (formula.length > 0) {
        if (formula.search('^[a-zA-Z0-9]') !== -1) {
            prefix = "\\b";
        }

        if (formula[formula.length - 1].search('[a-zA-Z0-9]$') !== -1) {
            postfix = "\\b";
        }
    }

    regs
        .forEach(
            (reg, idx) => {
                formula = formula.replace(reg, placeholder(idx));
            }
        );

    tokens
        .forEach(
            (token, idx) => {
                formula = formula.replaceAll(placeholder(idx), token);
            }
        );

    return `${prefix}${formula}${postfix}`;
};

export const kCombinations = <T>(k: number, set: T[]): T[][] => {
    if (k === 0) {
        return [];
    }

    if (k === 1) {
        return set.map(it => [it]);
    }

    if (k === set.length) {
        return [set];
    }

    // To get k-combinations of a set, we want to join each element
    // with all (k-1)-combinations of the other elements. The set of
    // these k-sized sets would be the desired result. However, as we
    // represent sets with lists, we need to take duplicates into
    // account. To avoid producing duplicates and also unnecessary
    // computing, we use the following approach: each element i
    // divides the list into three: the preceding elements, the
    // current element i, and the subsequent elements. For the first
    // element, the list of preceding elements is empty. For element i,
    // we compute the (k-1)-computations of the subsequent elements,
    // join each with the element i, and store the joined to the set of
    // computed k-combinations. We do not need to take the preceding
    // elements into account, because they have already been the i:th
    // element so they are already computed and stored. When the length
    // of the subsequent list drops below (k-1), we cannot find any
    // (k-1)-combs, hence the upper limit for the iteration:
    const combs = [];
    for (let i = 0; i < set.length - k + 1; i++) {
        // head is a list that includes only our current element.
        const head = set.slice(i, i + 1);
        // We take smaller combinations from the subsequent elements
        const tailcombs = kCombinations(k - 1, set.slice(i + 1));
        // For each (k-1)-combination we join it with the current
        // and store it to the set of k-combinations.
        for (let j = 0; j < tailcombs.length; j++) {
            combs.push(head.concat(tailcombs[j]));
        }
    }
    return combs;
};

export const permutations = <T>(set: T[]): T[][] => {
    const result = [] as T[][];

    if (set.length === 0) {
        return result;
    }

    if (set.length === 1) {
        result.push(set);
        return result;
    }

    for (let i = 0; i < set.length; i++) {
        const current = set[i];
        const others = set.slice(0, i).concat(set.slice(i + 1));
        const remainingPermuted = permutations(others);

        remainingPermuted.forEach(
            p => result.push([current].concat(p))
        );
    }

    return result;
};

export const kPermutations = <T>(k: number, set: T[]): T[][] => {
    return kCombinations(k, set)
        .flatMap(
            c => permutations(c)
        );
};

/*
 * Term distribution helpers
 */
const gRegex = new RegExp(/[a-zA-Z]/);
const pRegex = new RegExp(/[-:,0-9]/);

const charToIndex = (s: string): number => s.toLowerCase().charCodeAt(0) - 'a'.charCodeAt(0);

interface OutputFormatter {
    format(group: string[], proximity: undefined | string | string[]): string
}

class QueryOutputFormatter implements OutputFormatter {
    fmt(proximity: undefined | string | string[]): (str: string) => string {
        const p = isArray(proximity)
            ? proximity.map(p0 => parseInt(p0)).filter(p1 => !isNaN(p1)).reduce((p2, c) => {
                return c + p2;
            }, 0)
            : parseInt(proximity || "");

        return isNaN(p) || p <= 0
            ? (str) => `"${str}"`
            : (str) => `"${str}"~${p}`;
    }

    format(group: string[], proximity: undefined | string | string[]): string {
        const fmt = this.fmt(proximity);
        return fmt(group.join(" "));
    }
}

class RankingOutputFormatter implements OutputFormatter {
    getGlues(group: string[], proximity: undefined | string | string[]): string[] {
        if (isArray(proximity)) {
            return proximity.map(p => `|${p}|`);
        }

        const p = proximity ? `|${proximity}|` : " ";
        return new Array(group.length - 1).fill(p);
    }

    format(group: string[], proximity: undefined | string | string[]): string {
        const glues = this.getGlues(group, proximity);
        return flatten(zip(group, glues)).filter(el => !!el).join("");
    }
}

export class TermDistribution {
    query = false;

    reverse = false;

    proximity: undefined | string | string[] = undefined;

    separator = "#";

    build(combinations: number | string, items: string[][], proximity?: undefined | string): string[] {
        const k = toNumber(combinations);
        const builder = isNaN(k)
            ? (_items: string[]) => this.buildFixed(combinations.toString(), _items)
            : (_items: string[]) => this.buildCombinations(k, _items, proximity);

        const items_list = this.buildItems(items);
        const op = items_list.reduce(
            (acc, _items) => {
                return [...acc, ...builder(_items)];
            },
            [] as string[]
        );

        return op;
    }

    private groupGenerator(): ((k: number, set: string[]) => string[][]) {
        return this.reverse
            ? kPermutations
            : kCombinations;
    }

    private formatter(): OutputFormatter {
        return this.query
            ? new QueryOutputFormatter()
            : new RankingOutputFormatter();
    }

    private buildItems(items: string[][]): string[][] {
        if (items.length === 0) {
            return [];
        }

        return this.buildItemsImpl(items[0], items.slice(1));
    }

    private buildItemsImpl(items: string[], others: string[][]): string[][] {
        const output = [] as string[][];

        if (others.length === 0) {
            return [items];
        }

        items.map(item => {
            if (others.length === 1) {
                others[0].map(
                    other => output.push([item, other])
                );

                return output;
            }

            others[0].map(
                other => {
                    const others_ = others.slice(1);
                    this.buildItemsImpl([other], others_).forEach(
                        its => output.push([item, ...its])
                    );
                }
            );
        });

        return output;
    }

    private buildCombinations(k: number, items: string[], proximity: undefined | string): string[] {
        const _k = Math.min(k, items.length);
        const formatter = this.formatter();
        return this.groupGenerator()(_k, items)
            .map(
                group => formatter.format(group, proximity)
            );
    }

    private buildFixed(fixed: string, items: string[]): string[] {
        // Extract combination
        const combs = fixed.trim().split(this.separator);
        const input = items.map(el => el.toLowerCase());

        const formatter = this.formatter();

        return combs.map(comb => {
            const arr = comb.split("");
            const group = [] as string[];
            const proximity = [] as string[];
            const temp = [] as string[];


            arr.forEach((el, l) => {
                if (gRegex.test(el)) {
                    // Found a new group
                    group.push(input[charToIndex(el)]);

                    // Pop elements temp array and build proximity if possible
                    if (temp.length !== 0) {
                        proximity.push(temp.join(""));
                        temp.length = 0;
                    }
                } else if (l === 0) {
                } else if (pRegex.test(el)) {
                    temp.push(el);
                }
            });

            // Output
            return formatter.format(group, proximity);
        });
    }
}
