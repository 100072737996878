import React, { PropsWithChildren, ReactNode, useContext, useEffect, useRef, useState } from 'react';

import filter from 'lodash/filter';
import find from 'lodash/find';

import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import dayjs from 'dayjs';

import Tag from 'antd/es/tag';
import Table from 'antd/es/table';
import Button from 'antd/es/button';
import Progress from 'antd/es/progress';
import message from 'antd/es/message';
import Typography from 'antd/es/typography';
import Popover from 'antd/es/popover';
import Tooltip from 'antd/es/tooltip';
import Space from 'antd/es/space';

import EditOutlined from '@ant-design/icons/EditOutlined';
import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';
import CopyOutlined from '@ant-design/icons/CopyOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import WarningOutlined from '@ant-design/icons/WarningOutlined';

import {
    Id,
    TaskStatus,
    Task,
    TaskIO,
    TaskStatuses,
    ActiveTask,
    ScheduledTask
} from '../../constants/types';

import {
    ColumnsType,
    SortOrder
} from 'antd/lib/table/interface';

import {
    deleteTask,
    startTask,
    downloadParameterFile,
    getTasks
} from '../../api';


import { PageWrapper } from '../../components/PageWrapper';
import { AppContext } from '../../contexts';
import { UNIX_TIME_ORIGIN } from '../../constants';
import Metadata from '../../components/Metadata';
import Link from '../../components/Link';
import DeleteButton from '../../components/DeleteButton';

import Formatter from '../../components/Formatter';
import TableUtils from '../../components/TableUtils';
import R2Highlighter from '../../components/R2Highlighter';
import { useFilters } from '../../hooks/useFilters';

const crumbs = [{ label: 'Tasks' }];

const statusToColor = (status: TaskStatus): string => {
    switch (status) {
        case TaskStatus.ERROR:
            return 'red';
        case TaskStatus.ZOMBIE:
            return 'purple';
        case TaskStatus.STOPPED:
            return 'orange';
        case TaskStatus.COMPLETED:
            return 'green';
        case TaskStatus.SCHEDULED:
            return 'cyan';
        case TaskStatus.WARNING:
            return 'orange';
        case TaskStatus.ACTIVE:
            return 'blue';
    }

    return '';
};

const paramTypeToColor = (type: string): string => {
    if (type === 'FileLog') {
        return 'red';
    }

    return 'default';
};

const statusFmt = (status: TaskStatus): string => {
    if (status === TaskStatus.ERROR) {
        return 'Error';
    }
    return 'Message';
};

interface StatusIndicatorProps<TaskType> {
    task: TaskType;
}

const StatusIndicatorPopover: React.FC<React.PropsWithChildren<StatusIndicatorProps<Task>>> = ({ task, children }) => {
    if (!task.statusMessage) {
        return <>{children}</>;
    }

    const taskMessage = task.status === TaskStatus.ERROR
        ? task.error
        : task.statusMessage;

    const copyToClipboard = () => navigator.clipboard.writeText(taskMessage);

    const content = (
        <div className='content'>
            <Typography.Text>
                {taskMessage}
            </Typography.Text>
        </div>
    );

    const title = task.status === TaskStatus.ERROR
        ? (
            <div className='title'>
                <h6>
                    {statusFmt(task.status)}
                </h6>
                <Button
                    icon={<CopyOutlined />}
                    size='small'
                    type='link'
                    onClick={copyToClipboard} />
            </div>
        )
        : null;

    return (
        <Popover
            overlayClassName='r2-popover'
            placement='top'
            title={title}
            content={content}
            destroyTooltipOnHide={true}
        >
            {children}
        </Popover>
    );
};

const ProgressStatusIndicator: React.FC<StatusIndicatorProps<ActiveTask>> = ({ task }) => {
    const progress = task.progress || 0;
    return (
        <StatusIndicatorPopover task={task}>
            <Progress
                percent={Math.round(progress) || 0.0}
                size='small'
            />
        </StatusIndicatorPopover>
    );
};

const StatusIndicatorTag: React.FC<PropsWithChildren<StatusIndicatorProps<Task>>> = ({ task, children, ...rest }) => {
    return (
        <div className='task-status-indicator'>
            <Tag color={statusToColor(task.status)} {...rest}>
                <div className='task-status-indicator-content'>
                    <span>
                        {task.status}
                    </span>
                    {children}
                </div>
            </Tag>
        </div>
    );
};

const ScheduledStatusIndicator: React.FC<StatusIndicatorProps<ScheduledTask>> = ({ task }) => {
    const title = `Scheduled at ${dayjs(task.scheduled).format('DD-MM-YYYY HH:mm')}`;
    return (
        <Tooltip title={title} arrowPointAtCenter={true}>
            <StatusIndicatorTag task={task}>
                <ClockCircleOutlined />
            </StatusIndicatorTag>
        </Tooltip>
    );
};

const StatusIndicator: React.FC<StatusIndicatorProps<Task>> = ({ task }) => {
    const icon = Task.hasErrorParam(task)
        ? <WarningOutlined />
        : null;

    return (
        <StatusIndicatorPopover task={task}>
            <StatusIndicatorTag task={task}>
                {icon}
            </StatusIndicatorTag>
        </StatusIndicatorPopover>
    );
};

interface InpurOutputTableProps {
    input: { [key: string]: TaskIO }
    output: { [key: string]: TaskIO }
    errors: { [key: string]: TaskIO }
}

interface TaskIORecord extends TaskIO {
    key: string;
    ioType: string;
    index: string;
}

interface TaskIORecordProps {
    record: TaskIORecord;
}

const TaskIORecordTag: React.FC<TaskIORecordProps> = ({ record }) => (
    <Tag color={paramTypeToColor(record.type)}>
        {record.key}
    </Tag>
);

const TaskIORecordSummary: React.FC<TaskIORecordProps> = ({ record }) => {
    const { downloadToken } = useContext(AppContext);
    const navigate = useNavigate();
    const { paramTypes } = useContext(AppContext);
    const paramType = find(paramTypes, (pt) => pt.name === record.type);

    const actions = [];

    if (paramType && paramType.fileLike) {
        actions.push(
            <Button
                key='download'
                size='small'
                icon={<CloudDownloadOutlined />}
                onClick={() => downloadParameterFile(record.id, downloadToken)}
            />
        );
    }

    if (paramType && !paramType.internal) {
        actions.push(
            <Button
                key='edit'
                size='small'
                icon={<EditOutlined />}
                onClick={() => navigate(`/parameters/${record.id}`)}
            />
        );
    }

    const value = (!paramType || paramType.fileLike || !paramType.internal)
        ? null
        : (
            <Formatter.JSon
                json={record.value}
            />
        );

    const metadata = (<Metadata obj={record} />);

    return (
        <div className='space-between'>
            <Space>
                <Link
                    label={record.label}
                    onClick={() => navigate(`/parameters/${record.id}`)}
                />
                {value}
                {metadata}
            </Space>
            {
                (actions.length > 0) &&
                <div className='actions'>
                    {actions}
                </div>
            }
        </div>
    );
};

const ioTYpeRender = (ioType: string) => {
    return (
        <Typography.Text type={ioType === 'ERR' ? 'danger' : undefined}>
            {ioType}
        </Typography.Text>
    );
};

const InputOutputTable: React.FC<InpurOutputTableProps> = ({ input, output, errors }) => {
    const data: TaskIORecord[] = [
        ...Object.entries(input).map(([key, taskIO]) => ({ ...taskIO, key, ioType: 'IN', index: `${key}_IN` })),
        ...Object.entries(output).map(([key, taskIO]) => ({ ...taskIO, key, ioType: 'OUT', index: `${key}_OUT` })),
        ...Object.entries(errors).map(([key, taskIO]) => ({ ...taskIO, key, ioType: 'ERR', index: `${key}_ERR` })),
    ];

    const columns: ColumnsType<TaskIORecord> = [
        {
            title: '#',
            dataIndex: 'ioType',
            width: 35,
            render: ioTYpeRender,
        },
        {
            title: 'Type',
            width: 125,
            dataIndex: 'type'
        },
        {
            title: 'Key',
            width: 275,
            dataIndex: 'key',
            render: (key: string, record) => <TaskIORecordTag record={record} />
        },
        {
            render: (_: any, record) => <TaskIORecordSummary record={record} />
        },
    ];

    return (
        <div style={{ margin: '-1px -1px 16px -1px', padding: '0px 0px 0px 15px' }}>
            <Table
                bordered
                showHeader={false}
                size='small'
                pagination={false}
                columns={columns}
                dataSource={data}
                rowKey='index'
            />
        </div>
    );
};

const showTotalText = (total: number) => `Found ${total} tasks`;

const taskIdSorter = (a: Task, b: Task) => (a.id as number || 0) - (b.id as number || 0);

const taskTypeFilter = (value: string | number | boolean, record: Task) => record.taskType.type === value;

const taskCreatedSorter = (taskA: Task, taskB: Task): number => {
    const a = taskA.created || UNIX_TIME_ORIGIN;
    const b = taskB.created || UNIX_TIME_ORIGIN;
    return dayjs(a).unix() - dayjs(b).unix();
};

const taskEndedSorter = (taskA: Task, taskB: Task): number => {
    const a = taskA.ended || UNIX_TIME_ORIGIN;
    const b = taskB.ended || UNIX_TIME_ORIGIN;
    return dayjs(a).unix() - dayjs(b).unix();
};

const taskLabelFilter = (value: number | string | boolean, record: Task) => {
    return (
        !!value &&
        !!record.label &&
        record.label.toLowerCase()
            .includes(
                value.toString().toLowerCase()
            )
    );
};

const taskLabelRenderer = (search: string, navigate: NavigateFunction) => (text: string, record: Task): ReactNode => (
    <Link
        label={<R2Highlighter
            search={search}
            text={text}
        />}
        onClick={() => navigate(`/tasks/${record.id}`)}
    />
);

const taskTypeRenderer = (_: any, task: Task) => `${task.taskType.name} ${task.taskType.version}`;

const taskDateRenderer = (text: string): string => Formatter.datetime(text);

const taskStatusRenderer = (_: any, task: Task): ReactNode => {
    if (task.status === TaskStatus.ACTIVE) {
        return (
            <ProgressStatusIndicator task={task} />
        );
    }

    if (task.status === TaskStatus.SCHEDULED && task.scheduled) {
        return (
            <ScheduledStatusIndicator task={task} />
        );
    }

    return (
        <StatusIndicator task={task} />
    );
};

const shouldExpand = (record: Task) => {
    return (record.output && Reflect.ownKeys(record.output).length > 0) ||
        (record.input && Reflect.ownKeys(record.input).length > 0);
};

const taskStatusFilter = (value: string | number | boolean, record: Task) => record.status === value;

const Tasks: React.FC = ({ }) => {
    const [search, setSearch] = useState<string>('');
    const { filteredInfo, onFilterChange } = useFilters<Task>();
    const { activeProjectId, taskTypes } = useContext(AppContext);
    const queryClient = useQueryClient();
    const navigate = useNavigate();


    const { data: tasks } = useQuery<Task[]>(
        ['tasks.get', activeProjectId],
        () => activeProjectId ? getTasks(activeProjectId) : [],
        {
            initialData: [],
            refetchInterval: 60000,
            notifyOnChangeProps: ['data'],
            refetchIntervalInBackground: true,
            refetchOnWindowFocus: true,
        }
    );

    useEffect(() => {
        queryClient.invalidateQueries(['tasks', activeProjectId]);
    }, [queryClient, activeProjectId]);

    const handleTaskDelete = (id: Id) =>
        deleteTask(id)
            .then(
                () => {
                    message.success('Task deleted.');
                    queryClient.invalidateQueries(['tasks', activeProjectId]);
                })
            .catch(
                () => message.error('Something went wrong, try again!')
            );

    const unAvailableTasks = filter(taskTypes, (t) => !t.available).map((t) => t.id);

    // add stop
    const toggleTask = (id: Id) =>
        startTask(id)
            .then(() => queryClient.invalidateQueries(['tasks', activeProjectId]))
            .then(() => message.success('Task started'))
            .catch(() => message.error('Something went wrong, try again!'));


    const taskTypeFilters = taskTypes.map(taskType => ({ text: taskType.name, value: taskType.type }));

    const statusFilters = TaskStatuses.map(status => ({ text: status, value: status }));

    const columns: ColumnsType<Task> = [
        {
            title: 'Id',
            dataIndex: 'id',
            width: 100,
            sorter: taskIdSorter,
            defaultSortOrder: 'descend' as SortOrder,
            filteredValue: filteredInfo.id || null
        },
        {
            title: 'Label',
            dataIndex: 'label',
            ellipsis: true,
            filterDropdown: TableUtils.filterDropdown(setSearch),
            filterIcon: TableUtils.filterIcon,
            onFilter: taskLabelFilter,
            filteredValue: filteredInfo.label || null,
            render: taskLabelRenderer(search, navigate)
        },
        {
            title: 'Type',
            width: 185,
            dataIndex: ['taskType', 'type'],
            ellipsis: true,
            render: taskTypeRenderer,
            filters: taskTypeFilters,
            onFilter: taskTypeFilter,
            filteredValue: filteredInfo['taskType.type'] || null
        },
        {
            title: 'Created',
            dataIndex: 'created',
            ellipsis: false,
            width: 150,
            render: taskDateRenderer,
            sorter: taskCreatedSorter,
        },
        {
            title: 'Ended',
            dataIndex: 'ended',
            ellipsis: false,
            width: 150,
            render: taskDateRenderer,
            sorter: taskEndedSorter,
        },
        {
            title: 'Status',
            width: 150,
            dataIndex: 'status',
            ellipsis: true,
            render: taskStatusRenderer,
            filters: statusFilters,
            onFilter: taskStatusFilter,
            filteredValue: filteredInfo.status || null
        },
        {
            dataIndex: 'actions',
            width: 100,
            render: (_: any, record: any) => (
                <div className='actions'>
                    <Button
                        size='small'
                        icon={<CaretRightOutlined />}
                        disabled={
                            (
                                unAvailableTasks.includes(record.taskType.id) || [TaskStatus.SCHEDULED, TaskStatus.ACTIVE].includes(record.status as TaskStatus)
                            )
                        }
                        onClick={() => toggleTask(record.id)}
                    />
                    <Button
                        size='small'
                        onClick={() => navigate(`/tasks/${record.id}`)}
                        icon={<EditOutlined />}
                    />
                    <DeleteButton
                        onConfirm={() => handleTaskDelete(record.id)}
                        disabled={[TaskStatus.SCHEDULED, TaskStatus.ACTIVE].includes(record.status as TaskStatus)}
                    />
                </div>
            ),
        },
    ];

    const actions = (
        <Button
            onClick={() => navigate('/tasks/new')}
            type='primary'
            icon={<PlusOutlined />}
            disabled={!activeProjectId}
        >
            New Task
        </Button>
    );

    return (
        <PageWrapper crumbs={crumbs} actions={actions}>
            <Table
                bordered
                rowKey='id'
                size='small'
                columns={columns}
                dataSource={tasks}
                expandable={{
                    expandedRowRender: record => (
                        <InputOutputTable
                            input={record.input}
                            output={record.output}
                            errors={record.errors || {}}
                        />
                    ),
                    rowExpandable: record => shouldExpand(record),
                }}
                pagination={{
                    defaultPageSize: 20,
                    responsive: true,
                    size: 'default',
                    showTotal: showTotalText,
                    showSizeChanger: true
                }}
                onChange={onFilterChange}
            />
        </PageWrapper>
    );
};

export default Tasks;
