import React, { ReactNode } from "react";

interface LinkProps {
    label: string | ReactNode;
    onClick: () => void;
}

const Link: React.FC<LinkProps> = ({ label, onClick }) => {
    return (
        <a className="patsearch-link" onClick={onClick}>
            {label}
        </a>
    );
};

export default Link;
