import React, { useContext, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import find from "lodash/find";

import Alert from "antd/es/alert";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import message from "antd/es/message";
import Select from "antd/es/select";

import { useForm } from "antd/lib/form/Form";

import { AppContext } from "../../contexts";

import { ParamType, UserProject } from "../../constants/types";

import { getParameter, saveParamAndValue } from "../../api";

import { ParameterComponents } from "../../components/parameters";

import { PageWrapper } from "../../components/PageWrapper";

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 20 },
    },
};

const noLabelItem = {
    wrapperCol: {
        xs: { span: 24 },
        sm: { offset: 8, span: 16 },
        md: { offset: 6, span: 20 },
    },
};

const crumbs = [
    { label: "Parameters", path: "/parameters" },
    { label: "New Parameter" },
];

const paramDefaultLabel = (paramType: ParamType | undefined | null, project: UserProject | undefined | null): string => {
    if (!paramType) {
        return '';
    }

    return project
        ? `${project.label}-${paramType.name}-##`
        : `${paramType.name}-##`;
};

const ParametersAdd: React.FC = ({ }) => {
    const {
        activeProjectId,
        paramTypes,
        availableParamTypes,
        projects
    } = useContext(AppContext);
    const { id } = useParams<"id">();
    const [form] = useForm();
    const [paramType, setParamType] = useState<ParamType>();
    const [uploading, setUploading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [currentProject, setCurrentProject] = useState<UserProject | undefined | null>(null);

    useEffect(() => {
        const p = projects.find(item => item.value === activeProjectId);
        setCurrentProject(p);
    }, [activeProjectId, projects]);

    useEffect(() => {
        if (id) {
            getParameter(id).then(
                ({ paramType: _paramType, label, data }: any) => {
                    setParamType(_paramType);
                    form.setFieldsValue({ type: _paramType.name, label, value: data });
                }
            );
        }
    }, [id, form]);

    const handleParameterSaved = (response: any) => {
        message.success("Parameter saved!");
        setUploading(false);
        if (response.id) {
            navigate(`/parameters/${response.id}`);
        }
    };

    const handleParamTypeChange = (value: string) => {
        form.resetFields(["value", "label"]);
        const pt = find(paramTypes, (t) => t.name === value);
        setParamType(pt);
    };

    const handleFinish = () => {
        setUploading(true);
        if (!activeProjectId) {
            message.warn("Please select a project!");
            return false;
        }
        try {
            form.validateFields().then(({ value, label }: any) => {
                if (paramType) {
                    const param = {
                        id: id ? parseInt(id) : undefined,
                        label,
                        data: { value },
                        project: { id: activeProjectId },
                        paramType
                    };

                    const value_blob = paramType.fileLike ? value : undefined;
                    saveParamAndValue(param, value_blob).then(handleParameterSaved);
                }
            });
        } catch (error) {
            message.error("Something went wrong, try again!");
        }
    };

    const defaultLabel = paramDefaultLabel(paramType, currentProject);

    return (
        <PageWrapper crumbs={crumbs}>
            <Form
                {...formItemLayout}
                onFinish={handleFinish}
                form={form}
                className="r2-form"
            >
                <Form.Item
                    name="type"
                    label="Param Type"
                    rules={[{ required: true, message: "" }]}
                >
                    <Select
                        onChange={handleParamTypeChange}
                        disabled={!!id}
                    >
                        {availableParamTypes.map(({ name }, idx) => (
                            <Select.Option value={name} key={idx}>
                                {name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {paramType && currentProject &&
                    <Form.Item
                        name="label"
                        label="Label"
                        rules={[{ message: "" }]}
                    >
                        <Input
                            placeholder={defaultLabel}
                            onFocus={(event) => {
                                if (!event.target.value) {
                                    const label = defaultLabel;
                                    form.setFieldsValue({ label });
                                }
                            }}
                        />
                    </Form.Item>
                }
                {
                    paramType && (ParameterComponents.add[paramType.name]
                        ? (
                            <Form.Item
                                name="value"
                                {...noLabelItem}
                                rules={[{ required: ParameterComponents.add[paramType.name].required, message: ParameterComponents.add[paramType.name].required ? "this field is mandatory" : "" }]}
                            >
                                {
                                    React.createElement(ParameterComponents.add[paramType.name].component, ParameterComponents.add[paramType.name].props)
                                }
                            </Form.Item>
                        )
                        : (
                            <Form.Item name="value" {...noLabelItem}>
                                <Alert
                                    className="param-type-not-implemented"
                                    message={`${paramType.name} not implemented`}
                                    type="error"
                                    showIcon
                                />
                            </Form.Item>
                        )
                    )
                }
                <Form.Item {...noLabelItem}>
                    <Button type="primary" htmlType="submit" loading={uploading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </PageWrapper>
    );
};

export default ParametersAdd;
