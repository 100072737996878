import React, { useContext, useEffect, useState, useMemo } from "react";

import { ParameterEditProps } from "../../../constants/types";
import { AppContext } from "../../../contexts";
import { filterTaskTypeByInputType } from "../../../utils";

import Button from "antd/es/button";
import Col from "antd/es/col";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Spin from "antd/es/spin";

import { RunTaskDialog } from "../../tasks";
import { patentSetFromString } from "./utils";
import MergeTool from "./MergeTool";
import RandomSubSetTool from "./RandomSubSetTool";
import { useParameter } from "../../../hooks/useParameter";

interface AuthCounter {
    label: string;
    count: number;
}

type AuthCounters = Map<string, AuthCounter>;

const createAuthCounters = () => new Map<string, AuthCounter>([
    ["US", { label: "US", count: 0 }],
    ["EP", { label: "EP", count: 0 }],
    ["WO", { label: "WO", count: 0 }],
    ["JP", { label: "JP", count: 0 }],
    ["Others", { label: "Others", count: 0 }]
]);

interface PatentCounterProps {
    label: string;
    counter: AuthCounter;
    total: number;
}

const PatentCounter: React.FC<PatentCounterProps> = ({ label, counter, total }) => {
    const width = counter.count === 0
        ? 0.0
        : Math.max(5, 100.0 * counter.count / total);

    return (
        <div className="patent-counter">
            <div className="counter-label"><div>{`${label} Patents`}</div><div>{counter.count}</div></div>
            <div className="counter-progress">
                <div className="ant-progress-inner ant-progress-status-success">
                    <div className="ant-progress-bg" style={{ "width": `${width}%`, height: "8px" }}>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface CountersProps {
    loading: boolean;
    patentIds: Set<string>;
}

const Counters: React.FC<CountersProps> = ({ loading, patentIds }) => {
    const [counters, setCounters] = useState<AuthCounters>(createAuthCounters());

    useEffect(() => {
        const _counters = createAuthCounters();
        patentIds.forEach(id => {
            const auth = id.substring(0, 2);
            const counter = _counters.has(auth)
                ? _counters.get(auth)
                : _counters.get("Others");
            if (counter) {
                counter.count += 1;
            }
        });
        setCounters(_counters);
    }, [patentIds]);

    const entries = Array.from(counters);

    const total = entries.map(([auth, counter]) => counter.count).reduce((tot, count) => tot + count);

    return (
        <Spin spinning={loading}>
            <div className="patent-counter-container">
                {
                    Array.from(counters).map(([auth, counter]) => <PatentCounter key={auth} label={auth} counter={counter} total={total} />)
                }
                <div className="patent-counter total">
                    <div className="counter-label">
                        <div>Total</div>
                        <div>{total}</div>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

const PatentSetEdit: React.FC<ParameterEditProps> = ({ param: initialParam }) => {
    const { taskTypes } = useContext(AppContext);

    const {
        param,
        setParam,
        data,
        loading,
        saving,
        save,
        saveParam
    } = useParameter(initialParam);

    const [patentIds, setPatentIds] = useState<Set<string>>(new Set());

    useEffect(() => {
        if (data) {
            setPatentIds(patentSetFromString(data));
        }
    }, [data]);

    const patentSetTasks = useMemo(() => filterTaskTypeByInputType(taskTypes, "PatentSet"), [taskTypes]);

    return (
        <div className='param-edit patent-set'>
            <Row>
                <Col flex="256px" className="task-actions">
                    <div className="section">
                        <RandomSubSetTool param={param} patentIds={patentIds} />
                        <MergeTool param={param} patentIds={patentIds} />

                        <div className="section-header">
                            Tasks
                        </div>

                        <div className="section-actions">
                            {
                                patentSetTasks.map(
                                    (taskType, index) =>
                                        <RunTaskDialog
                                            key={index}
                                            taskType={taskType}
                                            param={param}
                                            hiddenFields={[]}
                                        />
                                )
                            }
                        </div>
                    </div>

                    <div className="section">
                        <Counters loading={loading} patentIds={patentIds} />
                    </div>
                </Col>
                <Col flex="auto">
                    <Row className='param-edit-header'>
                        <Col span={2} className="ant-form-item-label">
                            <label title="Label">
                                Label
                            </label>
                        </Col>
                        <Col span={22}>
                            <Input
                                value={param.label}
                                onChange={(e) => setParam({ ...param, label: e.target.value })}
                            />
                        </Col>
                    </Row>
                    <Row >
                        <Col offset={2}>
                            <Button
                                type="primary"
                                disabled={saving}
                                loading={saving}
                                onClick={save}
                            >
                                Save
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>);
};

export default PatentSetEdit;
