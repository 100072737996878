import React from 'react';

import Menu from 'antd/es/menu';

import StarOutlined from '@ant-design/icons/StarOutlined';
import StarFilled from '@ant-design/icons/StarFilled';

const items = [
    {
        key: '1',
        label: (
            <>
                <StarFilled />
                <StarOutlined />
                <StarOutlined />
                <StarOutlined />
                <StarOutlined />
            </>
        ),
    },
    {
        key: '2',
        label: (
            <>
                <StarFilled />
                <StarFilled />
                <StarOutlined />
                <StarOutlined />
                <StarOutlined />
            </>
        ),
    },
    {
        key: '3',
        label: (
            <>
                <StarFilled />
                <StarFilled />
                <StarFilled />
                <StarOutlined />
                <StarOutlined />
            </>
        ),
    },
    {
        key: '4',
        label: (
            <>
                <StarFilled />
                <StarFilled />
                <StarFilled />
                <StarFilled />
                <StarOutlined />
            </>
        ),
    },
    {
        key: '5',
        label: (
            <>
                <StarFilled />
                <StarFilled />
                <StarFilled />
                <StarFilled />
                <StarFilled />
            </>
        ),
    },
];

interface RatingMenuProps {
    onClick?: (rating: number) => void;
}

const RatingMenu: React.FC<RatingMenuProps> = ({ onClick }) => (
    <Menu onClick={({ key }) => onClick && onClick(parseInt(key))} items={items} />
);

export default RatingMenu;
