import React, { HTMLAttributes, PropsWithChildren } from "react";

import Divider from "antd/es/divider";

import { Breadcrumbs } from "./Breadcrumbs";

import { Crumb } from "../constants/types";

interface PageHeaderProps {
    crumbs?: Crumb[];
    actions?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ crumbs, actions }) => {
    return (crumbs || actions)
        ? (
            <div className="content-wrapper-header">
                {
                    crumbs && (
                        <div className="crumbs-container">
                            <Breadcrumbs crumbs={crumbs} />
                        </div>
                    )
                }
                {
                    actions && (
                        <div className="actions-container">
                            <div className="actions">{actions}</div>
                        </div>
                    )
                }
            </div>
        )
        : null;
};

interface PageWrapperProps {
    crumbs?: Crumb[];
    actions?: React.ReactNode;
    title?: string;
}

export const PageWrapper: React.FC<PropsWithChildren<PageWrapperProps> & HTMLAttributes<PageWrapperProps>> = ({
    crumbs,
    actions,
    title,
    children,
    className
}) => {
    const clsName = className
        ? `content-wrapper ${className}`
        : `content-wrapper`;

    return (
        <>
            <PageHeader crumbs={crumbs} actions={actions} />
            <div className={clsName}>
                {
                    title &&
                    (
                        <>
                            <h4 className="secondary" style={{ margin: "0 1rem 0 0" }}>
                                {title}
                            </h4>
                            <Divider style={{ margin: "10px 0 10px" }} />
                        </>
                    )
                }
                {children}
            </div>
        </>
    );
};
