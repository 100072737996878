import React, { useState } from "react";

import Row from "antd/es/row";
import Button from "antd/es/button";
import TextArea from "antd/es/input/TextArea";
import Col from "antd/es/col";

import ExperimentOutlined from "@ant-design/icons/ExperimentOutlined";
import ArrowDownOutlined from "@ant-design/icons/ArrowDownOutlined";

import { formulaToRegex } from "../../utils/tools";

import Tool, { ToolOutput, ToolSection } from "./Tool";

/*
a—S—N═O.
2C-methyl-D-erythritol
4-diphosphocytidyl
*/
const ChemicalToRegexTool: React.FC = () => {
    const [text, setText] = useState<string>("");
    const [regex, setRegex] = useState<string | null>(null);

    const process = () => {
        const res = "||" + text.split("\n")
            .map(
                (line) => line.trim()
            )
            .filter(
                (line) => line && line.length > 0
            )
            .map(
                (formula) => formulaToRegex(formula)
            )
            .join("||");
        setRegex(res);
    };

    const clear = () => {
        setText("");
        setRegex(null);
    };

    return (
        <Tool
            title="Chemical Formula To Regex"
            icon={<ExperimentOutlined />}
            onHide={clear}
        >
            <div className="chemical-formula-to-regex-tool patsearch-tool">
                <Row>
                    <Col span={24}>
                        <ToolSection>
                            <TextArea rows={6} value={text} onChange={(e) => setText(e.target.value)} />
                        </ToolSection>

                        <ToolSection flex centered>
                            <Button
                                type="primary"
                                onClick={process}
                                icon={<ArrowDownOutlined />}
                            >
                                Convert
                            </Button>
                        </ToolSection>

                        <ToolOutput values={regex ? [regex] : null} />
                    </Col>
                </Row>
            </div>

        </Tool>
    );
};

export default ChemicalToRegexTool;
