import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import isEqual from 'lodash/isEqual';
import queryString from 'query-string';

export interface SearchPayload {
    query: string | undefined | null;
    entity_type?: string;
    page?: number;
    page_size?: number;
}

export interface SearchResult<ResultType, FacetType = void> {
    docs: ResultType[];
    facets?: FacetType[];
    num_found: number;
    page: number;
    page_size: number;
}

const fromLocation = (location: any): SearchPayload => {
    const qs = queryString.parse(location.search);
    const query = 'query' in qs ? (qs.query as string) : null;
    return {
        query,
        page: parseInt(qs.page as string) || 1,
        page_size: parseInt(qs.page_size as string) || 10,
    };
};

const defaultSearch = (): SearchPayload => {
    return {
        query: '',
        page: 1,
        page_size: 10,
    };
};

interface S<T, F> {
    search: SearchPayload;
    setSearch: (s: SearchPayload) => void;
    loading: boolean;
    error: boolean;
    results: SearchResult<T, F>;
}

export type DoSearch<T, F> = (s: SearchPayload) => Promise<SearchResult<T, F>>;

export const useSearch = <T, F = void>(
    doSearch: (s: SearchPayload) => Promise<SearchResult<T, F>>,
    withHistory = true
): S<T, F> => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [results, setResults] = useState<SearchResult<T, F>>({ docs: [], num_found: 0, page: 0, page_size: 0 });
    const [search, _setSearch] = useState<SearchPayload>(withHistory ? fromLocation(location) : defaultSearch());

    useEffect(() => {
        let isSubscribed = true;
        if (search.query !== undefined && search.query !== null && search.query !== '') {
            setLoading(true);
            setError(false);
            window.scrollTo(0, 0);
            doSearch(search)
                .then((docs) => {
                    if (isSubscribed) {
                        setResults(docs);
                        setLoading(false);
                    }
                })
                .catch(() => {
                    if (isSubscribed) {
                        setResults({ docs: [], num_found: 0, page: 0, page_size: 0 });
                        setError(true);
                        setLoading(false);
                    }
                });
        } else if (isSubscribed) {
            setLoading(false);
        }

        return () => {
            isSubscribed = false;
        };
    }, [search, doSearch]);

    const setSearch = (s: SearchPayload) => {
        setLoading(true);
        if (error || (!isEqual(s, search) && withHistory)) {
            navigate({ search: queryString.stringify(s) });
        }
        _setSearch(s);
    };

    return { search, setSearch, loading, error, results };
};
