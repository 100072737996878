import React, { ReactNode } from 'react';

import Form, { FormInstance } from 'antd/es/form';
import Input from 'antd/es/input';
import Checkbox from 'antd/es/checkbox';
import Button from 'antd/es/button';
import Select from 'antd/es/select';
import Spin from 'antd/es/spin';
import Space from 'antd/es/space';

import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { FormField } from '../constants/types';
import EntitySelect from './parameters/inputs/EntitySelect';

interface LayoutColSpanProps {
    span: number | string;
}

interface LayoutColProps {
    xs: LayoutColSpanProps;
    sm: LayoutColSpanProps;
    md: LayoutColSpanProps;
}

export interface Layout {
    labelCol: LayoutColProps;
    wrapperCol: LayoutColProps;
    noLabelItem: {
        wrapperCol: LayoutColProps;
    };
}

const FormLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
        md: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
    },
    noLabelItem: {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 18, offset: 6 },
            md: { span: 18, offset: 6 },
        },
    }
} as Layout;

const Components: any = {
    Input,
    Password: Input.Password,
    Checkbox,
    EntitySelect,
    Select,
    TextArea: Input.TextArea
};

interface DFormProps {
    isLoading: boolean;
    disabled?: boolean;
    configuration: FormField[];
    onFinish: (values: any) => void;
    initialValues: any;
    form: FormInstance<any>;
    [key: string]: any;
    actions?: ReactNode;
}

const R2Form: React.FC<DFormProps> = ({
    isLoading,
    disabled = false,
    configuration,
    onFinish,
    initialValues,
    form,
    actions = null,
    ...otherProps
}) => {
    const items = configuration.map((field, idx: React.ReactText) => {
        if (typeof Components[field.component.name] !== 'undefined') {
            return (
                <Form.Item
                    key={idx}
                    name={field.name}
                    label={field.label || field.name}
                    rules={field.rules || []}
                >
                    {React.createElement(Components[field.component.name], { ...field.component.props })}
                </Form.Item>
            );
        } else {
            <div>{`${field.component.name} can't be handled by this configuration.`}</div>;
        }
    });

    const controls = !isLoading && (
        <Form.Item wrapperCol={FormLayout.noLabelItem.wrapperCol}>
            <Space>
                <Button type='primary' htmlType='submit'>
                    Save
                </Button>
                {actions}
            </Space>
        </Form.Item>
    );

    return (
        <Spin indicator={<LoadingOutlined />} spinning={isLoading}>
            <Form
                labelCol={FormLayout.labelCol}
                wrapperCol={FormLayout.wrapperCol}
                className='r2-form'
                disabled={disabled}
                {...otherProps}
                onFinish={onFinish}
                initialValues={initialValues}
                form={form}>
                {items}
                {controls}
            </Form>
        </Spin>
    );
};

export { R2Form as default, FormLayout };
