import message from "antd/es/message";

import { saveParamAndValue } from "../../../api";

import { ParamType, ProjectId } from "../../../constants/types";

import { shuffle_ } from "../../../utils";

export const patentSetFromString = (data: string): Set<string> => new Set(data.split("\n").filter(id => id.length > 0));

export const patentSetToString = (patentSet: Set<string>) => Array.from(patentSet).join("\n");

export const patentSetRandomSubset = (patentSet: Set<string>, size: number) => {
    const ps = shuffle_(Array.from(patentSet));
    return new Set(ps.slice(0, Math.min(patentSet.size, size)));
};

export const saveNewPatentSet = (
    label: string,
    paramType: ParamType,
    projectId: ProjectId,
    patentSet: Set<string>,
    successMessage?: string,
    errorMessage = "Unable to save patent set"
) => {
    const newParam = {
        label,
        data: {},
        paramType,
        project: projectId
    };

    const blob = new Blob([patentSetToString(patentSet)], { type: "plain/text" });
    return saveParamAndValue(newParam, blob, "patent_set.txt")
        .then(() => {
            message.success(successMessage);
        })
        .catch(() => {
            throw errorMessage;
        });
};
