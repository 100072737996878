import React from 'react';

import Breadcrumb from 'antd/es/breadcrumb';

import { Link } from 'react-router-dom';

import { Crumb } from '../constants/types';

const buildItem = (crumb: Crumb, idx: any) => {
    const content = crumb.path
        ? (
            <Link to={crumb.path}>{crumb.label}</Link>
        )
        : (
            <span>{crumb.label}</span>
        );

    return (
        <Breadcrumb.Item key={idx}>
            {content}
        </Breadcrumb.Item>
    );
};

interface BreadcrumbsProps {
    crumbs: Crumb[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs }) => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to='/'>Home</Link>
            </Breadcrumb.Item>
            {
                crumbs.map((crumb, idx) => buildItem(crumb, idx))
            }
        </Breadcrumb>
    );
};
