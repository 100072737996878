import Input from "antd/es/input";
import InputNumber from "antd/es/input-number";
import { useEffect, useState } from "react";


interface EditableCellProps {
    value: number | string;
    number?: boolean;
    onCellChange: (value: number | string) => void;
    status?: "error" | "warning"
}

const EditableCell: React.FC<EditableCellProps> = ({
    value: initialValue,
    number = false,
    onCellChange,
    status
}: any) => {
    const [value, setValue] = useState<string | number>(initialValue || number ? 0 : '');

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const onBlur = () => {
        onCellChange(value);
    };

    if (number) {
        return (
            <InputNumber
                status={status}
                value={value}
                onChange={(val) => setValue(val || 0)}
                onBlur={onBlur}
            />
        );
    }

    return (
        <Input
            status={status}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
            onBlur={onBlur}
        />
    );
};

export default EditableCell;
