import React, { HTMLAttributes } from "react";

import uniq from 'lodash/uniq';
import flatten from 'lodash/flatten';
import find from 'lodash/find';

import Highlighter from 'react-highlight-words';
import { Marker } from '../utils/highlight';

const additionalWords = [
    "characterised in that"
];

const highlightWords = (markers: Marker[]): string[] => {
    const words = uniq(flatten(markers ? markers.filter(e => e.enabled).map(el => el.items) : []));
    return words.concat(additionalWords);
};

interface HighlightedTextProps {
    text: string;
    markers: Marker[];
}

const HighlightedText: React.FC<HighlightedTextProps & HTMLAttributes<HTMLElement>> = ({ text, markers, className }) => {
    const words = highlightWords(markers);

    const highlightTag = ({ children }: { children: string }) => {
        let baseClassName = "highlighted-marker";
        let style = {};
        const marker = find(markers, w => w.enabled && w.items.includes(children.toLowerCase()));
        if (marker) {
            style = { backgroundColor: marker.color.toString() };
        } else {
            baseClassName = "highlighted-word";
        }
        return (
            <strong className={baseClassName} style={style}>
                {children}
            </strong>
        );
    };

    return <Highlighter
        className={className}
        searchWords={words}
        autoEscape={true}
        textToHighlight={text}
        highlightTag={highlightTag}
    />;
};

export default HighlightedText;
