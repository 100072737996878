import React, { useMemo, useContext } from 'react';
import { useNavigate } from 'react-router';

import { AppContext } from '../contexts';

import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Avatar from 'antd/es/avatar';
import LogoutOutlined from '@ant-design/icons/LogoutOutlined';

import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { MenuInfo } from 'rc-menu/lib/interface';

import { User, userFullName, UserRole } from '../constants/types';

interface UserMenuActions {
    [key: string]: () => void;
}

interface UserLabelProps {
    user?: User;
}

const UserLabel: React.FC<UserLabelProps> = ({ user }) => {
    if (!user) {
        return (
            <>
                <div className='title'>Guest</div>
            </>
        );
    }

    const className = user.roles.includes(UserRole.Admin) ? 'user-info role-admin' : 'user-info role-user';
    return (
        <div className='user-label'>
            <div>
                <div className='title'>{userFullName(user)}</div>
            </div>

            <div>
                @<span className={className}>{user.preferredUsername}</span> &#183; {user.email}
            </div>
        </div>
    );
};

const avatarLabel = (user: User | null | undefined): string => {
    if (!user) {
        return 'ツ';
    }
    return `${user.firstName} ${user.lastName}`.trim()[0];
};

const UserIndicator: React.FC = () => {
    const {
        user,
        logout
    } = useContext(AppContext);
    const navigate = useNavigate();

    const items = useMemo(
        () => [
            {
                key: 'username',
                label: <UserLabel user={user} />
            },
            {
                type: 'divider'
            },
            {
                key: 'logout',
                icon: <LogoutOutlined />,
                label: 'Logout',
            }
        ] as ItemType[],
        [user]
    );

    const actions = useMemo(
        () => {
            return {
                'username': () => {
                    navigate('/system');
                },

                'logout': () => {
                    logout();
                }
            } as UserMenuActions;
        },
        [logout, navigate]
    );

    if (!user) {
        return null;
    }

    const handleMenuAction = ({ key }: MenuInfo) => actions[key] && actions[key]();

    const overlay = (
        <Menu className='avatar-menu' items={items} onClick={handleMenuAction} />
    );

    return (
        <Dropdown
            overlay={overlay}
            overlayStyle={{ position: 'fixed' }}
            trigger={['click']}
        >
            <Avatar style={{ textTransform: 'capitalize' }}>
                {avatarLabel(user)}
            </Avatar>
        </Dropdown>
    );
};

export { UserIndicator as default, UserLabel };

