import React from 'react';
import AntCheckbox from 'antd/es/checkbox';

interface CheckboxProps {
    value: any;
    onChange: (data: any) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({ value, onChange }) => {
    return (
        <AntCheckbox checked={value && value.value && value.value.value} onChange={(event) => {
            const v = event.target.checked
                ? { value: event.target.checked }
                : null;
            onChange({ value: v });
        }} />
    );
};

export default Checkbox;
