import { useState } from 'react';

import isArray from 'lodash/isArray';

import queryString from 'query-string';

import { useLocation, useNavigate } from 'react-router-dom';

import { FilterValue, Key } from 'antd/es/table/interface';

import { TableProps } from 'antd/es/table';


const filteredInfoFromLocation = (location: any): Record<string, FilterValue | null> => {
    const filteredInfo: Record<string, FilterValue | null> = {};

    Object.entries(queryString.parse(location.search))
        .map(
            ([key, value]) => {
                const vs: Key[] = [];
                if (isArray(value)) {
                    value.forEach(v => !!v && vs.push(v));
                } else if (value) {
                    vs.push(value);
                }
                filteredInfo[key] = vs;
            }
        );

    return filteredInfo;
};

interface F<RecordType> {
    filteredInfo: Record<string, FilterValue | null>;
    onFilterChange: TableProps<RecordType>['onChange'];
}

export const useFilters = <RecordType>(): F<RecordType> => {
    const location = useLocation();
    const navigate = useNavigate();
    const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>(filteredInfoFromLocation(location));

    const onFilterChange: TableProps<RecordType>['onChange'] = (_, filters, __) => {
        //
        // Clean up query string by removing empty keys
        //
        const qs = Object.fromEntries(
            Object.entries(filters)
                .filter(([_1, value]) => !!value)
        );

        navigate({ search: queryString.stringify(qs) });
        setFilteredInfo(filters);
    };

    return { filteredInfo, onFilterChange };
};
