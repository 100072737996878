import { fetchPatentMainImage } from "../../../api";
import { PATSEARCH_API_KEY_HEADER } from "../../../constants";
import { PatentDocument } from "../../../constants/types";
import { RankingReportImage, RankingReportPatent } from "./types";
import { RankingRecord } from "../RankingOutput/types";

const fileUrl = (path: string, token: string | null | undefined): string => {
    return token
        ? `/api/files/get?filename=${path}&${PATSEARCH_API_KEY_HEADER}=${token}`
        : `/api/files/get?filename=${path}`;
}

export const imageName = (image: RankingReportImage): string => {
    return image.name
        ||
        (
            image.path
                ? image.path.substring(image.path.lastIndexOf('/') + 1)
                : image.data
                    ? image.data.name
                    : ""
        );
}

export const imageSrc = (image: RankingReportImage, token: string | null | undefined): string | undefined => {
    if (image.path) {
        return fileUrl(image.path, token);
    }

    if (image.data) {
        return URL.createObjectURL(image.data);
    }

    return undefined;
};

const patentFirstClaim = function (document?: PatentDocument): string {
    if (document && document.claims && document.claims.length > 0) {
        return document.claims[0];
    }
    return "";
}

export const merge = function (patent?: PatentDocument, record?: RankingRecord, rankingId: number = 0, rating: number = 0): RankingReportPatent {
    const rankingPatent = {
        ...patent,
        ...record,
        rankingOutputId: rankingId,
        rating: rating,
        claim: patentFirstClaim(patent),
        comment: "",
        images: []
    } as RankingReportPatent;

    return rankingPatent;
}


export const addPatentMainImage = async function (patent: RankingReportPatent) {
    if (patent.images.length === 0) {
        const image = await fetchPatentMainImage(patent.id);
        if (image) {
            patent.images.push({
                uid: image.uid,
                name: image.name,
                data: new File([image.file], image.name, { type: image.file.type })
            });
        }
    }

    return patent;
};
