import React from "react";

import TextArea from "antd/lib/input/TextArea";

interface SearchQueryViewProps {
    value: string;
    onChange: (data: any) => void;
}

const SearchQueryAdd: React.FC<SearchQueryViewProps> = ({ value, onChange }) => {
    return (
        <TextArea
            value={value}
            onChange={onChange}
            rows={8}
        />
    );
};

export default SearchQueryAdd;
