import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import Table from "antd/es/table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Tag from "antd/es/tag";

import EditOutlined from "@ant-design/icons/EditOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import LockTwoTone from "@ant-design/icons/LockTwoTone";
import UnlockTwoTone from "@ant-design/icons/UnlockTwoTone";

import { PageWrapper } from "../../components/PageWrapper";
import { getProjects, getProjectsAdmin } from "../../api";

import { Id, Project, User, userIsAdmin } from "../../constants/types";
import { ColumnsType } from "antd/lib/table/interface";

import { AppContext } from "../../contexts";

import Link from "../../components/Link";

import Formatter from "../../components/Formatter";
import TableUtils from "../../components/TableUtils";
import { useFilters } from "../../hooks/useFilters";
import R2Highlighter from "../../components/R2Highlighter";

const crumbs = [{ label: "Projects" }];

interface ProjectTitleProps {
    project: Project;
    user?: User;
    search?: string;
}

const ProjectUserRole: React.FC<ProjectTitleProps> = ({ project, user }) => {
    if (!user) {
        return null;
    }

    if (project.owner.id === user.id) {
        return <Tag>Owner</Tag>;
    }

    if (project.users.map(u => u.id).includes(user.id)) {
        return <Tag>User</Tag>;
    }

    return null;
};

const ProjectTitle: React.FC<ProjectTitleProps> = ({ project, user, search }) => {
    const navigate = useNavigate();

    const active = project.active
        ? <UnlockTwoTone className="active" />
        : <LockTwoTone className="active" twoToneColor="red" />;

    return (
        <div className="project-info">
            <Space>
                {active}
                <Link
                    label={
                        <div style={{ whiteSpace: "nowrap" }}>
                            <R2Highlighter search={search} text={project.name} />
                        </div>
                    }
                    onClick={
                        () => navigate(`/projects/${project.id}`)
                    }
                />
                <ProjectUserRole project={project} user={user} />
            </Space>
        </div>
    );
};

const Projects: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<Project[]>([]);
    const { user } = useContext(AppContext);
    const { filteredInfo, onFilterChange } = useFilters<Project>();
    const [search, setSearch] = useState<string>('');

    useEffect(() => {
        let isSubcribed = true;
        const fetcher = userIsAdmin(user) ? getProjectsAdmin : getProjects;
        navigate(`/projects`);
        fetcher()
            .then(
                (projects) => {
                    if (isSubcribed) {
                        setData(projects);
                    }
                }
            );
        return () => {
            isSubcribed = false;
        };
    }, [navigate, user]);

    const columns: ColumnsType<Project> = [
        {
            title: "Id",
            dataIndex: "id",
            width: 50
        },
        {
            title: "Name",
            dataIndex: "name",
            filterDropdown: TableUtils.filterDropdown(setSearch),
            filterIcon: TableUtils.filterIcon,
            onFilter: TableUtils.filter((project) => project.name),
            filteredValue: filteredInfo.name || null,
            render: (_: string, record: Project) => <ProjectTitle project={record} user={user} search={search} />
        },
        {
            title: "Info",
            dataIndex: "id",
            render: (_: Id, record: Project) => {
                const tags = record.tags
                    .map(
                        (t, i) => <Tag key={i}>{t}</Tag>
                    );

                return (
                    <div className="space-between">
                        <div>
                            {record.client}
                        </div>
                        <div>
                            {tags}
                        </div>
                    </div>
                );
            }
        },
        {
            title: "Created",
            dataIndex: "created",
            ellipsis: false,
            width: 150,
            render: (text: string) => Formatter.datetime(text),
        },
        {
            title: "Modified",
            dataIndex: "modified",
            ellipsis: false,
            width: 150,
            render: (text: string) => Formatter.datetime(text),
        },
        {
            dataIndex: "actions",
            width: 40,
            align: "right" as const,
            render: (_: any, record: { active: any; id: any }) => (
                <Button
                    size="small"
                    onClick={() => navigate(`/projects/${record.id}`)}
                    icon={<EditOutlined />}
                />
            )
        },
    ];

    const actions = (
        <Space>
            <Button
                onClick={() => navigate("/projects/new")}
                type="primary"
                icon={<PlusOutlined />}
            >
                New Project
            </Button>
        </Space>
    );

    return (
        <PageWrapper crumbs={crumbs} actions={actions}>
            <Table
                bordered
                rowKey="id"
                size="small"
                columns={columns}
                dataSource={data}
                onChange={onFilterChange}
            />
        </PageWrapper>
    );
};

export default Projects;
