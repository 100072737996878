import React from "react";
import { RankingRecord } from "../RankingOutput/types";

interface RegexRowProps {
    matches: { string: number[] }
}

const RegexRow: React.FC<RegexRowProps> = ({ matches }) =>
(
    <>
        {
            Object.entries(matches).map(([key, value]) =>
                <tr key={key}>
                    <th>{key}</th>
                    <td>{value[0]}</td>
                    <td>{value[1]}</td>
                    <td>{value[2]}</td>
                    <td>{value[3]}</td>
                </tr>
            )
        }
    </>
);

interface RegexMatchProps {
    record: RankingRecord
}

const RegexMatch: React.FC<RegexMatchProps> = ({ record }) => {
    const { regex } = record;
    return (
        <div className="ranking-regex">
            {
                regex.map((matches, i) => {
                    return (
                        <div key={i}>
                            <table >
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>T</th>
                                        <th>A</th>
                                        <th>C</th>
                                        <th>D</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <RegexRow matches={matches} />
                                </tbody>
                            </table>
                        </div>
                    );
                })
            }
        </div>
    );
};

export default RegexMatch;
