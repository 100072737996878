import React from 'react';

import Upload from 'antd/es/upload';
import Button from 'antd/es/button';

import InboxOutlined from '@ant-design/icons/InboxOutlined';
import FileOutlined from '@ant-design/icons/FileOutlined';

interface FileLoaderProps {
    emptyFilename: string,
    value: any;
    onChange: (file: any) => void;
}

const FileLoader: React.FC<FileLoaderProps> = ({ emptyFilename, value, onChange }) => {
    const setEmpty = (event: any) => {
        if (event) {
            event.stopPropagation();
        }
        const empty = new File([], emptyFilename);
        onChange(empty);
    };

    const props = {

        multiple: false,

        onRemove: (_: any) => {
            onChange(null);
        },

        beforeUpload: (_file: any) => {
            onChange(_file);
            return false;
        },

        fileList: value ? [value] : [],
    };


    return (
        <div className='file-param'>

            <Upload.Dragger {...props} className='file-uploader'>
                <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                </p>
                <p className='ant-upload-text'>
                    Click or drag file to this area to upload
                </p>
            </Upload.Dragger>

            <div className='file-empty-action'>
                <Button icon={<FileOutlined />} onClick={(e) => setEmpty(e)}>
                    Empty
                </Button>
            </div>
        </div>
    );
};

export default FileLoader;
