import React, { useContext, useEffect } from 'react';

import { ParameterEditProps } from '../../../constants/types';
import { downloadParameterFile } from '../../../api';

import {
    Form,
    Button,
    Input,
    message,
    Space
} from 'antd';
import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';
import { useParameter } from '../../../hooks/useParameter';
import { AppContext } from '../../../contexts';

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { offset: 4, span: 8 }
};

const FileEdit: React.FC<ParameterEditProps> = ({ param: initialParam }) => {
    const { downloadToken } = useContext(AppContext);
    const {
        param,
        setParam,
        save,
        saving
    } = useParameter(initialParam, false);
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            label: initialParam.label
        });
    }, [initialParam, form]);

    const onValuesChange = (_: any, values: any) => {
        const p = {
            ...param,
            label: values.label
        };
        setParam(p);
    };

    const onFinishFailed = (errorInfo: any) => {
        message.error('Failed:', errorInfo);
    };

    return (
        <div className='param-edit'>
            <Form
                {...layout}
                name='file-param-edit'
                form={form}
                onFinish={save}
                onFinishFailed={onFinishFailed}
                onValuesChange={onValuesChange}
                className='r2-form'
            >
                <Form.Item
                    label='Label'
                    name='label'
                    rules={[{ required: true, message: 'Parameter Label' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Space>
                        <Button
                            type='primary'
                            htmlType='submit'
                            loading={saving}
                            disabled={saving}
                        >
                            Save
                        </Button>
                        <Button
                            htmlType='button'
                            onClick={() => param.id && downloadParameterFile(param.id, downloadToken)}
                            icon={<CloudDownloadOutlined />}
                        >
                            Download
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};

export default FileEdit;
