import React, { useState } from 'react';

import { Parameter } from '../../constants/types';

import Button from 'antd/es/button';
import Popover from 'antd/es/popover';
import Skeleton from 'antd/es/skeleton';
import Typography from 'antd/es/typography';

import EyeOutlined from '@ant-design/icons/EyeOutlined';

import { getParameterFile } from '../../api';
import { isObject } from 'lodash';


const FILE_TYPES: string[] = [
    'FileText',
    'PatentSet',
    'RankingOutput',
    'RankingGrammar',
    'FileLog'
];

interface PreviewContentProps {
    content: React.ReactNode | string | Record<string, unknown> | null;
    loading?: boolean
}

const PreviewContent: React.FC<PreviewContentProps> = ({ content, loading = false }) => {
    const preview = isObject(content)
        ? <code>{JSON.stringify(content)}</code>
        : content;

    return loading
        ? (
            <div className='content'>
                <Skeleton
                    loading
                    title={false}
                    paragraph={{ rows: 4, width: 284 }}
                />
            </div>
        ) : (
            <div className='content' style={{ width: 284 }}>
                <Typography.Text >
                    {preview}
                </Typography.Text>
            </div>
        );
};

interface PreviewProps {
    param: Parameter;
}

const PreviewFileText: React.FC<PreviewProps> = ({ param }) => {
    const [data, setData] = useState<string | null>(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const toggleContentPreview = () => {
        if (!data) {
            let isSubscribed = true;
            setLoading(true);
            setVisible(true);
            getParameterFile(param.id!)
                .then(
                    newData => {
                        if (isSubscribed) {
                            setLoading(false);
                            setData(newData);
                        }
                    }
                ).finally(
                    () => {
                        isSubscribed = false;
                    }
                );
        }
    };

    return (
        <Popover
            overlayClassName='r2-popover'
            placement='bottom'
            trigger={['click']}
            content={<PreviewContent content={data} loading={loading} />}
            visible={visible}
            onVisibleChange={(v) => setVisible(v)}
        >
            <Button
                size='small'
                type='text'
                onClick={() => toggleContentPreview()}
                icon={<EyeOutlined />}
            />
        </Popover>
    );
};

const PreviewInternal: React.FC<PreviewProps> = ({ param }) => {
    return (
        <Popover
            overlayClassName='r2-popover'
            placement='bottom'
            trigger={['click']}
            content={<PreviewContent content={param.data} />}
        >
            <Button
                size='small'
                type='text'
                icon={<EyeOutlined />}
            />
        </Popover>
    );
};


const Preview: React.FC<PreviewProps> = ({ param }) => {
    if (FILE_TYPES.includes(param.paramType.name)) {
        return (
            <PreviewFileText param={param} />
        );
    }

    if (!param.paramType.fileLike) {
        return (
            <PreviewInternal param={param} />
        );
    }

    return null;
};

export default Preview;
