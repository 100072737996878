import React, { useContext } from 'react';

import Editor from '@monaco-editor/react';

import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Space from 'antd/es/space';

import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';

import { ParameterEditProps } from '../../../constants/types';

import { downloadParameterFile } from '../../../api';

import { MONACO_CONFIG } from '../../../constants';

import { useParameter } from '../../../hooks/useParameter';
import { AppContext } from '../../../contexts';


const FileTextEdit: React.FC<ParameterEditProps> = ({ param: initialParam, readonly }) => {
    const { downloadToken } = useContext(AppContext);
    const {
        param,
        setParam,
        data,
        setData,
        saving,
        loading,
        saveAsNewParam
    } = useParameter(initialParam);

    return (
        <div className='param-edit param-text'>
            <div className='param-edit-header'>
                <div className='label'>
                    <Input value={param.label} onChange={(e) => setParam({ ...param, label: e.target.value })} />
                </div>

            </div>

            <div className='editor-wrapper'>
                <Editor
                    value={data}
                    options={MONACO_CONFIG}
                    onChange={(v) => v && setData(v)}
                />
            </div>

            <div className='param-edit-footer'>
                <div className='actions-toolbar'>
                    <Space>
                        {
                            !readonly && (
                                <Button
                                    type='primary'
                                    disabled={saving}
                                    loading={loading || saving}
                                    onClick={saveAsNewParam}
                                >
                                    Save
                                </Button>
                            )
                        }
                        <Button
                            onClick={() => param.id && downloadParameterFile(param.id, downloadToken)}
                            icon={<CloudDownloadOutlined />}
                        >
                            Download
                        </Button>
                    </Space>
                </div>
            </div>
        </div >
    );
};

export default FileTextEdit;
