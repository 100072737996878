import Color from 'color';
import { isNumber } from 'lodash';

import { Layout } from '../components/Form';
import { TaskType } from '../constants/types';

const Literals = { PasswordPlaceholder: '••••••••••••••••', Elipsis: '…' };

const HAS_NUMERIC_FORMAT = /^[0-9]+[,|.]?[0-9]*$/gm;

const removeTags = (str: string): string => str.replace(/<\/?[^>]+(>|$)/g, '');

const summary = (str: string, maxLength = 300, elipsis = true): string => {
    if (str.length < maxLength) {
        return str;
    }

    let sub = str.substring(0, maxLength);
    const lastSpace = sub.lastIndexOf(' ');
    if (lastSpace > 0) {
        sub = str.substring(0, lastSpace);
    }

    return elipsis ? sub + Literals.Elipsis : sub;
};

const parsePatentId = (str: string): RegExpMatchArray | null => str.match(/^([A-z]+)([0-9]+)_?([A-Z]?[0-9]?)/);

const getAuthority = (id: string) => {
    return id.substring(0, 2);
};

const _authStyle = (authority: string) => {
    switch (authority) {
        case 'EP':
            return {
                border: '1px solid #389e0d',
                color: '#389e0d',
                backgroundColor: '#daf9bb',
            };
        case 'RU':
            return {
                border: '1px solid #720a13',
                color: '#fff1f0',
                backgroundColor: '#cf1322',
            };
        case 'CN':
            return {
                border: '1px solid #81290F',
                color: '#F7D800',
                backgroundColor: '#D7290F',
            };
        case 'US':
            return {
                border: '1px solid #096dd9',
                color: '#096dd9',
                backgroundColor: '#e6f7ff',
            };
        case 'WO':
            return {
                border: '1px solid #133A8D',
                color: '#133A8D',
                backgroundColor: '#77C8EC',
            };
        case 'JP':
            return {
                border: '1px solid #952c2c',
                color: '#ffffff',
                backgroundColor: '#f34646',
            };
        case 'DE':
            return {
                border: '1px solid #101010',
                color: '#ffffff',
                backgroundColor: '#808080',
            };

        default:
            return {};
    }
};

const authorityStyle = (authority: string) => {
    const baseStyle = {
        lineHeight: '22px',
    };
    return { ...baseStyle, ..._authStyle(authority) };
};

const capitalize = (str: string): string =>
    str
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
        .join(' ');

const hashCode = (s: string) => {
    let hash = 0;
    if (s.length === 0) return hash;
    for (let i = 0; i < s.length; i++) {
        hash = s.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
};

const generateHue = (word: string): number => {
    const hash = hashCode(word);
    return hash % 360;
};

const stringToColor = (str: string, saturation = 93, luminance = 92): Color => {
    const hue = generateHue(str);
    return new Color([hue, saturation, luminance, 0.85], 'hsl');
};

const prop = <T, K extends keyof T>(obj: T, key: K) => obj[key];

const setTargetNotNull = <T>(target: T, setTarget: (t: T) => void, value: T): void => {
    if (target === null) {
        setTarget(value);
    }
};

const filterTaskTypeByInputType = (taskTypes: TaskType[], inputType: string): TaskType[] => {
    return taskTypes.filter(
        (taskType) => Object.entries(taskType.input).filter(([_, meta]) => meta.type === inputType).length > 0
    );
};

const layoutClassName = (layout: Layout | undefined): string | undefined => {
    if (!layout) return undefined;
    const { md, sm, xs } = layout.labelCol;
    return `col-md-${md.span} col-sm-${sm.span} col-xs-${xs.span}`;
};

const shuffle_ = (array: any[]) => {
    let currentIndex = array.length;
    let randomIndex = 0;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
};

const parseRankingOutput = (data: string): any[] =>
    data
        .trim()
        .split('\n')
        .map((d) => JSON.parse(d));

const isNullOrEmpty = (obj: any | null | undefined): boolean => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

class ParseNumberError extends Error {
    constructor(message: any) {
        super(message);
        this.name = this.constructor.name;

        // eslint-disable-next-line prefer-reflect
        Object.setPrototypeOf(this, ParseNumberError.prototype);
    }
}

const parseNumber = (str: string): number => {
    const checking = str.trim();
    if (!checking.match(HAS_NUMERIC_FORMAT)) {
        throw new ParseNumberError(`${checking} is not a valid number`);
    }

    return parseFloat(checking);
};

export {
    Literals,
    removeTags,
    summary,
    getAuthority,
    authorityStyle,
    capitalize,
    stringToColor,
    prop,
    setTargetNotNull,
    filterTaskTypeByInputType,
    layoutClassName,
    shuffle_,
    parseRankingOutput,
    isNullOrEmpty,
    parseNumber,
};
