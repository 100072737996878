import React, { ReactNode, useState } from "react";

import axios, { AxiosError } from "axios";

import Button from "antd/es/button";
import Space from "antd/es/space";
import Drawer from "antd/es/drawer";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Popover from "antd/es/popover";
import Input from "antd/es/input";
import Tooltip from "antd/es/tooltip";
import Alert from "antd/es/alert";

import LinkOutlined from "@ant-design/icons/LinkOutlined";
import ToolOutlined from "@ant-design/icons/ToolOutlined";

interface Tool {
    label: string;
    url?: (payload: string) => string;
    onClick?: (payload: string) => Promise<ReactNode>;
    external: boolean;
}

interface AnalysisPayload {
    query: string;
    field_name: "Abstract" | "Assignee";
}

interface AnalysisResponse {
    text: string;
    type: string;
    keyword: boolean;
}

const doAnalysis = (search: AnalysisPayload): Promise<AnalysisResponse[]> => axios.post("/api/services/pa/search/analysis", search);

const solrStemmerRenderer = (payload: string, items: AnalysisResponse[]): ReactNode => {
    return (
        <>
            <div className="section">
                <div className="title">Solr Stemmer</div>
                <code>
                    {payload}
                </code>
            </div>

            <div className="section">
                <div className="ant-table ant-table-small ant-table-bordered">
                    <div className="ant-table-container">
                        <div className="ant-table-content">
                            <table style={{ tableLayout: "auto" }}>
                                <thead className="ant-table-thead">
                                    <tr>
                                        <th>Text</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody className="ant-table-tbody">
                                    {
                                        items.map((item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {
                                                            item.keyword
                                                                ? <Tooltip title="Keyword"><strong>{item.text}</strong></Tooltip>
                                                                : item.text
                                                        }
                                                    </td>
                                                    <td width={"50px"}>
                                                        {item.type}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const solrStemmerErrorRenderer = (error: AxiosError): ReactNode => {
    const message = (
        <>
            <div>Error</div>
            <div>
                {
                    error.message
                }
            </div>
        </>
    );

    return (
        <Alert
            message={message}
            type="error"
            showIcon
        />
    );
};

const tools = [
    {
        label: "Solr Text Stemmer",
        onClick: (payload) => doAnalysis({ query: payload, field_name: "Abstract" })
            .then(items => solrStemmerRenderer(payload, items))
            .catch(error => solrStemmerErrorRenderer(error)),
        external: false,
    },
    {
        label: "Solr Assignee Stemmer",
        onClick: (payload) => doAnalysis({ query: payload, field_name: "Assignee" })
            .then(items => solrStemmerRenderer(payload, items))
            .catch(error => solrStemmerErrorRenderer(error)),
        external: false,
    },
    {
        label: "wordreference.com (eng - it)",
        url: (payload) => `https://www.wordreference.com/enit/${payload}`,
        external: true
    },
    {
        label: "wordreference.com (it - eng)",
        url: (payload) => `https://www.wordreference.com/iten/${payload}`,
        external: true
    },
    {
        label: "thesaurus.com",
        url: (payload) => `https://www.thesaurus.com/browse/${payload}`,
        external: true
    },
    {
        label: "synonyms.net",
        url: (payload) => `https://www.synonyms.com/synonym/${payload}`,
        external: true
    },
    {
        label: "translate.google.com (eng - it)",
        url: (payload) => `https://translate.google.com/?sl=en&tl=it&op=translate&text=${payload}`,
        external: true
    },
    {
        label: "translate.google.com (it - eng)",
        url: (payload) => `https://translate.google.com/?sl=it&tl=en&op=translate&text=${payload}`,
        external: true
    },
    {
        label: "google.com",
        url: (payload) => `https://www.google.com/search?q=${payload}`,
        external: true
    },
    {
        label: "image.google.com",
        url: (payload) => `https://www.google.com/search?site=imghp&tbm=isch&q=${payload}`,
        external: true
    }
] as Tool[];

interface ToolLinkProps {
    tool: Tool;
    payload: string;
    outputSetter: (node: ReactNode) => void;
}

const ToolLink: React.FC<ToolLinkProps> = ({ tool, payload, outputSetter }) => {
    const onClick = tool.external
        ? (url: string) => tool.url && window.open(tool.url(url), "_blank")
        : (url: string) => tool.onClick && tool.onClick(url).then(node => outputSetter(node));

    return (
        <div className="tool" onClick={() => onClick(payload)}>
            <Space>
                {tool.external && <LinkOutlined />}
                {tool.label}
            </Space>
        </div>
    );
};

const ToolsDrawer: React.FC = () => {
    const [value, setValue] = useState<string>("");
    const [visible, setVisible] = useState<boolean>(false);
    const [output, setOutput] = useState<null | ReactNode>(null);

    const onClose = () => {
        setValue("");
        setOutput(null);
        setVisible(false);
    };

    const outputSetter = (node: ReactNode) => {
        setValue("");
        setOutput(node);
    };

    const overlay = (
        <div className="tool-container">
            {
                tools.map((tool, i) => (
                    <ToolLink
                        key={i}
                        tool={tool}
                        payload={value}
                        outputSetter={outputSetter}
                    />
                ))
            }
        </div>
    );

    return (
        <>
            <Button
                className="drawer-button tools-drawer-button"
                icon={<ToolOutlined />}
                onClick={() => setVisible(true)}
            />
            <Drawer
                width={320}
                className="patsearch-tool"
                placement="right"
                title={
                    <div className="justify-between">
                        <span>
                            Tools
                        </span>
                    </div>
                }
                closable={false}
                visible={visible}
                onClose={onClose}
            >
                <Row>
                    <Col flex={"100%"} className="section">
                        <Popover
                            visible={!!value}
                            placement="bottom"
                            content={overlay}>
                            <Input
                                allowClear
                                value={value}
                                onChange={(event) => setValue(event.target.value)}
                                style={{ width: "100%" }}
                            />
                        </Popover>
                    </Col>
                    <Col flex={"100%"} className="section">
                        {output}
                    </Col>
                </Row>
            </Drawer>
        </>
    );
};

export default ToolsDrawer;
