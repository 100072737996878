import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getParameters } from '../../../api';
import { AppContext } from '../../../contexts';
import EntitySelect from './EntitySelect';
import { Id, Parameter } from '../../../constants/types';

import find from 'lodash/find';

import Metadata from '../../Metadata';

interface ParameterSelectProps {
    value: any;
    onChange: (data: any) => void;
    paramType: string;
    style?: any;
}

const ParameterSelect: React.FC<ParameterSelectProps> = ({
    value,
    onChange,
    paramType,
    style,
}) => {
    const [selected, setSelected] = useState<Parameter | undefined>();
    const { activeProjectId } = useContext(AppContext);
    const { data: params } = useQuery(
        ['params', activeProjectId, paramType],
        () => getParameters({ params: { projectId: activeProjectId, paramType } }),
        {
            initialData: [],
            notifyOnChangeProps: ['data'],
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        if (!!params && !!value) {
            setSelected(find(params, (p: Parameter) => p.id === value.id));
        }
    }, [value, params]);

    const options = useMemo(
        () => (params ? params.map(({ id, label }) => ({ value: id as Id, label: `#${id} - ${label}` })) : []),
        [params]
    );

    return (
        <>
            <EntitySelect
                value={value}
                onChange={onChange}
                options={options}
                style={style}
            />
            <div className='metadata-wrapper'>
                <Metadata obj={selected} />
            </div>
        </>
    );
};

export default ParameterSelect;
