import React, { useContext, useEffect, useState } from "react";

import message from "antd/es/message";
import Alert from "antd/es/alert";
import Button from "antd/es/button";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Switch from "antd/es/switch";
import Tabs from "antd/es/tabs";
import Table from "antd/es/table";
import Result from "antd/es/result";

import FileAddOutlined from "@ant-design/icons/FileAddOutlined";
import HomeOutlined from "@ant-design/icons/HomeOutlined";
import LikeOutlined from "@ant-design/icons/LikeOutlined";

import { SortOrder, ColumnsType } from "antd/lib/table/interface";

import { useParams, useNavigate } from "react-router-dom";

import { useDForm } from "../../hooks/useDForm";

import {
    getProject,
    getProjectFormConfiguration,
    postProject,
    getParameters,
    saveParamAndValue,
    postProjectAdmin,
    getProjectAdmin
} from "../../api";

import { AppContext } from "../../contexts";

import { Parameter, Project, User, userIsAdmin } from "../../constants/types";

import DForm from "../../components/Form";
import { PageWrapper } from "../../components/PageWrapper";
import { useQueryClient } from "react-query";

interface ParamSelectorProps {
    projectId?: string;
}

const ParamSelector: React.FC<ParamSelectorProps> = ({ projectId }) => {
    const [grammars, setGrammars] = useState<Parameter[]>([]);
    const [searchs, setSearchs] = useState<Parameter[]>([]);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);

    useEffect(() => {
        let isSubscribed = true;
        if (projectId) {
            getParameters({ params: { projectId } }).then(
                (params) => {
                    const gs: Parameter[] = [];
                    const ss: Parameter[] = [];
                    params.forEach(param => {
                        if (param.paramType.name === "RankingGrammar") {
                            gs.push(param);
                        }
                        if (param.paramType.name === "SearchQuery") {
                            ss.push(param);
                        }
                    });
                    if (isSubscribed) {
                        setGrammars(gs);
                        setSearchs(ss);
                    }
                }
            );
        }

        return () => {
            isSubscribed = false;
        };
    }, [projectId]);

    const updateParam = (param: Parameter, relevant: boolean) => {
        let isSubscribed = true;
        setIsUpdating(true);
        saveParamAndValue({ ...param, relevant }).then(
            (p: Parameter) => {
                const [collection, setter] = (p.paramType.name === "RankingGrammar")
                    ? [[...grammars], setGrammars]
                    : [[...searchs], setSearchs];
                collection.forEach(g => {
                    if (g.id === p.id) {
                        g.relevant = p.relevant;
                    }
                });
                if (isSubscribed) {
                    setter(collection);
                }
            }
        ).catch(
            () => {
                message.error("Unable to update parameter");
            }
        ).finally(
            () => {
                if (isSubscribed) {
                    setIsUpdating(false);
                }
                isSubscribed = false;
            }
        );
    };

    if (!projectId) {
        return null;
    }

    const columns: ColumnsType<Parameter> = [
        {
            title: "Id",
            dataIndex: "id",
            width: 100,
            sorter: (a: Parameter, b: Parameter) => (a.id || 0) - (b.id || 0),
            defaultSortOrder: 'descend' as SortOrder
        },
        {
            title: "Label",
            dataIndex: "label",
            ellipsis: true
        },
        {
            title: <div style={{ display: "flex", justifyContent: "center" }}><LikeOutlined style={{ fontSize: "16px" }} /></div>,
            dataIndex: "actions",
            width: 46,
            render: (_: any, record: Parameter) => (
                <div className='actions'>
                    <Switch style={{ margin: "0" }} disabled={isUpdating} size="small" checked={record.relevant} onClick={(value) => updateParam(record, value)} />
                </div>
            )
        }
    ];

    return (
        <>
            <Row gutter={16} style={{ marginBottom: "16px" }}>
                <Col span={24}>
                    <Alert
                        message="Select relevant Ranking Grammars and Search Queries"
                        type="info"
                        showIcon
                    />
                </Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: "32px" }}>
                <Col span={12}>
                    <Table
                        bordered
                        rowKey="id"
                        size="small"
                        pagination={false}
                        title={() => <span><strong>Ranking Grammars {`[${grammars.length}]`}</strong></span>}
                        columns={columns}
                        dataSource={grammars}
                    />
                </Col>
                <Col span={12}>
                    <Table
                        bordered
                        rowKey="id"
                        size="small"
                        pagination={false}
                        title={() => <span><strong>Search Queries {`[${searchs.length}]`}</strong></span>}
                        columns={columns}
                        dataSource={searchs}
                    />
                </Col>
            </Row>
        </>
    );
};

const _getProject = (user: User | undefined | null) => {
    return userIsAdmin(user) ? getProjectAdmin : getProject;
};

const ProjectsAddEdit: React.FC = () => {
    const { id } = useParams<"id">();
    const { user } = useContext(AppContext);
    const { form, isLoading, hasError, configuration, initialValues, } = useDForm<Project>(
        id,
        getProjectFormConfiguration,
        _getProject(user)
    );
    const [project, setProject] = useState<Project | null>(null);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const active = project ? project.active : true;

    const crumbs = [
        { label: "Projects", path: "/projects" },
        { label: id ? "Edit" : "Add New Project" },
    ];

    useEffect(() => {
        if (initialValues && initialValues.id) {
            setProject(initialValues);
        }
    }, [initialValues]);


    const handleProjectSaved = (response: Project) => {
        message.success("Project saved!");
        queryClient.invalidateQueries("projects");
        if (response.id) {
            navigate(`/projects/${response.id}`);
        }
        setProject(response);
    };

    const handleSubmit = (isActive: boolean) => {
        let isSubcribed = true;
        form.validateFields()
            .then((values: any) => {
                const saver = userIsAdmin(user) ? postProjectAdmin : postProject;
                return saver({ ...values, active: isActive, id });
            })
            .then(
                (response: Project) => {
                    if (isSubcribed) {
                        handleProjectSaved(response);
                    }
                }
            )
            .catch(
                error => message.error(`Error: ${error.message}`)
            )
            .finally(
                () => isSubcribed = false
            );
    };

    const handleFinish = () => {
        handleSubmit(active);
    };

    const toggleActiveStatus = () => {
        handleSubmit(!active);
    };

    const actions = (
        <Button type={active ? "primary" : "default"} danger onClick={toggleActiveStatus} >
            {active ? "Archive Project" : "Reactivate Project"}
        </Button>
    );

    return (
        <PageWrapper crumbs={crumbs} className="project-add-edit">
            {hasError
                ? (
                    <>
                        <Result
                            status="error"
                            title="There are some problems with your operation."
                            extra={
                                <Button type="primary" key="console" onClick={() => navigate("/projects")}>
                                    Go Back
                                </Button>
                            }
                        />
                    </>
                )
                : (
                    <Tabs defaultActiveKey="general">
                        <Tabs.TabPane tab={<span><HomeOutlined />General</span>} key="general">
                            <DForm
                                isLoading={isLoading}
                                disabled={hasError}
                                initialValues={initialValues}
                                form={form}
                                id="project_form"
                                onFinish={handleFinish}
                                actions={actions}
                                configuration={configuration}
                            />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={<span><FileAddOutlined />Params</span>} key="params">
                            <ParamSelector projectId={id} />
                        </Tabs.TabPane>
                    </Tabs>
                )
            }
        </PageWrapper>
    );
};

export default ProjectsAddEdit;
