import React, { useState, useEffect, useRef, ReactNode, HTMLAttributes, PropsWithChildren } from 'react';

import { Link } from 'react-router-dom';

import FileSaver from 'file-saver';

import { Patent, PatentDocument } from '../constants/types';

import { getAuthority, authorityStyle } from '../utils';

import { fetchDocdbFamily, fetchInpadocFamily, fetchPatentPdf, patentDisplayUri } from '../api';

import { Marker } from '../utils/highlight';

import Avatar from 'antd/es/avatar';
import BackTop from 'antd/es/back-top';
import Typography from 'antd/es/typography';
import Descriptions from 'antd/es/descriptions';
import Skeleton from 'antd/es/skeleton';
import Tag from 'antd/es/tag';
import message from 'antd/es/message';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Table from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table/interface';

import GoogleOutlined from '@ant-design/icons/GoogleOutlined';
import GlobalOutlined from '@ant-design/icons/GlobalOutlined';
import FilePdfTwoTone from '@ant-design/icons/FilePdfTwoTone';
import LinkOutlined from '@ant-design/icons/LinkOutlined';

import PatentImageGallery from './PatentImageGallery';
import HighlightedText from './HighlightedText';
import { EmptyResults, SearchItem } from './Search';

const trim = (txt: string): string => txt.replace(/\s+/g, ' ').trim();

const LINK_ICONS: { [key: string]: ReactNode } = {
    // epo: null,
    fpo: <GlobalOutlined />,
    google: <GoogleOutlined />,
};

type PatentLayout = 'one-column' | 'two-columns';

const patentFamilyColumns: ColumnsType<PatentDocument> = [
    {
        title: 'Id',
        dataIndex: 'id',
        width: 130,
        render: (_, patent: PatentDocument) => (
            <span className="tool">
                <Link to={patentDisplayUri(patent.id)} target="_blank">
                    {patent.id}
                </Link>
            </span>
        ),
    },
    {
        title: 'Filing Date',
        width: 110,
        dataIndex: 'filing_date',
    },
    {
        title: 'Publish Date',
        width: 110,
        dataIndex: 'publish_date',
    },
    {
        title: 'Title',
        dataIndex: 'title',
        render: (_, patent: PatentDocument) => <span style={{}}>{patent.title}</span>,
    },
];

interface ExternalLinkProps {
    name: string;
    link: string;
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ name, link }) => (
    <span className="external-link">
        <a href={link} target="_blank" rel="noreferrer">
            {name in LINK_ICONS ? LINK_ICONS[name] : name}
        </a>
    </span>
);

interface PatentIdViewProps {
    patent: PatentDocument;
    showLink?: boolean;
}

const PatentIdView: React.FC<PatentIdViewProps> = ({ patent, showLink = true }) => (
    <>
        <span>{patent.id}</span>
        {showLink && (
            <>
                <span className="tool">
                    <Link to={patentDisplayUri(patent.id)} target="_blank">
                        <LinkOutlined />
                    </Link>
                </span>
            </>
        )}
    </>
);

interface PatentHeaderProps {
    patent: PatentDocument;
    markers: Marker[];
    showLink?: boolean;
    showInfo?: boolean;
}

const PatentHeader: React.FC<PatentHeaderProps> = ({ patent, showLink = true, showInfo = true }) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const downloadPatentPdf = async (patentId: string) => {
        if (isDownloading) {
            return;
        }

        try {
            setIsDownloading(true);
            const data = await fetchPatentPdf(patentId);
            const blob = new Blob([data], { type: 'application/pdf' });
            FileSaver.saveAs(blob, `${patentId}.pdf`);
        } catch (error) {
            message.error(`Unable to download PDF: ${error}`);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="header">
            <span className="title">
                <PatentIdView patent={patent} showLink={showLink} />
            </span>
            {showInfo && (
                <span className="info">
                    <span className="tool">
                        <a onClick={() => downloadPatentPdf(patent.id)}>
                            <FilePdfTwoTone twoToneColor={isDownloading ? '#b0b0b0' : '#e06040'} />
                        </a>
                    </span>
                    {patent.links &&
                        Object.entries(patent.links).map(([name, link]) => (
                            <ExternalLink key={name} name={name} link={link} />
                        ))}
                </span>
            )}
        </div>
    );
};

interface PatentSectionProps {
    bordered?: boolean;
}

const PatentSection: React.FC<PropsWithChildren<PatentSectionProps> & HTMLAttributes<PatentSectionProps>> = ({
    bordered = false,
    className = '',
    children,
}) => {
    const clsName = bordered ? `section bordered ${className}` : `section ${className}`;
    return children ? <div className={clsName}>{children}</div> : null;
};

const claimClassName = (part: string) =>
    ['claim', 'claims', 'Claim', 'Claims'].some((v) => part.includes(v)) ? 'claim dependent' : 'claim';

const PatentClaimsSection: React.FC<PatentTextSectionProps> = ({ title, text, markers = [] }) => {
    const parts: string[] = PatentDocument.getParts(text);

    return parts.length === 0 ? null : (
        <PatentSection>
            {title && (
                <Typography.Title level={5} className="title">
                    <HighlightedText text={title} markers={markers} />
                </Typography.Title>
            )}
            <ul className="claims-list">
                {parts.map((part, idx) => (
                    <li key={idx} className={claimClassName(part)}>
                        <Typography.Paragraph>
                            <HighlightedText text={trim(part)} markers={markers} />
                        </Typography.Paragraph>
                    </li>
                ))}
            </ul>
        </PatentSection>
    );
};

interface ItemListProps {
    items?: (string | ReactNode)[];
}

const ItemList: React.FC<ItemListProps> = ({ items }) => (
    <dl>{items ? items.map((item, index) => <dt key={index}>{item}</dt>) : '---'}</dl>
);

interface CitationsItemProps {
    citations?: string[];
    onClick?: (patentId: string) => void;
}

const CitationsItem: React.FC<CitationsItemProps> = ({ citations }) => {
    if (!citations) {
        return <div>---</div>;
    }

    return (
        <ItemList
            items={citations.map((citationId, i) => (
                <dl key={i}>
                    <Link to={patentDisplayUri(citationId)} target="_blank">
                        {citationId}
                    </Link>
                </dl>
            ))}
        />
    );
};

interface ClassesItemProps {
    name: string;
    patent: PatentDocument;
}

const ClassesItem: React.FC<ClassesItemProps> = ({ name, patent }) => {
    const items = PatentDocument.getClassItems(patent, name);
    return <ItemList items={items} />;
};

interface PatentMetaProps {
    patent: PatentDocument;
}

const PatentMeta: React.FC<PatentMetaProps> = ({ patent }) => {
    return (
        <PatentSection bordered={true}>
            <Descriptions size="small" layout="vertical" column={3}>
                <Descriptions.Item label="Docdb Family">{patent.docdb_family_id}</Descriptions.Item>
                <Descriptions.Item label="Assignee">
                    <ItemList items={patent.assignee} />
                </Descriptions.Item>
                <Descriptions.Item label="Inventors">
                    <ItemList items={patent.inventor} />
                </Descriptions.Item>
            </Descriptions>

            <Descriptions size="small" layout="vertical" column={3}>
                <Descriptions.Item label="Filing Date">{patent.filing_date}</Descriptions.Item>

                <Descriptions.Item label="Priority Date">{patent.priority_date}</Descriptions.Item>

                <Descriptions.Item label="Publish Date">{patent.publish_date}</Descriptions.Item>
            </Descriptions>

            <Descriptions size="small" layout="vertical" column={3}>
                <Descriptions.Item label="Cpc Classes">
                    <ClassesItem name="cpc" patent={patent} />
                </Descriptions.Item>

                <Descriptions.Item label="Ipc Classes">
                    <ClassesItem name="ipc" patent={patent} />
                </Descriptions.Item>

                <Descriptions.Item label="Citations">
                    <CitationsItem citations={patent.backward_citations} />
                </Descriptions.Item>
            </Descriptions>
        </PatentSection>
    );
};

interface PatentTextSectionProps {
    title?: string;
    text?: string | string[];
    markers: Marker[];
}

const PatentTextSection: React.FC<PatentTextSectionProps> = ({ title, text, markers = [] }) => {
    const parts: string[] = PatentDocument.getParts(text);

    return parts.length === 0 ? null : (
        <PatentSection>
            {title && (
                <Typography.Title level={5} className="title">
                    <HighlightedText text={title} markers={markers} />
                </Typography.Title>
            )}
            <>
                {parts.map((part, idx) => (
                    <Typography.Paragraph key={idx}>
                        <HighlightedText text={trim(part)} markers={markers} />
                    </Typography.Paragraph>
                ))}
            </>
        </PatentSection>
    );
};

interface PatentFamilyListProps {
    patentId: string;
    title: string;
    fetcher: (patentId: string) => Promise<PatentDocument[]>;
}

const PatentFamilyList: React.FC<PatentFamilyListProps> = ({ patentId, title, fetcher }) => {
    const [family, setFamily] = useState<PatentDocument[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        fetcher(patentId)
            .then((f) => isSubscribed && setFamily(f.filter((p) => p.id !== patentId)))
            .finally(() => isSubscribed && setLoading(false));

        return () => {
            isSubscribed = false;
        };
    }, [patentId, fetcher]);

    return !loading && family.length === 0 ? null : (
        <PatentSection className="family-list">
            <Table
                className="family-list-table"
                size="small"
                rowKey="id"
                loading={loading}
                title={() => <strong>{title}</strong>}
                dataSource={family}
                columns={patentFamilyColumns}
                pagination={false}
            />
        </PatentSection>
    );
};

interface PatentMatterProps {
    patent: PatentDocument;
    markers?: Marker[];
    galleries: ReactNode | null;
    layout?: 'one-column' | 'two-columns';
}

const PatentMatter: React.FC<PatentMatterProps> = ({ patent, markers = [], galleries, layout = 'one-column' }) => {
    return layout === 'one-column' ? (
        <>
            <PatentSection className="title">
                <Typography.Title level={4}>
                    <HighlightedText text={patent.title} markers={markers} />
                </Typography.Title>
            </PatentSection>

            <PatentTextSection title="Abstract" text={patent.abstract} markers={markers} />

            {galleries}

            {patent.claims && (
                <PatentClaimsSection
                    title={`Claims (${patent.claims.length})`}
                    text={patent.claims}
                    markers={markers}
                />
            )}

            <PatentTextSection title="Description" text={patent.description} markers={markers} />
        </>
    ) : (
        <>
            <PatentSection className="title">
                <Typography.Title level={4}>
                    <HighlightedText text={patent.title} markers={markers} />
                </Typography.Title>
            </PatentSection>

            <PatentTextSection title="Abstract" text={patent.abstract} markers={markers} />

            <Row gutter={32}>
                <Col span={12}>
                    {patent.claims && (
                        <PatentClaimsSection
                            title={`Claims (${patent.claims.length})`}
                            text={patent.claims}
                            markers={markers}
                        />
                    )}
                </Col>
                <Col span={12}>
                    <PatentTextSection title="Description" text={patent.description} markers={markers} />
                </Col>
            </Row>
        </>
    );
};

interface PatentViewerProps {
    loading: boolean;
    patent: PatentDocument | null;
    markers?: Marker[];
    layout?: PatentLayout;
    onCitationClick?: (patentId: string) => void;
    showLink?: boolean;
}

interface PatentContentProps {
    header: ReactNode;
    meta: ReactNode;
    matter: ReactNode;
    galleries: ReactNode;
    family: ReactNode;
}

interface PatentContentLayoutProps extends PatentContentProps {
    layout: PatentLayout;
}

const PatentContentOneColumn: React.FC<PatentContentProps> = ({ header, meta, matter, family }) => {
    const contentRef = useRef(null);
    return (
        <>
            {header}
            <div className="patent-content" ref={contentRef}>
                {meta}
                {matter}
                {family}
                <br />
            </div>
            {contentRef && <BackTop target={() => contentRef.current!} />}
        </>
    );
};

const PatentContentTwoColumn: React.FC<PatentContentProps> = ({ header, meta, matter, galleries, family }) => {
    return (
        <>
            <div className="patent-content">
                <Row gutter={16}>
                    <Col span={14} className="left-col">
                        {matter}
                    </Col>
                    <Col span={10} className="right-col">
                        <div className="content-wrapper">
                            <div className="patent-content">
                                {header}
                                {meta}
                            </div>
                            {galleries}
                            {family}
                        </div>
                    </Col>
                </Row>
            </div>
            {<BackTop />}
        </>
    );
};

const PatentContent: React.FC<PatentContentLayoutProps> = (props) =>
    props.layout === 'one-column' ? <PatentContentOneColumn {...props} /> : <PatentContentTwoColumn {...props} />;

const PatentViewer: React.FC<PatentViewerProps> = ({
    loading,
    patent,
    markers = [],
    showLink = true,
    layout = 'one-column',
}) => {
    if (!patent) {
        return null;
    }

    if (loading) {
        return (
            <div className="patent-viewer">
                <Skeleton paragraph={{ rows: 6 }} active loading={loading} />
            </div>
        );
    }

    const header = <PatentHeader patent={patent} markers={markers} showLink={showLink} />;

    const meta = <PatentMeta patent={patent} />;

    const galleries = (
        <>
            <PatentSection className="gallery">
                <PatentImageGallery provider={'epo'} id={patent.id} />
            </PatentSection>

            <PatentSection className="gallery">
                <PatentImageGallery provider={'google'} id={patent.id} />
            </PatentSection>
        </>
    );

    const family = (
        <>
            <PatentFamilyList patentId={patent.id} title="Docdb Family" fetcher={fetchDocdbFamily} />
            <PatentFamilyList patentId={patent.id} title="Inpadoc Family" fetcher={fetchInpadocFamily} />
        </>
    );

    const matter = <PatentMatter patent={patent} markers={markers} galleries={galleries} layout={layout} />;

    return (
        <>
            <div className={`patent-viewer ${layout}`}>
                <div className="patent-content-wrapper">
                    <PatentContent
                        header={header}
                        meta={meta}
                        matter={matter}
                        galleries={galleries}
                        family={family}
                        layout={layout}
                    />
                </div>
            </div>
        </>
    );
};

interface PatentAvatarProps {
    id: string;
}

const PatentAvatar: React.FC<PatentAvatarProps> = ({ id }) => {
    const authority = getAuthority(id);
    const style = authorityStyle(authority);
    return (
        <Avatar size="small" style={style}>
            {authority}
        </Avatar>
    );
};

interface PatentItemProps {
    patent: Patent;
    markers: Marker[];
    onClick?: (patentId: string) => void;
}

const PatentItem: React.FC<PatentItemProps> = ({ patent, markers, onClick }) => {
    return (
        <SearchItem
            avatar={<PatentAvatar id={patent.id} />}
            title={<HighlightedText text={patent.title} markers={markers} />}
            subtitle={patent.id}
            onClick={() => onClick && onClick(patent.id)}
            summary={<HighlightedText text={patent.abstract} markers={markers} />}
        >
            <div className="assigneelist">
                {patent.assignee.map((as, idx) => (
                    <Tag key={idx}>{as}</Tag>
                ))}
            </div>
        </SearchItem>
    );
};

interface PatentListProps {
    query: string | null | undefined;
    loading: boolean;
    patents: Patent[];
    markers?: Marker[];
    onSelect?: (patentId: string) => void;
}

const PatentList: React.FC<PatentListProps> = ({ query, loading, patents, markers = [], onSelect = () => {} }) => {
    if (!query || query === '') {
        return null;
    }

    return !loading && patents.length === 0 ? (
        <>
            <EmptyResults />
        </>
    ) : (
        <>
            {patents.map((patent, idx) => (
                <PatentItem onClick={onSelect} key={idx} patent={patent} markers={markers || []} />
            ))}
        </>
    );
};

export { PatentViewer, PatentAvatar, PatentItem, PatentList };
