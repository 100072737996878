import React, { useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";

import Button from "antd/es/button";
import Form from "antd/es/form";
import message from "antd/es/message";
import Result from "antd/es/result";
import Space from "antd/es/space";

import {
    Task
} from "../../constants/types";

import { PageWrapper } from "../../components/PageWrapper";

import { getTask } from "../../api";

import {
    TaskEditor,
    useTaskForm
} from "../../components/tasks";

import { FormLayout } from "../../components/Form";

const TaskPlaceholder: React.FC = () => {
    const navigate = useNavigate();

    const onClick = () => navigate('/tasks');

    const extra = (
        <Button type="primary" key="console" onClick={onClick}>
            Go To Task List
        </Button>
    );

    return (
        <Result
            status="warning"
            title="This task is active and cannot be edited..."
            extra={extra}
        />
    );
};

const TasksAddEdit: React.FC = ({ }) => {
    const { id } = useParams<"id">();
    const [task, setTask] = useState<Task | undefined>();
    const navigate = useNavigate();
    const [taskForm] = useTaskForm();

    useEffect(() => {
        if (id) {
            getTask(id).then((t: Task) => {
                setTask(t);
            });
        }
    }, [id]);

    useEffect(() => {
        taskForm.setOnTaskSaved((t: Task) => {
            message.success("Task saved!");
            if (t.id) {
                navigate(`/tasks/${t.id}`);
            }
        });
    }, [taskForm, navigate]);

    const crumbs = [{ label: "Tasks", path: "/tasks" }, { label: id ? "Edit" : "Add" }];

    const onSave = () => {
        taskForm.save();
    };

    const onSaveAndStart = () => {
        taskForm
            .saveAndRun()
            .then(() => navigate('/tasks'));
    };

    return !Task.isEditable(task)
        ? (
            <PageWrapper crumbs={crumbs} >
                <TaskPlaceholder />
            </PageWrapper>
        )
        : (
            <PageWrapper crumbs={crumbs} className="tasks-add-edit">
                <TaskEditor
                    id={id}
                    taskForm={taskForm}
                    initialData={task}
                    controls={
                        <Form.Item {...FormLayout.noLabelItem}>
                            <Space>
                                <Button
                                    type="primary"
                                    onClick={onSave}>
                                    Save
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    onClick={onSaveAndStart}>
                                    Save and start
                                </Button>
                            </Space>
                        </Form.Item>
                    }
                />
            </PageWrapper >
        );
};

export default TasksAddEdit;
