import React, { PropsWithChildren } from "react";

import { ParamMetadata, TaskType, UserProject } from "../../constants/types";
import { ParameterComponents } from "../parameters";

import Alert from "antd/es/alert";
import DatePicker from "antd/es/date-picker";
import Form from "antd/es/form";
import Input from "antd/es/input";

interface LabelItemProps {
    taskType?: TaskType;
    project?: UserProject;
    hiddenFields?: string[];
}

interface ConcealerItemProps {
    name: string;
    hiddenFields?: string[];
}

const ConcealerItem: React.FC<PropsWithChildren<ConcealerItemProps>> = ({ name, hiddenFields, children }) => {
    const style = (hiddenFields && hiddenFields.includes(name)) ? { display: "none" } : {};
    return (
        <div style={style}>
            {children}
        </div>
    );
};

const LabelItem: React.FC<LabelItemProps> = ({ taskType, project, hiddenFields = [] }) => {
    if (!taskType) {
        return null;
    }
    const labelPlaceholder = project ? `${project.label}-${taskType.name}-##` : `${taskType.name}-##`;
    return (
        <ConcealerItem name="label" hiddenFields={hiddenFields}>
            <Form.Item
                name="label"
                label="Label"
                rules={[{ required: false, message: "" }]}
            >
                <Input placeholder={labelPlaceholder} />
            </Form.Item>
        </ConcealerItem>
    );
};

interface DateItemProps {
    name: string;
    label: string;
}

const DateItem: React.FC<DateItemProps> = ({ name, label }) => {
    return (
        <div>
            <Form.Item
                name={name}
                label={label}
                rules={[{ required: false, message: "" }]}>
                <DatePicker
                    // style={{ width: "300px", maxWidth: "300px" }}
                    allowClear={true}
                    showTime
                    format={"DD-MM-YYYY HH:mm"}
                />
            </Form.Item>
        </div>
    );
};

export interface ParamItemProps {
    name: string;
    input: ParamMetadata;
    hiddenFields: string[];
}

const capitalize = (name: string) => (name[0].toUpperCase() + name.substring(1));
const labelFromName = (name: string) => name.split("_").map(part => capitalize(part)).join(" ");

const ParamItem: React.FC<ParamItemProps> = ({ name, input, hiddenFields }) => {
    const paramComponentDef = ParameterComponents.tasks[input.type];
    const label = labelFromName(name);

    const inputEl = !input
        ? <Alert
            className="param-type-not-implemented"
            message={`${name} not implemented`}
            type="error"
            showIcon
        />
        : React.createElement(paramComponentDef.component, {
            initialValue: input.defaultValue,
            values: input.values,
            ...paramComponentDef.props
        });

    return (
        <ConcealerItem name={name} hiddenFields={hiddenFields}>
            <Form.Item
                name={name}
                label={label}
                rules={[{ required: input.required }]}>
                {inputEl}
            </Form.Item>
        </ConcealerItem>
    );
};

const TaskFormItem = {
    Concealer: ConcealerItem,
    Label: LabelItem,
    Param: ParamItem,
    Date: DateItem,
    labelFromName
};

export { TaskFormItem as default };
