import React, { useState, useRef, ReactNode, PropsWithChildren, useEffect } from 'react';

import { Button, Row, Col, Input, Typography, Pagination } from 'antd';

import SearchOutlined from '@ant-design/icons/SearchOutlined';

import { SearchResult } from '../hooks/useSearch';

interface SearchAreaProps {
    value: string;
    setValue: (value: string) => void;
}

const SearchArea: React.FC<SearchAreaProps> = ({ value, setValue }) => {
    return <Input.TextArea value={value} onChange={(e) => setValue(e.target.value)} />;
};

interface SearchBarProps {
    defaultValue?: string;
    placeholder?: string;
    disabled: boolean;
    onSearch: (value: string) => void;
}

const SearchBar: React.FC<PropsWithChildren<SearchBarProps>> = ({
    defaultValue,
    placeholder,
    disabled,
    onSearch,
    children,
}) => {
    const [value, setValue] = useState<string>(defaultValue || '');
    const [rows, setRows] = useState<number>(1);
    const textAreaRef = useRef<any>(null);

    useEffect(() => {
        setValue(defaultValue || '');
    }, [defaultValue]);

    const textAreaClass = rows > 1 ? 'expanded' : 'collapsed';

    const blur = () => {
        if (textAreaRef.current) {
            textAreaRef.current.blur();
            textAreaRef.current.resizableTextArea.textArea.scrollTop = 0;
            return true;
        }
        return false;
    };

    return (
        <Row className="search-bar" gutter={16}>
            <Col flex="auto">
                <Input.Group compact className="input-group">
                    <span className="ant-input-group-addon" style={{ padding: '4px 0px', width: '42px' }}>
                        <SearchOutlined />
                    </span>
                    <Input.TextArea
                        disabled={disabled}
                        ref={textAreaRef}
                        className={textAreaClass}
                        allowClear
                        onFocus={() => setRows(10)}
                        onBlur={() => setRows(1)}
                        onKeyUp={(e) => e.code === 'Escape' && blur()}
                        rows={rows}
                        value={value}
                        onChange={(e) => {
                            setValue(e.target.value.replace(/(\r\n|\n|\r)+(?!$)/gm, ' '));
                        }}
                        onPressEnter={() => {
                            blur();
                            onSearch(value);
                        }}
                        placeholder={placeholder}
                    />
                    <Button disabled={disabled} type="primary" onClick={() => onSearch(value)}>
                        Search
                    </Button>
                </Input.Group>
            </Col>
            <Col>{children}</Col>
        </Row>
    );
};

interface CounterProps {
    count: number;
}

const Counter: React.VFC<CounterProps> = ({ count }) => {
    return (
        <>
            Found <strong>{count}</strong> results
        </>
    );
};

interface SearchCounterProps {
    loading: boolean;
    show: boolean;
    count: number;
}

const SearchCounter: React.FC<SearchCounterProps> = ({ loading, show, count }) => {
    if (!show) {
        return null;
    }
    const message = loading ? <>Loading...</> : <Counter count={count} />;
    return (
        <div className="counter">
            <span className="item">{message}</span>
        </div>
    );
};

interface SearchItemProps {
    avatar?: React.ReactNode;
    title: React.ReactNode;
    subtitle?: string;
    summary?: React.ReactNode;
    onClick?: () => void;
}

const SearchItem: React.FC<PropsWithChildren<SearchItemProps>> = ({
    avatar,
    title,
    subtitle,
    summary,
    onClick,
    children,
}) => {
    return (
        <div className="search-item">
            <div className="title" onClick={(e) => onClick && onClick()}>
                {avatar}
                <span className="text">{title}</span>
            </div>
            {subtitle && (
                <div className="subtitle">
                    <Typography.Text type="secondary" copyable={{ text: subtitle }}>
                        {subtitle}
                    </Typography.Text>
                </div>
            )}
            {summary && (
                <div className="summary">
                    <Typography.Paragraph ellipsis={{ rows: 3, expandable: false }}>{summary}</Typography.Paragraph>
                </div>
            )}
            {children}
        </div>
    );
};

interface SearchPaginationProps<T, F> {
    results: SearchResult<T, F>;
    onChange: (page: number, page_size?: number) => void;
}

const SearchPagination: React.FC<SearchPaginationProps<any, any>> = ({ results, onChange }) => {
    return results.num_found > 0 ? (
        <Pagination
            size="small"
            current={results.page}
            total={results.num_found}
            pageSize={results.page_size}
            onChange={onChange}
        />
    ) : null;
};

interface EmptyResultsProps {
    message?: string | ReactNode;
}

const EmptyResults: React.FC<EmptyResultsProps> = ({ message }) => {
    return (
        <div className="empty-results">
            <div className="message">
                {message ? (
                    message
                ) : (
                    <>
                        <h2>Oops!</h2>
                        No results found
                    </>
                )}
            </div>
        </div>
    );
};

interface ErrorMessageProps {
    error: boolean;
    loading?: boolean;
    message: string;
}

const ErrorMessage: React.FC<PropsWithChildren<ErrorMessageProps>> = ({ error, loading, message, children }) => {
    return <>{children}</>;

    console.log('ErrorMessage', error, loading);

    if (!error || loading) {
        return <>{children}</>;
    }

    return (
        <div className="error-results">
            <div className="message">
                <h2>Oops!</h2>
                {message}
            </div>
        </div>
    );
};

export { SearchCounter, SearchBar, EmptyResults, ErrorMessage, SearchItem, SearchPagination };
