import React, { PropsWithChildren, useContext } from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter, HashRouter, Route, Routes, Navigate } from 'react-router-dom';

import Layout from './components/Layout';

import axios from 'axios';
import './style/index.less';

import Spin from 'antd/es/spin';

import { useAppContext } from './hooks/useAppContext';

import { AppContext } from './contexts';
import { QueryClient, QueryClientProvider } from 'react-query';

import { SearchRoutes } from './view/search';
import { Projects, ProjectsAddEdit } from './view/projects';
import { Parameters, ParametersAdd, ParametersEdit } from './view/parameters';
import { Tasks, TasksAddEdit } from './view/tasks';
import { System } from './view/system';
import ServiceRoute from './components/ServiceRoute';

import { loader } from '@monaco-editor/react';

loader.config({
    paths: {
        vs: '/vs',
    },
});

loader.init().then((monaco) => {
    monaco.editor.defineTheme('r2', {
        base: 'vs',
        inherit: true,
        rules: [],
        colors: {
            'editor.selectionBackground': '#88ff9090',
            'minimap.selectionHighlight': '#88ff9088',
        },
    });
});

const DEVMODE = import.meta.env.MODE === 'development';

const Router = ({ children }: any) => {
    if (DEVMODE) {
        return <HashRouter>{children}</HashRouter>;
    } else {
        return <BrowserRouter>{children}</BrowserRouter>;
    }
};

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        console.warn(error);
        const { status } = error.response;
        if (status === 401) {
            console.warn('401');
        }
        return Promise.reject(error);
    }
);

const queryClient = new QueryClient();

const App: React.FC = ({}) => {
    const app = useAppContext();

    if (!app.initialized) {
        return (
            <div className="spin-wrapper">
                <Spin spinning={true} size="large" />
            </div>
        );
    }

    const defaultRoute = '/tasks';

    return (
        <AppContext.Provider value={{ ...app }}>
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/projects/:id" element={<ProjectsAddEdit />} />
                        <Route path="/projects/new" element={<ProjectsAddEdit />} />
                        <Route path="/projects" element={<Projects />} />

                        <Route path="/parameters/:id" element={<ParametersEdit />} />
                        <Route path="/parameters/new" element={<ParametersAdd />} />
                        <Route path="/parameters" element={<Parameters />} />

                        <Route path="/tasks/:id" element={<TasksAddEdit />} />
                        <Route path="/tasks/new" element={<TasksAddEdit />} />
                        <Route path="/tasks" element={<Tasks />} />
                        <Route path="/system" element={<System />} />

                        <Route path="/*" element={<Navigate to={defaultRoute} />} />
                    </Routes>
                </Layout>
            </Router>
        </AppContext.Provider>
    );
};

App.displayName = 'App';

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <App />
    </QueryClientProvider>,
    document.getElementById('app')
);
