import React, { useEffect, PropsWithChildren, HTMLAttributes, useRef, useState } from 'react';

type R<T> = (((instance: T | null) => void) | React.MutableRefObject<T | null> | null);

function useCombinedRefs(...refs: R<any>[]) {
    const targetRef = React.useRef(null);

    useEffect(() => {
        refs.forEach(ref => {
            if (!ref) return;
            if (typeof ref === 'function') {
                ref(targetRef.current);
            } else {
                ref.current = targetRef.current;
            }
        });
    }, [refs]);

    return targetRef;
}

interface ScrollShadowProps extends HTMLAttributes<HTMLElement> {
}

const HorizontalScrollShadow: React.FC<ScrollShadowProps> = ({
    className = '',
    style,
    children
}) => {
    const [showStart, setShowStart] = React.useState(false);
    const [showEnd, setShowEnd] = React.useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const node = contentRef.current;
        const onScroll = () => {
            const { scrollWidth, scrollLeft, offsetWidth } = contentRef.current || { scrollWidth: 0, scrollLeft: 0, offsetWidth: 0 };
            setShowStart(scrollLeft > 0);
            setShowEnd(scrollLeft + offsetWidth < scrollWidth);
        };

        setTimeout(() => {
            const { scrollWidth, scrollLeft, offsetWidth } = contentRef.current || { scrollWidth: 0, scrollLeft: 0, offsetWidth: 0 };
            onScroll();
        }, 100);
        if (node) {
            node.addEventListener('scroll', onScroll);
        }

        return () => {
            if (node) {
                node.removeEventListener('scroll', onScroll);
            }
        };
    }, []);

    const clsName = `horizontal-scroll-shadow ${className}${(showStart ? ' start' : '')}${(showEnd ? ' end' : '')}`;

    return (
        <div className='vertical-scroll-shadow-wrapper' style={{ overflow: 'auto hidden' }}>
            <div className={`${clsName}`}>
                <div className='horizontal-scroll-shadow-content' ref={contentRef} style={style}>
                    {children}
                </div>
            </div>
        </div>
    );
};

const VerticalScrollShadow: React.FC<PropsWithChildren<ScrollShadowProps>> = ({
    className = '',
    style,
    children
}) => {
    const [showStart, setShowStart] = useState(false);
    const [showEnd, setShowEnd] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const node = contentRef.current;
        const onScroll = () => {
            const { scrollHeight, scrollTop, offsetHeight } = contentRef.current || { scrollHeight: 0, scrollTop: 0, offsetHeight: 0 };
            setShowStart(scrollTop > 0);
            setShowEnd(scrollTop + offsetHeight < scrollHeight);
        };

        if (node) {
            node.addEventListener('scroll', onScroll);
        }
        onScroll();

        return () => {
            if (node) {
                node.removeEventListener('scroll', onScroll);
            }
        };
    }, []);

    const clsName = `vertical-scroll-shadow ${className}${(showStart ? ' start' : '')}${(showEnd ? ' end' : '')}`;

    return (
        <div className='vertical-scroll-shadow-wrapper' style={{ overflow: 'hidden auto' }}>
            <div className={`${clsName}`}>
                <div className='vertical-scroll-shadow-content' ref={contentRef} style={style}>
                    {children}
                </div>
            </div>
        </div>
    );
};


export { HorizontalScrollShadow, VerticalScrollShadow };
