import React, { HTMLAttributes } from "react";

interface ToolbarProps extends HTMLAttributes<HTMLDivElement> {
    align?: "left" | "center" | "right"
}

const Toolbar: React.FC<ToolbarProps> = ({
    align = "center",
    className,
    children,
    ...props
}) => {
    const clsName = `patsearch-toolbar ${align}` + (className ? ` ${className}` : "");
    return (
        <div className={clsName} {...props}>
            {children}
        </div>
    );
};

export default Toolbar;
