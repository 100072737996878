import React from 'react';

import Input from 'antd/es/input';
import Select from 'antd/es/select';

interface TextProps {
    initialValue?: any;
    value: any;
    values: string[];
    onChange: (data: any) => void;
}

const Text: React.FC<TextProps> = ({ initialValue, value, values, onChange }) => {
    const _onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = event.target.value
            ? { text: event.target.value }
            : null;
        onChange({ value: v });
    };

    const _onSelectChange = (v: string) => {
        onChange({ value: { text: v } });
    };

    const inputValue = value && value.value && value.value.text;
    const hasValues = values && (values.length > 0);

    return hasValues
        ? (
            <Select
                defaultValue={initialValue}
                value={inputValue}
                onChange={_onSelectChange}>
                {
                    values.map((v, index) => <Select.Option value={v} key={index}>{v}</Select.Option>)
                }
            </Select>
        ) : (
            <Input
                value={inputValue}
                defaultValue={initialValue}
                onChange={_onInputChange}
            />
        );
};

export default Text;
