import React from 'react';

import Popconfirm from 'antd/es/popconfirm';
import Button, { ButtonType } from 'antd/es/button';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';

import { SizeType } from 'antd/lib/config-provider/SizeContext';


interface DeleteButtonProps {
    onConfirm?: (e?: React.MouseEvent<HTMLElement>) => void;
    icon?: React.ReactNode;
    size?: SizeType;
    type?: ButtonType;
    danger?: boolean;
    disabled?: boolean;
}

const DeleteButton: React.PropsWithChildren<React.FC<DeleteButtonProps>> = ({ onConfirm, icon = <DeleteOutlined />, children, size = 'small', type = 'default', danger = true, disabled = false }) => {
    return (
        <Popconfirm
            placement='topRight'
            title='Are you sure?'
            onConfirm={onConfirm}
            okText='Yes'
            cancelText='No'
        >
            <Button type={type} size={size} icon={icon} danger={danger} disabled={disabled}>
                {children}
            </Button>
        </Popconfirm>
    );
};

export default DeleteButton;
