import React, { useCallback, useContext, useEffect, useState } from "react";

import find from "lodash/find";
import dayjs from "dayjs";

import Form from "antd/es/form";
import Select from "antd/es/select";
import Collapse from "antd/es/collapse";
import message from "antd/es/message";

import WifiOutlined from "@ant-design/icons/WifiOutlined";

import { useForm } from "antd/lib/form/Form";

import { Pair, ParamMetadata, TaskFormData, TaskType } from "../../constants/types";

import { TaskFormInstance } from "./useTaskForm";
import { AppContext } from "../../contexts";
import { postTask, startTask } from "../../api";
import { FormLayout } from "../Form";
import TaskFormItem from "./TaskFormItem";
import { TaskFormRendererProps } from "./types";
import { TaskFormComponents } from ".";
import { layoutClassName } from "../../utils";

const advancedOptionTitle = () =>
    <div className="header">
        Advanced settings
    </div>;

const DefaultFormRenderer: React.FC<TaskFormRendererProps> = ({
    form,
    requiredInputs,
    otherInputs,
    advancedInputs,
    hiddenFields,
    layout
}) => (
    <>
        {
            requiredInputs.map(([name, input]) =>
                <TaskFormItem.Param
                    key={name}
                    name={name}
                    input={input}
                    hiddenFields={hiddenFields} />
            )
        }

        {
            otherInputs.map(([name, input]) =>
                <TaskFormItem.Param
                    key={name}
                    name={name}
                    input={input}
                    hiddenFields={hiddenFields} />
            )
        }

        {
            advancedInputs.length > 0 &&
            <div className="advanced-params">
                <Collapse
                    ghost
                    accordion={true}
                    className={layoutClassName(layout)}
                    style={{ marginBottom: "24px", }}
                >
                    <Collapse.Panel
                        key="1"
                        header={advancedOptionTitle()}>
                        {
                            advancedInputs.map(([name, input]) =>
                                <TaskFormItem.Param
                                    key={name}
                                    name={name}
                                    input={input}
                                    hiddenFields={hiddenFields}
                                />
                            )
                        }
                    </Collapse.Panel>
                </Collapse>
            </div>
        }
    </>
);

interface TaskEditorProps {
    taskForm: TaskFormInstance,
    id?: string;
    taskType?: TaskType;
    initialData?: TaskFormData | null;
    controls?: React.ReactNode;
    hiddenFields?: string[];
}

const TaskEditor: React.FC<TaskEditorProps> = ({
    taskForm,
    id,
    taskType: initialTaskType,
    initialData,
    controls,
    hiddenFields = []
}) => {
    const { activeProject, taskTypes } = useContext(AppContext);
    const [form] = useForm();
    const [taskType, setTaskType] = useState<TaskType | undefined>(initialTaskType);

    const handleFinish = useCallback(async (runImmediatly = false) => {
        if (!activeProject) {
            message.warn("Please select a project!");
            return false;
        }

        if (!taskType) {
            message.warn("Please select a task type!");
            return false;
        }

        try {
            const { type, label, scheduled, ...rest } = await form.validateFields();
            const formData = new FormData();
            const taskParams = find(taskTypes, (el) => el.id === taskType!.id);
            const newtaskParams: any = {};

            Reflect.ownKeys(taskParams!.input).forEach(
                (k) => {
                    if (rest[k] && (rest[k].id !== undefined || !!rest[k].value)) {
                        newtaskParams[k as string] = { ...rest[k] };
                    }
                }
            );

            const json = JSON.stringify({
                id,
                label,
                scheduled: scheduled ? scheduled.format('YYYY-MM-DDTHH:mm:ss') : null,
                input: newtaskParams,
                project: { id: activeProject.value },
                taskType: {
                    id: taskType.id,
                    name: taskType.name,
                    type: taskType.type,
                    version: taskType.version
                },
            });

            const blob = new Blob([json], { type: "application/json" });
            formData.append("task", blob);

            const newTask = await postTask(formData);

            const input = newTask.input;
            const formInput: any = {};
            Reflect.ownKeys(input).forEach(
                (k) => {
                    const v = input[k as string];
                    formInput[k as string] = v;
                }
            );

            form.setFieldsValue({
                label: newTask.label,
                ...formInput,
            });

            if (runImmediatly) {
                await startTask(newTask.id);
            }

            if (taskForm.onTaskSaved) {
                taskForm.onTaskSaved(newTask);
            }

            return true;
        } catch (error) {
            message.error("Something went wrong, try again!");
            console.error(error);
        }

        return false;
    }, [id, form, activeProject, taskType, taskTypes, taskForm]);

    useEffect(() => {
        taskForm.setSave(() => handleFinish());
        taskForm.setSaveAndRun(() => handleFinish(true));
    }, [taskForm, form, taskType, taskTypes, activeProject, handleFinish]);

    useEffect(() => {
        const formInput: any = {};
        if (initialData) {
            if (initialData.input) {
                Reflect.ownKeys(initialData.input).forEach(
                    (k) => {
                        const v = initialData.input![k as string];
                        formInput[k as string] = v;
                    }
                );
            }
            setTaskType(initialData.taskType);
            form.setFieldsValue({
                type: initialData.taskType.id,
                label: initialData.label || "",
                scheduled: initialData.scheduled ? dayjs(initialData.scheduled) : null,
                ...formInput
            });
        }
    }, [initialData, form]);

    const handleTaskTypeChange = (taskTypeId: number) => {
        if (taskType) {
            form.resetFields(Reflect.ownKeys(taskType!.input) as any[]);
        }
        const tt = find(taskTypes, (t) => t.id === taskTypeId);
        setTaskType(tt);
    };

    const requiredInputs: Pair<string, ParamMetadata>[] = [];
    const otherInputs: Pair<string, ParamMetadata>[] = [];
    const advancedInputs: Pair<string, ParamMetadata>[] = [];

    if (taskType) {
        Object.entries(taskType.input).forEach(([key, value]) => {
            if (value.advanced) {
                advancedInputs.push([key, value]);
            } else if (value.required) {
                requiredInputs.push([key, value]);
            } else {
                otherInputs.push([key, value]);
            }
        });
    }

    const [FormRenderer, formRendererProps] = taskType && taskType.type in TaskFormComponents
        ? [TaskFormComponents[taskType.type].component, TaskFormComponents[taskType.type].props]
        : [DefaultFormRenderer, {}];

    return (
        <Form
            labelCol={FormLayout.labelCol}
            wrapperCol={FormLayout.wrapperCol}
            form={form}
            className="task-form">
            {
                taskTypes.length > 0 && (
                    <>
                        <TaskFormItem.Concealer name="type" hiddenFields={hiddenFields}>
                            <Form.Item
                                name="type"
                                label="Task Type"
                                rules={[{ required: true, message: "" }]}>
                                <Select
                                    onChange={handleTaskTypeChange}
                                    disabled={!!id}>
                                    {
                                        taskTypes &&
                                        taskTypes.map(
                                            ({ id: tid, name, type, version, available }, idx) => (
                                                <Select.Option value={tid} key={idx}>
                                                    {`${name} v${version}`}{" "}
                                                    <WifiOutlined style={{ color: available ? "green" : "red" }} />
                                                </Select.Option>
                                            ))
                                    }
                                </Select>
                            </Form.Item>
                        </TaskFormItem.Concealer>

                        <TaskFormItem.Label
                            taskType={taskType}
                            project={activeProject}
                            hiddenFields={hiddenFields}
                        />

                        <FormRenderer
                            form={form}
                            requiredInputs={requiredInputs}
                            otherInputs={otherInputs}
                            advancedInputs={advancedInputs}
                            hiddenFields={hiddenFields}
                            layout={FormLayout}
                            {...formRendererProps}
                        />

                        <TaskFormItem.Date name="scheduled" label="Start Date" />

                        {controls}
                    </>
                )
            }
        </Form>
    );
};

export default TaskEditor;
