import React, { useState } from 'react';
import { PatentDocument } from '../../../constants/types';

import Button from 'antd/es/button';
import Spin from 'antd/es/spin';
import Typography from 'antd/es/typography';

import PlusOutlined from '@ant-design/icons/PlusOutlined';
import MinusOutlined from '@ant-design/icons/MinusOutlined';

interface PatentSummaryProps {
    patent?: PatentDocument;
}

const PatentSummary: React.FC<PatentSummaryProps> = ({ patent }) => {
    const [showMore, setShowMore] = useState<boolean>(false);

    return !patent ? (
        <Spin />
    ) : (
        <div className="patent-summary">
            <div className="title">
                <span className="uppercase">{patent.title}</span>
                &nbsp;
                <Button
                    size="small"
                    icon={showMore ? <MinusOutlined /> : <PlusOutlined />}
                    onClick={() => setShowMore(!showMore)}
                />
            </div>
            {showMore ? (
                <>
                    <Typography.Text strong>Abstract</Typography.Text>
                    <Typography.Paragraph>
                        <span
                            className="abstract"
                            dangerouslySetInnerHTML={{ __html: patent.abstract }}
                        ></span>
                    </Typography.Paragraph>
                </>
            ) : null}
        </div>
    );
};

export default PatentSummary;
