import React, { useContext, useEffect, useRef, useState } from "react";

import { AppContext } from "../../contexts";

import {
    StateOfHealth,
    Token,
    TokenKind,
    UserRole
} from "../../constants/types";

import {
    getAccessTokens,
    createAccessToken,
    getSOH,
    deleteAccessToken
} from "../../api";

import Formatter from "../../components/Formatter";

import Button from "antd/es/button";
import Spin from "antd/es/spin";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Descriptions from "antd/es/descriptions";
import InputNumber from "antd/es/input-number";
import Table, { ColumnsType } from "antd/es/table";
import Space from "antd/es/space";
import message from "antd/es/message";
import Tabs from "antd/es/tabs";
import Typography from "antd/es/typography";

import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
import LaptopOutlined from "@ant-design/icons/LaptopOutlined";
import FireOutlined from "@ant-design/icons/FireOutlined";
import KeyOutlined from "@ant-design/icons/KeyOutlined";

import { PageWrapper } from "../../components/PageWrapper";
import DeleteButton from "../../components/DeleteButton";
import { UserLabel } from "../../components/UserIndicator";

class TokenForm {
    name: string;

    expiration: number | null;

    constructor() {
        this.name = "";
        this.expiration = null;
    }
}

const SohPane: React.FC = () => {
    const [stateOfHealth, setStateOfHealth] = useState<StateOfHealth>({ status: "", components: {} });

    useEffect(() => {
        let isSubscribed = true;

        getSOH()
            .then((data) => isSubscribed && setStateOfHealth(data));

        const intervalId = setInterval(() => {
            getSOH()
                .then((data) => {
                    if (isSubscribed) {
                        setStateOfHealth(data);
                    }
                });
        }, 5000);

        return () => {
            isSubscribed = false;
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div>
            <Formatter.JSon json={stateOfHealth} />
        </div>
    );
};

interface TokenViewProps {
    token: Token;
}

const TokenIcon: React.FC<TokenViewProps> = ({ token }) => {
    if (token.kind === TokenKind.System) {
        return (<LaptopOutlined />);
    }

    if (token.kind === TokenKind.Download) {
        return (<DownloadOutlined />);
    }

    if (token.kind === TokenKind.User) {
        return (<UserOutlined />);
    }

    return null;
};

const TokenLabel: React.FC<TokenViewProps> = ({ token }) => {
    return (
        <div className="token-label">
            <TokenIcon token={token} />
            <Typography.Text copyable>
                {token.id}
            </Typography.Text>
        </div>
    );
};

interface TokenDetailsProps {
    token?: Token | null;
}

const TokenDetails: React.FC<TokenDetailsProps> = ({ token }) => {
    return token
        ? (
            <Descriptions bordered column={2} size="small">
                <Descriptions.Item label="Name">
                    {token.name}
                </Descriptions.Item>
                <Descriptions.Item label="Token">
                    <TokenLabel token={token} />
                </Descriptions.Item>
                <Descriptions.Item label="Created">
                    {token.created}
                </Descriptions.Item>
                <Descriptions.Item label="Expiration">
                    {token.expiration}
                </Descriptions.Item>
            </Descriptions>
        )
        : null;
};

const TokenPane: React.FC = () => {
    const [token, setToken] = useState<Token | null>(null);
    const [tokens, setTokens] = useState<Token[]>([]);
    const [tokenLoading, setTokenLoading] = useState<boolean>(false);
    const [tokenForm, setTokenForm] = useState<TokenForm>(new TokenForm());
    const isSubscribed = useRef(true);

    useEffect(() => {
        getAccessTokens()
            .then((tks) => isSubscribed.current && setTokens(tks));

        const intervalId = setInterval(() => {
            getAccessTokens()
                .then((tks) => isSubscribed.current && setTokens(tks));
        }, 5000);

        return () => {
            isSubscribed.current = false;
            clearInterval(intervalId);
        };
    }, []);

    const deleteToken = (id: string) => {
        deleteAccessToken(id)
            .then(
                () => message.success(`Deleted token ${id}`)
            )
            .catch(
                (error) => message.error(`Error: ${error.message}`)
            );
    };

    const createNewToken = () => {
        setTokenLoading(true);

        createAccessToken(tokenForm.name, tokenForm.expiration)
            .then(
                (res) => isSubscribed.current && setToken(res)
            )
            .finally(
                () => isSubscribed.current && setTokenLoading(false)
            );
    };

    const columns: ColumnsType<Token> = [
        {
            title: "Token",
            dataIndex: "id",
            width: 400,
            render: (_: string, record: Token) => <TokenLabel token={record} />
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Created",
            dataIndex: "created",
            ellipsis: false,
            width: 150,
            render: (text: string) => Formatter.datetime(text)
        },
        {
            title: "Time To Live",
            dataIndex: "ttl",
            width: 100
        },
        {
            dataIndex: "actions",
            width: 25,
            align: "right" as const,
            render: (_: any, record: Token) => {
                return record.kind === TokenKind.User
                    ? (
                        <Space>
                            <DeleteButton
                                onConfirm={() => deleteToken(record.id)}
                            />
                        </Space>
                    )
                    : null;
            }
        }
    ];

    return (
        <>
            <div className="ant-form ant-form-vertical section">
                <Row className="section">
                    <Col span={24}>
                        <Table
                            bordered
                            size="small"
                            rowKey="id"
                            columns={columns}
                            dataSource={tokens}
                            pagination={false}
                        />
                    </Col>
                </Row>

                <Row className="sub-section">
                    <Col flex="100%">
                        <div className="sub-title">
                            Create new user token
                        </div>
                    </Col>
                </Row>

                <Row gutter={16} style={{ alignItems: "end" }}>
                    <Col flex="auto">
                        <Form.Item label="Name" >
                            <Input
                                value={tokenForm.name}
                                onChange={(event) => setTokenForm({ ...tokenForm, name: event.target.value })}
                            />
                        </Form.Item>
                    </Col>
                    <Col flex="auto">
                        <Form.Item label="Duration">
                            <InputNumber
                                style={{ width: "100%" }}
                                value={tokenForm.expiration}
                                onChange={(value) => setTokenForm({ ...tokenForm, expiration: value })}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item >
                            <Button
                                type="primary"
                                loading={tokenLoading}
                                onClick={createNewToken}
                            >
                                Create
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>

                <Row className="sub-section">
                    <Col span={24}>
                        <TokenDetails token={token} />
                    </Col>
                </Row>
            </div>
        </>
    );
};

const System: React.FC = () => {
    const { user } = useContext(AppContext);
    const userClassName = user && user.roles.includes(UserRole.Admin) ? "user-info role-admin" : "user-info role-user";

    return user
        ? (
            <PageWrapper className="system">
                <div className="header">
                    <UserLabel user={user} />
                </div>
                <Tabs>
                    <Tabs.TabPane tab={<span><KeyOutlined />Tokens</span>} key="tokens">
                        <TokenPane />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={<span><FireOutlined />State Of Health</span>} key="soh">
                        <SohPane />
                    </Tabs.TabPane>
                </Tabs>
            </PageWrapper>
        )
        : (
            <div style={{ marginTop: "64px", justifyContent: "center", display: "flex" }}>
                <Spin spinning={true} size="large" />
            </div>
        );
};

export { System };
