import React, { useEffect, useState } from 'react';

import { Parameter, Task, TaskFormData, TaskType } from '../../constants/types';
import useTaskForm from './useTaskForm';

import Button from 'antd/es/button';
import Modal from 'antd/es/modal';
import Space from 'antd/es/space';

import TaskEditor from './TaskEditor';
import Tooltip from 'antd/es/tooltip';

interface ControlComponentProps {
    onClick: () => void;
}

interface RunTaskDialogProps {
    title?: string;
    label?: string;
    taskType: TaskType;
    param?: Parameter;
    component?: React.ComponentType<ControlComponentProps>;
    hiddenFields?: string[];
    beforeShow?: () => Promise<any>;
}

const RunTaskDialog: React.FC<RunTaskDialogProps> = ({
    taskType,
    param,
    title,
    label,
    component,
    hiddenFields = [],
    beforeShow,
}) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [taskForm] = useTaskForm();
    const [initialData, setInitialData] = useState<TaskFormData | null>(null);

    useEffect(() => {
        taskForm.setOnTaskSaved((task: Task) => setVisible(false));
    }, [taskForm]);

    useEffect(() => {
        if (!initialData) {
            let input = undefined;
            if (param) {
                input = Object.fromEntries(
                    Object.entries(taskType.input)
                        .filter(([_, meta]) => meta.type === param.paramType.name && meta.required === true)
                        .map(([key, _]) => [key, param])
                );
            }
            setInitialData({
                input,
                taskType,
            });
        }
    }, [initialData, taskType, param]);

    const modalTitle = title ? (
        title
    ) : (
        <>
            Run Task: {taskType.name}, version {taskType.version}
        </>
    );

    const tooltipTitle = `Run ${taskType.name}, ${taskType.version}`;

    const Control = component ? component : Button;
    const controlLabel = label ? label : taskType.name;

    const onClick = beforeShow ? () => beforeShow().then(() => setVisible(true)) : () => setVisible(true);

    return (
        <div>
            <Tooltip title={tooltipTitle}>
                <Control onClick={onClick}>{controlLabel}</Control>
            </Tooltip>
            <Modal
                className="task-modal"
                width={'80vw'}
                style={{
                    maxWidth: '1000px',
                    minWidth: '800px',
                }}
                visible={visible}
                title={modalTitle}
                destroyOnClose={true}
                footer={
                    <div className="actions-toolbar">
                        <Space>
                            <Button onClick={() => setVisible(false)}>Cancel</Button>
                        </Space>
                        <Space>
                            <Button type="primary" onClick={() => taskForm.saveAndRun()}>
                                Save
                            </Button>
                        </Space>
                    </div>
                }
                onCancel={() => setVisible(false)}
            >
                <TaskEditor
                    taskType={taskType}
                    taskForm={taskForm}
                    initialData={initialData}
                    hiddenFields={['type', ...hiddenFields]}
                />
            </Modal>
        </div>
    );
};

export default RunTaskDialog;
