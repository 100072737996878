import React from "react";

import { RankingGrammarTextEditorProps } from "./types";

import Editor from "@monaco-editor/react";

import { MONACO_CONFIG } from "../../../constants";

const RankingGrammarTextEditor: React.FC<RankingGrammarTextEditorProps> = ({ value, onChange }) => {
    return (
        <div className="editor-wrapper">
            <Editor
                height="70vh"
                defaultLanguage="ruby"
                value={value}
                options={{ ...MONACO_CONFIG }}
                onChange={(v) => v && onChange(v)}
            />
        </div>
    );
};

export default RankingGrammarTextEditor;
