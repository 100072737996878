import {
    ParameterComponentMap,
    TaskParameterComponentMap
} from "../../constants/types";


import Checkbox from "./inputs/Checkbox";
import Number from "./inputs/Number";
import Text from "./inputs/Text";

import ParameterSelect from "./inputs/ParameterSelect";
import File from "./File";
import FileText from "./FileText";
import SearchQuery from "./SearchQuery";
import RankingGrammar from "./RankingGrammar";
import RankingReport from "./RankingReport";
import PatentSet from "./PatentSet";

import ParameterPreview from "./ParameterPreview";
import RankingOutput from "./RankingOutput";

const Empty = () => {
    return null;
};

const parametersAdd: ParameterComponentMap = {
    PatentSet: {
        component: File.Add,
        props: { emptyFilename: "patent_set.txt" },
        required: true
    },
    FileText: {
        component: File.Add,
        props: { emptyFilename: "file_text.txt" },
        required: true
    },
    RankingGrammar: {
        component: File.Add,
        props: { emptyFilename: "grammar.grm" },
        required: true
    },
    RankingPattern: {
        component: File.Add,
        props: { emptyFilename: "rankingpattern.json" },
        required: true
    },
    File: {
        component: File.Add,
        props: { emptyFilename: "file" },
        required: true
    },
    SearchQuery: {
        component: SearchQuery.Add,
        props: {},
        required: true
    },
    RankingReport: {
        component: Empty,
        props: {},
        required: false
    }
};

const parametersEdit: ParameterComponentMap = {
    File: {
        component: File.Edit,
        props: {},
        required: true
    },
    FileArchive: {
        component: File.Edit,
        props: {},
        required: true
    },
    FileText: {
        component: FileText.Edit,
        props: {},
        required: true
    },
    FileXlsx: {
        component: File.Edit,
        props: {},
        required: true
    },
    RankingGrammar: {
        component: RankingGrammar.Edit,
        props: {},
        required: true
    },
    RankingPattern: {
        component: File.Edit,
        props: {},
        required: true
    },
    RankingOutput: {
        component: RankingOutput.Edit,
        props: {},
        required: true
    },
    PatentSet: {
        component: PatentSet.Edit,
        props: {},
        required: true
    },
    SearchQuery: {
        component: SearchQuery.Edit,
        props: {},
        required: true
    },
    RankingReport: {
        component: RankingReport.Edit,
        props: {},
        required: true
    },
    FileLog: {
        component: FileText.Edit,
        props: {
            readonly: true
        },
        required: true
    },
};

const taskParameters: TaskParameterComponentMap = {
    Text: {
        component: Text,
        props: {}
    },
    Number: {
        component: Number,
        props: { step: "01" }
    },
    Boolean: {
        component: Checkbox,
        props: {}
    },
    PatentSet: {
        component: ParameterSelect,
        props: { paramType: "PatentSet" }
    },
    RankingGrammar: {
        component: ParameterSelect,
        props: { paramType: "RankingGrammar" }
    },
    RankingPattern: {
        component: ParameterSelect,
        props: { paramType: "RankingPattern" }
    },
    File: {
        component: ParameterSelect,
        props: { paramType: "File" }
    },
    FileText: {
        component: ParameterSelect,
        props: { paramType: "FileText" }
    },
    SearchQuery: {
        component: ParameterSelect,
        props: { paramType: "SearchQuery" }
    },
};

const ParameterComponents = {
    add: parametersAdd,
    edit: parametersEdit,
    tasks: taskParameters,
};

export {
    ParameterComponents,
    ParameterPreview
};
