import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';

import find from 'lodash/find';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import findIndex from 'lodash/findIndex';
import reverse from 'lodash/reverse';

import update from 'immutability-helper';

import axios from 'axios';

import { Id, Parameter, ParameterEditProps } from '../../../constants/types';
import {
    RankingOutputEntry,
    RankingReportHeader,
    RankingReportPatent,
    RankingReportImage,
} from './types';

import { RankingLanguages, RankingTypes, layout, tailLayout } from './constants';

import { getParameterFile, saveParamAndValue, upload } from '../../../api';

import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import Menu from 'antd/es/menu';
import Dropdown from 'antd/es/dropdown';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Space from 'antd/es/space';
import Tabs from 'antd/es/tabs';
import message from 'antd/es/message';
import Switch from 'antd/es/switch';

import DatePicker from '../../DatePicker';

import PlusOutlined from '@ant-design/icons/PlusOutlined';

import RankingPreview from './RankingPreview';
import ReportEditor from './ReportEditor';
import PatentEditor from './PatentEditor';
import dayjs from 'dayjs';
import { addPatentMainImage } from './utils';
import FileSaver from 'file-saver';
import Toolbar from '../../Toolbar';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { parseRankingOutput } from '../../../utils';

function fieldFromParam<Type>(param: Parameter, field: string, def: Type): Type {
    return param.data[field] ? (param.data[field] as Type) : def;
}

const fromParam = (param: any): RankingReportHeader => {
    return {
        title: fieldFromParam(param, 'title', ''),
        type: fieldFromParam(param, 'type', ''),
        author: fieldFromParam(param, 'author', ''),
        language: fieldFromParam(param, 'language', ''),
        date: fieldFromParam(param, 'date', null)
            ? dayjs(fieldFromParam(param, 'date', null))
            : null,
    };
};

const fetchRankingOutput = (projectId: Id): Promise<Parameter[]> =>
    axios.get(`/api/params?projectId=${projectId}&paramType=RankingOutput`);

interface RankingOutputDropdownProps {
    params: Parameter[];
    selected: (number | undefined)[];
    onClick: (value: number) => void;
}

const RankingOutputDropdown: React.FC<RankingOutputDropdownProps> = ({
    params,
    selected,
    onClick,
}) => {
    const items =
        params.length === 0
            ? [{ key: -1, label: 'No Ranking Found', disabled: true }]
            : (params.map((ro) => {
                  return {
                      key: ro.id,
                      label: `${ro.id} - ${ro.label}`,
                      disabled: selected.includes(ro.id || 0),
                  };
              }) as ItemType[]);

    const rankingOutputMenu = (
        <Menu onClick={({ key: id }) => onClick(parseInt(id))} items={items} />
    );

    return (
        <Dropdown overlay={rankingOutputMenu} trigger={['click']}>
            <Button size="small" icon={<PlusOutlined />}>
                Ranking Output
            </Button>
        </Dropdown>
    );
};

const RankingReportEdit: React.FC<ParameterEditProps> = ({ param: initialParam }) => {
    const [form] = Form.useForm();
    const [param, setParam] = useState<Parameter>(initialParam);
    const [label, setLabel] = useState<string>(initialParam.label);
    const [patents, setPatents] = useState<RankingReportPatent[]>(
        initialParam.data.patents || []
    );
    const [rankingOutputParams, setRankingOutputParams] = useState<Parameter[]>([]);
    const [rankings, setRankings] = useState<RankingOutputEntry[]>([]);
    const [editingPatent, setEditingPatent] = useState<RankingReportPatent | null>(null);
    const [activeTab, setActiveTab] = useState<string>('summary');
    const [saving, setSaving] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);
    const [isPrinting, setIsPrinting] = useState<boolean>(false);
    const [autosave, setAutosave] = useState<boolean>(false);
    const isSubscribed = useRef(true);

    const autosaveTimer: { current: NodeJS.Timeout | null } = useRef(null);

    // Unmount, remove subscription
    useEffect(() => {
        return () => {
            isSubscribed.current = false;
        };
    }, []);

    // Prepare form
    useEffect(() => {
        const reportHeader = fromParam(initialParam);
        form.setFieldsValue(reportHeader);
    }, [form, initialParam]);

    useEffect(() => {
        fetchRankingOutput(initialParam.project.id).then((ros) => {
            if (isSubscribed.current) {
                setRankingOutputParams(ros);
            }
        });
    }, [initialParam.project.id]);

    useEffect(() => {
        if (autosaveTimer.current) {
            clearTimeout(autosaveTimer.current);
            autosaveTimer.current = null;
        }

        if (autosave) {
            autosaveTimer.current = setInterval(() => {
                form.submit();
            }, 60000);
        }

        return () => {
            if (autosaveTimer.current) {
                clearInterval(autosaveTimer.current);
            }
        };
    }, [autosave, form]);

    const handleFinish = async (values: any) => {
        setSaving(true);
        const ps = await Promise.all(
            patents.map(async (patent: RankingReportPatent) => {
                if (!patent.images) {
                    return patent;
                }

                const images = await Promise.all(
                    patent.images.map(async (image: RankingReportImage) => {
                        if (!image.path && image.data) {
                            const response = await upload(
                                `ranking_report/${param.id}`,
                                image.data
                            );
                            if (response.status === 'OK') {
                                return {
                                    uid: image.uid,
                                    path: response.path,
                                };
                            }
                        }
                        return image;
                    })
                );

                return { ...patent, images };
            })
        );

        const newParam = {
            ...param,
            label,
            data: {
                ...values,
                patents: ps,
            },
        };

        saveParamAndValue(newParam)
            .then(() => {
                message.success('Report Saved');
                if (isSubscribed.current) {
                    setParam(newParam);
                    setPatents(newParam.data.patents);
                    setIsDirty(false);
                }
            })
            .catch((err) => {
                message.error(`Unable to save: ${err}`);
            })
            .finally(() => {
                if (isSubscribed.current) {
                    setSaving(false);
                }
            });
    };

    const openRankingOutput = (id: number) => {
        const parameter = find(rankingOutputParams, (el) => el.id === id) as Parameter;
        const alreadyLoaded = !!find(rankings, (rk) => rk.parameter.id === parameter.id);

        if (alreadyLoaded) {
            setActiveTab(`${parameter.id}`);
        } else {
            getParameterFile(id).then((data) => {
                if (!isSubscribed.current) {
                    return;
                }

                setRankings(
                    update(rankings, {
                        $push: [{ parameter, data: parseRankingOutput(data) }],
                    })
                );
                setActiveTab(`${parameter.id}`);
            });
        }
    };

    const openRankingPreview = (patent: RankingReportPatent) => {
        if (patent.rankingOutputId) {
            openRankingOutput(patent.rankingOutputId);
        }
    };

    const closeRankingOutput = (id: number) => {
        setRankings(filter(rankings, (r) => r.parameter.id !== id));
        setActiveTab('summary');
    };

    const addPatents = async (patentsToAdd: RankingReportPatent[]) => {
        setIsDirty(true);
        const ids = patents.map((p) => p.id);
        const ps = await Promise.all(
            patentsToAdd
                .filter((patent: RankingReportPatent) => !ids.includes(patent.id))
                .map((patent) => addPatentMainImage(patent))
        );

        setPatents(update(patents, { $push: ps }));
    };

    const updatePatent = (patent: RankingReportPatent) => {
        setIsDirty(true);
        const index = findIndex(patents, (p) => p.id === patent.id);
        setPatents(update(patents, { [index]: { $set: patent } }));
    };

    const movePatent = (startIndex: number, endIndex: number) => {
        setIsDirty(true);
        const dragRow = patents[startIndex];
        setPatents(
            update(patents, {
                $splice: [
                    [startIndex, 1],
                    [endIndex, 0, dragRow],
                ],
            })
        );
    };

    const sortPatentsByRating = () => {
        setIsDirty(true);
        setPatents(reverse(sortBy(patents, ['rating', 'rank'])));
    };

    const removePatent = (id: string) => {
        setIsDirty(true);
        setPatents(filter(patents, (p) => p.id !== id));
    };

    const printReport = async () => {
        setIsPrinting(true);
        try {
            const data = (await axios({
                url: '/api/services/ranking-report/document',
                method: 'POST',
                responseType: 'blob',
                data: param.data,
            })) as any;
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
            FileSaver.saveAs(blob, `${param.label}.docx`);
        } catch (error) {
            message.error('Unable to print report');
            console.error(error);
        }
        setIsPrinting(false);
    };

    const toggleAutosave = (enable: boolean) => {
        if (enable) {
            message.success('Autosave enabled');
        } else {
            message.warning('Autosave disabled');
        }
        setAutosave(enable);
    };

    return (
        <div className="param-edit ranking-report">
            <div className="param-edit-header">
                <Row style={{ width: '100%' }}>
                    <Col span={2} className="ant-form-item-label">
                        <label title="Label">Label</label>
                    </Col>
                    <Col span={22}>
                        <Input value={label} onChange={(e) => setLabel(e.target.value)} />
                    </Col>
                </Row>
            </div>

            <Form
                {...layout}
                id="report_form"
                form={form}
                onValuesChange={() => setIsDirty(true)}
                onFinish={handleFinish}
            >
                <Form.Item name={['title']} label="Title">
                    <Input
                    // initial  value={reportHeader.title}
                    />
                </Form.Item>

                <Form.Item name={['type']} label="Type">
                    <Select
                    //value={reportHeader.type}
                    >
                        {RankingTypes.map((t) => (
                            <Select.Option key={t.value} value={t.value}>
                                {t.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name={['language']} label="Language">
                    <Select
                    //value={reportHeader.language}
                    >
                        {RankingLanguages.map((l) => (
                            <Select.Option key={l.value} value={l.value}>
                                {l.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name={['author']} label="Author">
                    <Input
                    // value={reportHeader.author}
                    />
                </Form.Item>

                <Form.Item name={['date']} label="Date">
                    <DatePicker
                        // value={reportHeader.date}
                        style={{ width: '100%' }}
                        format="DD-MM-YYYY"
                    />
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Toolbar>
                        <Space>
                            <Button type="primary" htmlType="submit" loading={saving}>
                                Save
                            </Button>

                            <Button
                                type="default"
                                disabled={saving || isDirty}
                                loading={isPrinting}
                                onClick={printReport}
                            >
                                Print
                            </Button>
                        </Space>
                        <Space>
                            Autosave
                            <Switch checked={autosave} onChange={toggleAutosave} />
                        </Space>
                    </Toolbar>
                </Form.Item>
            </Form>

            <Tabs
                type="editable-card"
                size="small"
                onChange={setActiveTab}
                onEdit={(targetKey, action) => {
                    if (action === 'remove') {
                        closeRankingOutput(parseInt(targetKey as string));
                    }
                }}
                activeKey={activeTab}
                hideAdd={true}
                tabBarExtraContent={
                    <RankingOutputDropdown
                        params={rankingOutputParams}
                        selected={rankings.map((r) => r.parameter.id)}
                        onClick={openRankingOutput}
                    />
                }
                style={{ margin: '-16px', padding: '16px' }}
            >
                <Tabs.TabPane tab="Summary" key="summary" closable={false}>
                    <ReportEditor
                        patents={patents}
                        onRankingLinkClick={openRankingPreview}
                        onPatentAdd={(p) => addPatents([p])}
                        onPatentEdit={setEditingPatent}
                        onPatentRemove={removePatent}
                        onPatentMove={movePatent}
                        onPatentSort={sortPatentsByRating}
                    />
                </Tabs.TabPane>
                {rankings.map((rk, index) => (
                    <Tabs.TabPane
                        tab={`${rk.parameter.id} - ${rk.parameter.label}`}
                        key={rk.parameter.id}
                        closable={true}
                    >
                        <RankingPreview
                            ranking={rk}
                            reportPatents={patents}
                            onAdd={addPatents}
                            onRemove={removePatent}
                            onEdit={setEditingPatent}
                        />
                    </Tabs.TabPane>
                ))}
            </Tabs>
            {editingPatent && (
                <PatentEditor
                    visible={true}
                    patent={editingPatent}
                    accept={(patent) => {
                        updatePatent(patent);
                        setEditingPatent(null);
                    }}
                    reject={() => setEditingPatent(null)}
                />
            )}
        </div>
    );
};

export default RankingReportEdit;
