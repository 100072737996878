import React, { useContext, useState } from 'react';
import update from 'immutability-helper';

import { RankingReportPatent, RankingReportImage, ImageRotation } from './types';

import Modal from 'antd/es/modal';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Rate from 'antd/es/rate';
import Space from 'antd/es/space';

import RichTextEditor from '../../RichTextEditor';
import { VerticalScrollShadow } from '../../ScrollShadow';
import PatentGallery from './PatentGallery';
import { imageName, imageSrc } from './utils';
import { AppContext } from '../../../contexts';

interface TitleProps {
    patent: RankingReportPatent;
    onRatingChange: (value: number) => void;
}

const Title: React.FC<TitleProps> = ({ patent, onRatingChange }) => (
    <Space align='start'>
        {`${patent.id} - ${patent.title}`}
        <Rate className='patent-rating' allowHalf value={patent.rating} onChange={onRatingChange} />
    </Space>
);

interface PatentEditorProps {
    visible: boolean;
    patent: RankingReportPatent;
    accept: (patent: RankingReportPatent) => void;
    reject: () => void;
}

function rotatePatentImage(reportImage: RankingReportImage, imageRotation: ImageRotation, token: string | null | undefined, onComplete: (data: File) => void) {
    const rotation = imageRotation === ImageRotation.Left ? -0.5 * Math.PI : 0.5 * Math.PI;

    // Load image from data or from path
    const src = imageSrc(reportImage, token);
    const name = imageName(reportImage);

    if (!src) {
        return;
    }

    const img = new Image();
    img.src = src;

    img.onload = () => {
        const width = img.height;
        const height = img.width;

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');
        if (ctx) {
            ctx.translate(width / 2, height / 2);
            ctx.rotate(rotation);
            ctx.drawImage(img, -img.width / 2, -img.height / 2);
            canvas.toBlob((blob) => {
                if (blob) {
                    const data = new File([blob], name, { type: 'image/png' });
                    onComplete(data);
                }
            });
        }
    };
}

const PatentEditor: React.FC<PatentEditorProps> = ({ visible, patent: initialPatent, accept, reject }) => {
    const { downloadToken } = useContext(AppContext);
    const [patent, setPatent] = useState<RankingReportPatent>(initialPatent);

    const setField = (field: string, value: string | number) => {
        setPatent(update(patent, { [field]: { $set: value } }));
    };

    const onImageAdd = (image: RankingReportImage) => {
        setPatent(update(patent, { images: { $push: [image] } }));
    };

    const onImageRotate = (index: number, rotation: ImageRotation) => {
        const patentImage = patent.images[index];
        rotatePatentImage(patentImage, rotation, downloadToken ? downloadToken.id : null, (data: File) => {
            const image = { uid: patentImage.uid, patentImage, data };
            setPatent(
                update(patent, {
                    images: { [index]: { $set: image } }
                })
            );
        });
    };

    const onImageRemove = (imageIndex: number) => {
        if (imageIndex >= 0) {
            setPatent(update(patent, { images: { $splice: [[imageIndex, 1]] } }));
        }
    };

    return (
        <Modal
            className='ranking-report-patent-editor'
            width='95vw'
            centered
            destroyOnClose={true}
            visible={visible}
            title={<Title patent={patent} onRatingChange={(value) => setField('rating', value)} />}
            onOk={() => accept(patent)}
            onCancel={() => reject()}
        >
            <Row gutter={0}>
                <Col span={16} >
                    <div className='patent-text-container'>
                        <VerticalScrollShadow style={{ height: '80vh' }}>
                            <RichTextEditor
                                title='Comment'
                                value={patent.comment}
                                onChange={(value) => setField('comment', value)}
                            />
                            <RichTextEditor
                                title='Abstract'
                                value={patent.abstract}
                                onChange={(value) => setField('abstract', value)}
                            />
                            <RichTextEditor
                                title='Claim'
                                value={patent.claim}
                                onChange={(value) => setField('claim', value)}
                            />
                        </VerticalScrollShadow>
                    </div>
                </Col>
                <Col span={8}>
                    <PatentGallery
                        patent={patent}
                        token={downloadToken ? downloadToken.id : null}
                        onAdd={onImageAdd}
                        onRotate={onImageRotate}
                        onRemove={onImageRemove}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default PatentEditor;
