import React, { useCallback, useEffect, useRef, useState } from 'react';

import find from 'lodash/find';
import filter from 'lodash/filter';

import { fetchPatents, getParameterFile, getRelatedByType } from '../../../api';

import { PatentDocument } from '../../../constants/types';
import { RankingReportPatent, RankingOutput } from './types';
import { RankingRecord } from '../RankingOutput/types';
import Table, { ColumnsType } from 'antd/lib/table';

import Button from 'antd/es/button';
import Dropdown from 'antd/es/dropdown';
import Input, { InputRef } from 'antd/es/input';
import Space from 'antd/es/space';
import message from 'antd/es/message';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import CaretDownOutlined from '@ant-design/icons/CaretDownOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import {
    FilterDropdownProps,
    Key,
    TableCurrentDataSource,
    TablePaginationConfig,
} from 'antd/lib/table/interface';

import PatentSummary from './PatentSummary';
import RatingMenu from './RatingMenu';
import RegexMatch from './RegexMatch';
import { merge } from './utils';
import { isArray } from 'lodash';
import { Grammar, GrammarState } from '../RankingGrammar/grammar';
import RankingGrammarPreview from '../RankingGrammar/RankingGrammarPreview';
import TableUtils from '../../TableUtils';
import R2Highlighter from '../../R2Highlighter';

interface RankingPreviewControlsProps {
    record: RankingRecord;
    editable: boolean;
    loading: boolean;
    onAdd: (patent: RankingRecord, rating: number) => void;
    onRemove: (patent: RankingRecord) => void;
    onEdit: (patent: RankingRecord) => void;
}

const RankingPreviewControls: React.FC<RankingPreviewControlsProps> = ({
    record,
    editable,
    loading,
    onAdd,
    onRemove,
    onEdit,
}) => {
    if (loading) {
        return null;
    }

    if (editable) {
        return (
            <Space>
                <Button
                    size="small"
                    danger
                    type="primary"
                    icon={<DeleteOutlined />}
                    onClick={() => onRemove(record)}
                />
                <Button
                    size="small"
                    icon={<EditOutlined />}
                    onClick={() => onEdit(record)}
                />
            </Space>
        );
    }

    return (
        <Dropdown.Button
            overlay={<RatingMenu onClick={(rating: number) => onAdd(record, rating)} />}
            onClick={() => onAdd(record, 3)}
            icon={<CaretDownOutlined />}
            size="small"
            trigger={['click']}
        >
            <PlusOutlined />
        </Dropdown.Button>
    );
};

// extract id from records
const idsFromData = (records: RankingRecord[]) => {
    return records.map((el) => el.id);
};

const toHtml = (text: string): string => `<div><em>&quot;${text}&quot;</em></div>`;

const toRichText = (patent?: PatentDocument): PatentDocument => {
    const abstract = patent ? toHtml(patent.abstract) : '';
    const claims = patent && patent.claims ? patent.claims.map((c) => toHtml(c)) : [];

    return { ...patent, abstract, claims } as PatentDocument;
};

const RankingPreviewPageSize = 20;

const loadPatents = (
    records: RankingRecord[],
    reportPatents: RankingReportPatent[]
): Promise<Map<string, PatentDocument>> => {
    const reportPatentIds = reportPatents.map((rp) => rp.id);
    const recordToLoad = filter(records, (record) => !reportPatentIds.includes(record.id));
    const ids = idsFromData(recordToLoad);
    return fetchPatents(ids);
};

const mergePatents = (
    patents: Map<string, PatentDocument>,
    reportPatents: RankingReportPatent[],
    rankingId: number
): Map<string, RankingReportPatent> => {
    const merged = new Map(reportPatents.map((p) => [p.id, p]));
    patents.forEach((patent, id) =>
        merged.set(id, merge(toRichText(patent), undefined, rankingId))
    );
    return merged;
};

interface RankingOutputProps {
    ranking: RankingOutput;
    reportPatents: RankingReportPatent[];
    onAdd: (patents: RankingReportPatent[]) => void;
    onRemove: (id: string) => void;
    onEdit: (patent: RankingReportPatent) => void;
}

const RankingPreview: React.FC<RankingOutputProps> = ({
    ranking,
    reportPatents,
    onAdd,
    onRemove,
    onEdit,
}) => {
    const [visible, setVisible] = useState<RankingRecord[]>(
        ranking.data.slice(0, RankingPreviewPageSize)
    );
    const [grammar, setGrammar] = useState<Grammar | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const searchInput = useRef<InputRef>(null);
    const isSubscribed = useRef(true);
    const patents = useRef(new Map());

    // Unmount, remove subscription
    useEffect(() => {
        return () => {
            isSubscribed.current = false;
        };
    }, []);

    // Update related grammar if any
    useEffect(() => {
        getRelatedByType(ranking.parameter.id!, 'RankingGrammar').then((grammarParams) => {
            if (isSubscribed.current === false || grammarParams.length === 0) {
                return;
            }
            const grammarParam = grammarParams[0];
            getParameterFile(grammarParam.id!).then((data) => {
                if (isSubscribed.current) {
                    const [grm] = GrammarState.createFromString(data);
                    setGrammar(grm);
                }
            });
        });
    }, [ranking.parameter.id]);

    // Update patents ref
    useEffect(() => {
        const ps = new Map(patents.current);
        reportPatents.forEach((p) => {
            ps.set(p.id, p);
        });
        patents.current = ps;
    }, [reportPatents]);

    // Fetch visible patents
    useEffect(() => {
        if (visible.length === 0) {
            return;
        }
        loadPatents(visible, reportPatents)
            .then((data) => {
                if (!isSubscribed.current) {
                    return;
                }
                const rankingOutputId = ranking.parameter.id || 0;
                const merged = mergePatents(
                    new Map([...patents.current, ...data]),
                    reportPatents,
                    rankingOutputId
                );
                patents.current = merged;
            })
            .catch((error) => message.error(error.message));
    }, [ranking.parameter.id, visible, reportPatents]);

    const handleSearch = (selectedKeys: Key[], confirm: () => void, dataIndex: string) => {
        confirm();
        setSearchText(selectedKeys[0].toString());
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const addAll = () => {
        const reportPatentIds = reportPatents.map((rp) => rp.id);
        const recordToAdd = filter(
            visible,
            (record) => !reportPatentIds.includes(record.id)
        );
        onAdd(
            recordToAdd.map((record) =>
                merge(patents.current.get(record.id), record, ranking.parameter.id || 0, 3)
            )
        );
    };

    const addPatent = (record: RankingRecord, rating: number) => {
        onAdd([
            merge(
                patents.current.get(record.id),
                record,
                ranking.parameter.id || 0,
                rating
            ),
        ]);
    };

    const removePatent = (record: RankingRecord) => {
        onRemove(record.id);
    };

    const editPatent = (record: RankingRecord) => {
        const patent = find(reportPatents, { id: record.id });
        if (patent) {
            onEdit(patent);
        }
    };

    const rowClassName = (record: RankingRecord) => {
        const reportPatent = find(reportPatents, { id: record.id });
        if (reportPatent) {
            return reportPatent.rankingOutputId === ranking.parameter.id
                ? 'success'
                : 'warning';
        }
        return '';
    };

    const isEditable = (record: RankingRecord): boolean => {
        return !!find(reportPatents, (p) => p.id === record.id);
    };

    const isLoading = (record: RankingRecord): boolean => {
        return !patents.current.get(record.id);
    };

    const onPageChange = (
        pagination: TablePaginationConfig,
        _filters: any,
        _sorter: any,
        extra: TableCurrentDataSource<RankingRecord>
    ) => {
        const ps = pagination.pageSize || RankingPreviewPageSize;
        const current = pagination.current || 1;
        const start = ps * (current - 1);
        const end = ps * current;
        const records = extra.currentDataSource.slice(start, end);
        setVisible(records);
    };

    const groups = ranking.data && ranking.data[0].grank.map((g: any, idx: number) => idx);

    const columns: ColumnsType<RankingRecord> = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 175,
            sorter: (a: any, b: any) => (a.id > b.id ? -1 : 1),
            sortDirections: ['descend', 'ascend'],
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }: FilterDropdownProps) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Search`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => handleSearch(selectedKeys, confirm, 'id')}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => handleSearch(selectedKeys, confirm, 'id')}
                            icon={<SearchOutlined />}
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                confirm({ closeDropdown: false });
                                setSearchText(selectedKeys[0].toString());
                            }}
                        >
                            Filter
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: TableUtils.filterIcon,
            onFilter: (
                value: string | number | boolean,
                record: RankingRecord
            ): boolean => {
                const strValue = value.toString().toLowerCase();
                const id = record.id;
                return id ? id.toString().toLowerCase().includes(strValue) : false;
            },
            onFilterDropdownVisibleChange: (v: boolean) => {
                if (v) {
                    setTimeout(
                        () =>
                            searchInput &&
                            searchInput.current &&
                            searchInput.current.focus(),
                        100
                    );
                }
            },
            render: (text: string) => <R2Highlighter search={searchText} text={text} />,
        },
        {
            title: 'Global Ranking',
            dataIndex: 'rank',
            key: 'rank',
            width: 150,
            sorter: (a: any, b: any) => a.rank - b.rank,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Group Rankings',
            dataIndex: 'grank',
            children: groups.map((group: number) => {
                return {
                    title: `G${group + 1}`,
                    dataIndex: ['grank', group],
                    width: 75,
                    sorter: (a: any, b: any) => a.grank[group] - b.grank[group],
                    render: (value) => (
                        <RankingGrammarPreview
                            grammar={grammar}
                            group={group}
                            value={value}
                        />
                    ),
                };
            }),
        },
        {
            title: 'Title',
            dataIndex: 'id',
            key: 'id',
            fixed: 'right',
            width: 320,
            render: (id) => <PatentSummary patent={patents.current.get(id)} />,
        },
        {
            title: (
                <Button size="small" icon={<PlusOutlined />} onClick={() => addAll()}>
                    All
                </Button>
            ),
            fixed: 'right',
            width: 75,
            render: (record: RankingRecord) => (
                <RankingPreviewControls
                    record={record}
                    editable={isEditable(record)}
                    loading={isLoading(record)}
                    onAdd={addPatent}
                    onRemove={removePatent}
                    onEdit={editPatent}
                />
            ),
        },
    ];

    return (
        <div>
            <Table
                bordered
                dataSource={ranking.data}
                columns={columns}
                rowKey="id"
                size="small"
                scroll={{ x: 'max-content' }}
                pagination={{
                    pageSize: RankingPreviewPageSize,
                    showSizeChanger: false,
                }}
                rowClassName={rowClassName}
                expandable={{
                    expandedRowRender: (record) => <RegexMatch record={record} />,
                    rowExpandable: (record) => !record.regex && isArray(record.regex),
                }}
                onChange={onPageChange}
            />
        </div>
    );
};

export default RankingPreview;
