import { useQuery } from "react-query";
import { SimilarityData, SimilarityResponse, SynonymClassModels, getSynonymifierClassesModels, getSynonyms } from "../../../api";
import List from "antd/es/list";
import Radio, { RadioChangeEvent } from "antd/es/radio";
import { Col, Row } from "antd/es/grid";
import { useState } from "react";
import Divider from "antd/es/divider";
import { Spin } from "antd";
import Alert from "antd/es/alert";

interface SynonymifierProps {
    word: string;
    onSelected: (word: string) => void;
    onClose?: () => void;
}


const Synonymifier: React.FC<SynonymifierProps> = ({ word, onSelected, onClose }) => {
    const [selectedModel, setSelectedModel] = useState<string>("");
    const [selectedClass, setSelectedClass] = useState<string>("");
    const [error, setError] = useState<string | null>(null);
    const [similarities, setSimilarities] = useState<SimilarityData[]>([]);
    const [searching, setSearching] = useState<boolean>(false);
    const { data: classModelMap } = useQuery<SynonymClassModels>(
        ["classes-models"],
        getSynonymifierClassesModels,
        {
            initialData: { models: {} },
            notifyOnChangeProps: ["data"],
            refetchIntervalInBackground: false,
            refetchOnWindowFocus: false,
            refetchInterval: 60000
        }
    );

    if (!classModelMap?.models) {
        return null;
    }


    const onChangeSelection = ({ target: { value } }: RadioChangeEvent) => {
        setSelectedModel(value);
        setSearching(true);
        setError(null);
        getSynonyms(word, value)
            .then((data: SimilarityResponse) => setSimilarities(data.similarity_data))
            .catch((err) => setError(err.message))
            .finally(() => setSearching(false));
    };

    const onRadioClick = (key: string) => {
        setSelectedClass(key);
    };

    const classNameSet = Object.keys(classModelMap?.models);

    return (
        <Row>
            <Col span={3}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <h3>Classes</h3>
                    <p>Select a class</p>
                    {
                        classNameSet.length &&
                        <Radio.Group
                            style={{ display: "flex", flexDirection: "column" }}
                            onChange={onChangeSelection}
                            buttonStyle="solid">
                            {
                                classNameSet.map((key) => (
                                    <Radio.Button
                                        key={key}
                                        checked={classModelMap?.models[key] === selectedModel || false}
                                        style={{ margin: "5px 0px" }}
                                        disabled={searching}
                                        onClick={(e: any) => onRadioClick(key)}
                                        value={classModelMap?.models[key]}>
                                        {key}
                                    </Radio.Button>
                                ))
                            }
                        </Radio.Group>
                    }
                </div>
            </Col>
            <Col span={21}>
                {
                    selectedClass !== '' &&
                    <Row>
                        <Col offset={1} span={23}>
                            <h5>Selected class: {selectedClass}</h5>
                            <Spin style={{ width: "100%" }} tip={`Loading synonyms of ${word} in ${selectedClass}`} spinning={searching} size="large" ></Spin>
                            {error && <Alert message={error} type="error" />}
                            <Row>
                                <Col span={4}>
                                    <Radio.Group
                                        style={{ display: "flex", flexDirection: "column" }}
                                        onChange={({ target: { value } }) => onSelected(value)}
                                        buttonStyle="solid">
                                        {
                                            similarities.map((similar, index) => (
                                                <Radio.Button
                                                    key={index}
                                                    // checked={classModelMap?.models[key] === selectedModel || false}
                                                    style={{ margin: "5px 0px" }}
                                                    // disabled={searching}
                                                    value={similar.chunk}>
                                                    {`${similar.chunk} (${similar.similarity_score.toFixed(4)})`}
                                                </Radio.Button>
                                            ))
                                        }
                                    </Radio.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </Col >
        </Row >

    );
};

export default Synonymifier;
