import React, { useContext, useState } from 'react';
import axios from 'axios';

import { Patent, PatentDocument, EmptyPatent, ParameterEditProps } from '../../../constants/types';
import { fetchPatent } from '../../../api';

import { useSearch, DoSearch } from '../../../hooks/useSearch';
import { RunTaskDialog } from '../../tasks';

import { AppContext } from '../../../contexts';

import find from 'lodash/find';

import { Affix, Button, Dropdown, Input, Space, Spin, Row, Col, Menu, message } from 'antd';

import DownOutlined from '@ant-design/icons/DownOutlined';
import SaveOutlined from '@ant-design/icons/SaveOutlined';

import { SearchCounter, ErrorMessage, SearchPagination } from '../../Search';
import { PatentViewer, PatentList } from '../../Patent';
import ToolsDrawer from '../../ToolDrawer';
import { useParameter } from '../../../hooks/useParameter';

const doSearch: DoSearch<Patent, void> = (search) => axios.post('/api/services/pa/search/patents', search);

const SearchQueryEdit: React.FC<ParameterEditProps> = ({ param: initialParam }) => {
    const { taskTypes } = useContext(AppContext);
    const { param, setParam, saving, save, saveAsNewParam } = useParameter(initialParam);
    const { search, setSearch, loading, error, results } = useSearch<Patent>(doSearch, false);
    const [selectedPatent, setSelectedPatent] = useState<PatentDocument | null>(null);
    const [patentLoading, setPatentLoading] = useState<boolean>(false);

    const solrSearchTaskType = find(taskTypes, (taskType) => taskType.type === 'SolrSearch');
    const solrSearchFilterTaskType = find(taskTypes, (taskType) => taskType.type === 'SolrSearchFilter');

    const searchPreview = () => {
        const s = { ...search };
        s.query = param.data.value;
        setSearch(s);
    };

    const showPatent = async (patentId: string) => {
        setPatentLoading(true);
        setSelectedPatent(EmptyPatent);
        const patentDocument = await fetchPatent(patentId);
        setPatentLoading(false);
        if (patentDocument) {
            setSelectedPatent(patentDocument);
        } else {
            message.error(`Unable to load patent ${patentId}`);
        }
    };

    const patents = results?.docs;
    const showCounter = search.query !== '';

    return (
        <div className="param-edit search-query">
            <Row gutter={16}>
                <Col span={12}>
                    <div className="param-edit-header">
                        <div className="label">
                            <Input
                                value={param.label}
                                onChange={(e) => setParam({ ...param, label: e.target.value })}
                            />
                        </div>
                    </div>

                    <div>
                        <Input.TextArea
                            value={param.data.value}
                            rows={8}
                            onChange={(e) => {
                                const p = { ...param };
                                p.data.value = e.target.value.replace(/(\r\n|\n|\r)+(?!$)/gm, ' ');
                                setParam(p);
                            }}
                        />
                    </div>

                    <div className="param-edit-footer">
                        <div className="actions-toolbar">
                            <Space>
                                <Dropdown.Button
                                    type="primary"
                                    disabled={saving}
                                    loading={saving}
                                    onClick={saveAsNewParam}
                                    icon={<DownOutlined />}
                                    overlay={
                                        <Menu
                                            items={[{ key: 'override', label: 'Override', icon: <SaveOutlined /> }]}
                                            onClick={save}
                                        />
                                    }
                                >
                                    Save
                                </Dropdown.Button>

                                <Button
                                    type="primary"
                                    ghost={true}
                                    disabled={saving}
                                    loading={loading}
                                    onClick={searchPreview}
                                >
                                    Search
                                </Button>
                            </Space>

                            <Space>
                                {solrSearchTaskType && (
                                    <RunTaskDialog
                                        label={'Save Patent Set'}
                                        component={({ onClick, children }) => (
                                            <Button onClick={onClick}>{children}</Button>
                                        )}
                                        taskType={solrSearchTaskType}
                                        param={param}
                                        hiddenFields={['search_query']}
                                        beforeShow={saveAsNewParam}
                                    />
                                )}

                                {solrSearchFilterTaskType && (
                                    <RunTaskDialog
                                        label={'Save Filtered Patent Set'}
                                        component={({ onClick, children }) => (
                                            <Button onClick={onClick}>{children}</Button>
                                        )}
                                        taskType={solrSearchFilterTaskType}
                                        param={param}
                                        hiddenFields={['search_query']}
                                        beforeShow={saveAsNewParam}
                                    />
                                )}
                            </Space>
                        </div>
                    </div>
                    <div className="search">
                        <SearchCounter loading={loading} show={showCounter} count={results.num_found} />
                        <ErrorMessage error={error} loading={true} message={'System error, try again later...'}>
                            <Spin spinning={loading} size="large">
                                <Row className="item-container">
                                    <Col span={24}>
                                        <PatentList
                                            query={search.query}
                                            loading={loading}
                                            patents={patents}
                                            onSelect={showPatent}
                                        />
                                        <SearchPagination
                                            results={results}
                                            onChange={(page, page_size) =>
                                                setSearch({ ...search, page, page_size: page_size || 10 })
                                            }
                                        />
                                    </Col>
                                </Row>
                            </Spin>
                        </ErrorMessage>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="search">
                        <Affix offsetTop={64}>
                            <PatentViewer loading={patentLoading} patent={selectedPatent} markers={[]} />
                        </Affix>
                    </div>
                </Col>
            </Row>
            <ToolsDrawer />
        </div>
    );
};

export default SearchQueryEdit;
