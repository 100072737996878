import { createContext } from "react";
import { AppContextState } from "../constants/types";

export const AppContext = createContext<AppContextState>({
    initialized: false,
    projects: [],
    paramTypes: [],
    availableParamTypes: [],
    taskTypes: [],
    runningTasks: [],
    apiServices: [],
    getProjects: () => { },
    serviceIsAvailable: (serviceName: string) => false,
    logout: () => { }
});
