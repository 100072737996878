import React, { useEffect, useState } from 'react';

import filter from 'lodash/filter';

import { Entity, Id } from '../../../constants/types';

import Select from 'antd/es/select';


interface EntitySelectProps {
    value: Entity | Entity[];
    onChange: (value: undefined | Entity | Entity[]) => void;
    options: { label: string; value: Id }[];
    allkey?: string;
    style?: any;
}

const EntitySelect: React.FC<EntitySelectProps> = ({
    value,
    onChange,
    options,
    allkey = 'all',
    ...rest
}) => {
    const [val, setVal] = useState<undefined | Id | Id[]>();

    useEffect(() => {
        if (!value) {
            setVal(value);
            return;
        }
        let _val = undefined;
        if (Array.isArray(value)) {
            _val = value.map(({ id }: Entity) => id);
        } else if (typeof value === 'object') {
            _val = value.id;
        }
        setVal(_val);
    }, [value]);

    const handleChange = (values: Id | Id[]) => {
        let response = undefined;
        if (Array.isArray(values)) {
            let keys = [...values];
            if (keys.includes(allkey)) {
                keys = filter(options, (o) => o.value !== allkey).map(
                    ({ value: _value }) => _value
                );
            }
            response = keys.map((id) => ({ id }));
        } else if (typeof values === 'string' || typeof values === 'number') {
            response = { id: values };
        }
        onChange(response);
    };
    return (
        <Select allowClear value={val} onChange={handleChange} {...rest} options={options} />
    );
};

export default EntitySelect;
