import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router';

import Layout from 'antd/es/layout';
import Menu, { MenuProps } from 'antd/es/menu';

import { AppContext } from '../contexts';

import logoSmall from '../resources/logo.png';

import ProjectSelector from './ProjectSelector';
import UserIndicator from './UserIndicator';
import StatusIndicator from './StatusIndicator';

const { Header, Content } = Layout;

interface NavigationMenuItem {
    label: string;
    path: string;
    requiredService?: string;
}

interface NavigationMenuProps {
    items: NavigationMenuItem[];
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ items }) => {
    const { serviceIsAvailable } = useContext(AppContext);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const menuItems = items
        .filter((item) =>
            item.requiredService
                ? serviceIsAvailable(item.requiredService)
                : true
        )
        .map((item) => {
            return {
                label: item.label,
                key: item.path,
            };
        });

    const selectedKeys = items
        .filter((item) => pathname.startsWith(item.path))
        .map((item) => item.path);

    const onClick: MenuProps['onClick'] = (e) => navigate(e.key);

    return (
        <Menu
            className="navigation-menu"
            mode="horizontal"
            selectedKeys={selectedKeys}
            items={menuItems}
            onClick={onClick}
        />
    );
};

const NavigationItems: NavigationMenuItem[] = [
    {
        label: 'Search Patents',
        path: '/search/patents',
        requiredService: 'PatentApiNONE',
    },
    {
        label: 'Search Parameters',
        path: '/search/parameters',
        requiredService: 'PatentApiNONE',
    },
    {
        label: 'Projects',
        path: '/projects',
    },
    {
        label: 'Parameters',
        path: '/parameters',
    },
    {
        label: 'Tasks',
        path: '/tasks',
    },
];

const LayoutWrapper = ({ children }: any) => {
    const navigate = useNavigate();

    return (
        <Layout>
            <Header id="patsearch-header">
                <span className="logo" onClick={() => navigate('/')}>
                    <img src={logoSmall} className="header-logo" />
                    <h5>Patsearch</h5>
                </span>
                <div className="navigation-menu-container">
                    <NavigationMenu items={NavigationItems} />
                </div>
                <div className="context">
                    <StatusIndicator />
                    <ProjectSelector />
                    <UserIndicator />
                </div>
            </Header>
            <Content>{children}</Content>
        </Layout>
    );
};

export default LayoutWrapper;
