import React, { ReactNode } from "react";

import Button from "antd/es/button";
import Popover from "antd/es/popover";
import { TooltipPlacement } from "antd/es/tooltip";

import { isArray } from "lodash";

import QuestionCircleTwoTone from "@ant-design/icons/QuestionCircleTwoTone";

export interface HelpMenuSection {
    label: string | ReactNode;
    items?: string[] | ReactNode;
}

interface HelpMenuContentProps {
    sections: HelpMenuSection[];
}

const HelpMenuContent: React.FC<HelpMenuContentProps> = ({ sections }) => (
    <div className='help-popover'>
        {
            sections.map((section, i) => (
                <div className="section" key={i}>
                    <div className="label">
                        {section.label}
                    </div>
                    <pre className="items">
                        {
                            isArray(section.items)
                                ? section.items.map((item, j) => <code key={j}>{item}</code>)
                                : section.items
                        }
                    </pre>
                </div>
            ))
        }
    </div>
);

interface HelpMenuProps extends HelpMenuContentProps {
    placement?: TooltipPlacement;
}

const HelpMenu: React.FC<HelpMenuProps> = ({ placement, sections }) => (
    <Popover
        placement={placement}
        trigger="click"
        content={<HelpMenuContent sections={sections} />}
    >
        <Button icon={<QuestionCircleTwoTone />} type="link" />
    </Popover>
);

export default HelpMenu;
