import React, { useState } from "react";

import Form from "antd/es/form";
import Input from "antd/es/input";
import InputNumber from "antd/es/input-number";

import { ParamType, ProjectId } from "../../../constants/types";
import { PatentSetToolProps } from "./PatentSetTool";

import Tool from "../../tools/Tool";

import { patentSetRandomSubset, saveNewPatentSet } from "./utils";

const saveRandomSubSet = (
    label: string,
    paramType: ParamType,
    projectId: ProjectId,
    patentSet: Set<string>
) => saveNewPatentSet(
    label,
    paramType,
    projectId,
    patentSet,
    "Saved Random SubSet"
);

const RandomSubSetTool: React.FC<PatentSetToolProps> = ({ param, patentIds }) => {
    const [subSetSize, setSubSetSize] = useState<number>(patentIds.size);
    const [label, setLabel] = useState<string>(`${param.label}-RandomSubSet`);

    const save = () => {
        return saveRandomSubSet(
            label,
            param.paramType,
            param.project,
            patentSetRandomSubset(patentIds, subSetSize)
        );
    };

    return (
        <Tool
            title="Random Sub Set"
            label="Random Sub Set"
            onSave={save}
        >
            <Form.Item label={"Label"} labelCol={{ flex: "100px" }}>
                <Input
                    value={label}
                    onChange={(event) => setLabel(event.target.value)}
                    style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label={"Sub Set Size"} labelCol={{ flex: "100px" }}>
                <InputNumber
                    min={1}
                    max={patentIds.size}
                    defaultValue={patentIds.size}
                    onChange={(value) => setSubSetSize(value || 0)}
                    style={{ width: "100%" }} />
            </Form.Item>
        </Tool>
    );
};

export default RandomSubSetTool;
