import Input from "antd/es/input";
import Tooltip from "antd/es/tooltip";
import InputNumber from "antd/es/input-number";
import { useEffect, useState } from "react";
import cloneDeep from 'lodash/cloneDeep';
import zip from 'lodash/zip';
import { parseNumber } from "../../../utils";
import Button from "antd/es/button";
import Checkbox from "antd/es/checkbox";
import { FontSizeOutlined } from '@ant-design/icons';
import Drawer from "antd/es/drawer";
import Space from "antd/es/space";
import Synonymifier from "./Synonymifier";
import Modal from "antd/es/modal";
import Radio from "antd/es/radio";
import { SynomymAddMode } from "./types";

type RowType = "Reversed" | "Regular";

interface RowItem {
    words: string[],
    proximities: number[],
    reverse: boolean
}

const START_WITH_PROXYMITY = /^\|\d+\|/;
const START_WITH_PROXYMITY_REVERSE = /^\|<%\d+\|/;

const MATCH_PROXYMITY = /\|\d+\|/g;
const MATCH_PROXYMITY_REVERSE = /\|<%\d+\|/g;
const SINGLE_TERM_VALIDATOR = /^[0-9A-Za-zÀ-ÖØ-öø-ÿ-\s,_]*$/;


const MATCH_PROXIMITY_MAP = {
    "Reversed": MATCH_PROXYMITY_REVERSE,
    "Regular": MATCH_PROXYMITY
};

const CLEANUP_PROXIMITY_MAP = {
    "Reversed": (p: string) => parseNumber(p.replace('|', '').replace('<%', '')),
    "Regular": (p: string) => parseNumber(p.replace('|', ''))
};

const rowDataClassificator = (data: string): RowType => {
    /*
    if (data.match(START_WITH_PROXYMITY)) {
        throw Error(`Error parse: ${data}`);
    }

    if (data.match(START_WITH_PROXYMITY_REVERSE)) {
        throw Error(`Error parse: ${data}`);
    }*/

    const couldBeReverse = data.match(MATCH_PROXYMITY_REVERSE);
    const couldBeRegular = data.match(MATCH_PROXYMITY);

    if (couldBeReverse && couldBeRegular) {
        throw Error(`Error parse: must not be regular & reversed at the same time (${data})`);
    }

    if (couldBeReverse) {
        return "Reversed";
    }

    return "Regular";
};

const toRowItem = (data: string): RowItem => {
    const rowType = rowDataClassificator(data);

    const regex = MATCH_PROXIMITY_MAP[rowType];
    const cleanUpFunc = CLEANUP_PROXIMITY_MAP[rowType];

    const words = data.split(regex).map((p) => {
        if (!p.match(SINGLE_TERM_VALIDATOR)) {
            throw Error(`Error parse: ivalid term format (${p})`);
        }
        return p;
    });
    const proximities = (data.match(regex) || []).map(cleanUpFunc);

    return { words, proximities, reverse: rowType === "Reversed" };
};

interface LightEditableWordInputProps {
    word: string,
    index: number,
    onWordChange: (index: number, value: string) => void
    onSynonymAdd: (addingType: SynomymAddMode, synonym: string) => void
}

const LightEditableWordInput: React.FC<LightEditableWordInputProps> = ({
    word,
    index,
    onWordChange,
    onSynonymAdd
}: any) => {
    const [open, setOpen] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [synonym, setSynonym] = useState<string>("");
    const [synonymAddMode, setSynonymAddMode] = useState<SynomymAddMode>("Cell");

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = (e: any): void => {
        setOpen(false);
    };

    const onSelectedSynonym = (s: string): void => {
        setSynonym(s);
        setOpenModal(true);
    };

    const onSynonymOk = (e: any) => {
        /*
        if (synonymAddMode === "Row") {
            onSynonymAdd(synonymAddMode, synonym);
            return;
        }

        const newWord = `${word},${synonym}`;
        onWordChange(index, newWord);*/

        onSynonymAdd(synonymAddMode, synonym);
        setOpenModal(false);
    };

    return (
        <>
            <Input
                className="lightEditorWordCell"
                addonAfter={(<Tooltip placement="top" title={"Click to serach for similar words."}><FontSizeOutlined onClick={showDrawer} /></Tooltip>)}
                onChange={(event) => onWordChange(index, event.target.value)}
                style={{ width: '150px', margin: '0px 5px' }}
                key={`w_${index}`}
                value={word}
            />
            <Drawer
                title={(<h5>Choose synonym of <strong>{word}</strong></h5>)}
                placement="top"
                width={500}
                height={"100%"}
                onClose={onClose}
                visible={open}
                destroyOnClose={true}
                extra={
                    <Space>
                        <Button type="primary" onClick={onClose}>
                            Close
                        </Button>
                    </Space>
                }
            >
                <Synonymifier
                    onSelected={onSelectedSynonym}
                    word={word}
                />
            </Drawer>
            <Modal title={`Add ${synonym} to ?`} visible={openModal} onOk={onSynonymOk} onCancel={() => setOpenModal(false)}>
                <Radio.Group onChange={({ target: { value } }) => setSynonymAddMode(value)} value={synonymAddMode}>
                    <Space direction="vertical">
                        <Radio value={"Row"}>Add in a new row</Radio>
                        <Radio value={"Cell"}>Add in the same cell</Radio>
                    </Space>
                </Radio.Group>
            </Modal>
        </>
    );
};

const toRowComponents = (row: RowItem, onWordChange: (index: number, value: string) => void, onProximityChange: (index: number, value: number) => void, onSynonymAdd: (columnId: number, addingType: SynomymAddMode, synonym: string) => void) => {
    const wordComponents = row.words.map((w, i) => (<LightEditableWordInput onSynonymAdd={(addingType: SynomymAddMode, synonym: string) => onSynonymAdd(i, addingType, synonym)} key={`lewi_${i}`} onWordChange={onWordChange} word={w} index={i} />));
    const proximityComponents = row.proximities.map((w, i) => (<InputNumber onChange={(value) => onProximityChange(i, value || 1)} style={{ width: '50px' }} key={`n_${i}`} value={w} />));

    return zip(wordComponents, proximityComponents).flatMap((c) => c);
};

const toGrammarStringTerm = (row: RowItem): string => {
    const glue = row.reverse ? '<%' : '';

    return zip(row.words, row.proximities.map(p => `|${glue}${p}|`)).flatMap((c) => c).join('');
};

interface LightEditableCellProps {
    value: string;
    onCellChange: (value: string) => void;
    onSynonymAddInNewRow: (synonym: string) => void;
    status?: "error" | "warning"
}

const LightEditableCell: React.FC<LightEditableCellProps> = ({
    value: initialValue,
    onCellChange,
    onSynonymAddInNewRow,
    status
}: any) => {
    const [row, _setRow] = useState<RowItem>(toRowItem(initialValue));

    useEffect(() => {
        _setRow(toRowItem(initialValue));
    }, [initialValue]);

    const setRow = (_row: RowItem): void => {
        const grammarStringTerm = toGrammarStringTerm(_row);
        onCellChange(grammarStringTerm);

        // _setRow(_row);
    };

    const addTermInput = () => {
        const newRow = cloneDeep(row);

        newRow.words.push('');
        newRow.proximities.push(1);

        setRow(newRow);
    };

    const removeTermInput = () => {
        const newRow = cloneDeep(row);

        newRow.words.pop();
        newRow.proximities.pop();

        setRow(newRow);
    };

    const onReverseChange = (isReverse: boolean) => {
        const newRow = cloneDeep(row);

        newRow.reverse = isReverse;

        setRow(newRow);
    };

    const onWordChange = (index: number, value: string) => {
        if (!value.match(SINGLE_TERM_VALIDATOR)) {
            return;
        }

        const newRow = cloneDeep(row);

        newRow.words[index] = value;

        setRow(newRow);
    };

    const onProximityChange = (index: number, value: number) => {
        const newRow = cloneDeep(row);

        newRow.proximities[index] = value;

        setRow(newRow);
    };

    const onSynonymAddInColumn = (columnId: number, addingType: SynomymAddMode, synonym: string) => {
        if (addingType === "Row") {
            onSynonymAddInNewRow(synonym);
            return;
        }

        const newRow = cloneDeep(row);
        const addingWord = `${newRow.words[columnId]},${synonym}`;

        newRow.words[columnId] = addingWord;

        setRow(newRow);
    };

    const components = toRowComponents(row, onWordChange, onProximityChange, onSynonymAddInColumn);

    return (
        <>
            {components}
            {
                <>
                    {row.words.length < 4 &&
                        <Button
                            style={{ margin: '0px 2px' }}
                            onClick={addTermInput}
                        >+</Button>
                    }
                    {row.words.length > 1 &&
                        <>
                            <Button
                                style={{ margin: '0px 2px' }}
                                onClick={removeTermInput}
                            >-</Button>
                            <div style={{ display: 'inline-block', textAlign: 'center' }}>
                                <label>Reverse</label><br />
                                <Checkbox checked={row.reverse} onChange={(event) => onReverseChange(event.target.checked)} />
                            </div>
                        </>
                    }
                </>
            }
        </>
    );
};

export default LightEditableCell;
