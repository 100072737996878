import React, { PropsWithChildren, ReactNode, useContext } from "react";

import { AppContext } from "../contexts";
import { Task, userFullName } from "../constants/types";

import Badge from "antd/es/badge";
import Popover from "antd/es/popover";
import Progress from "antd/es/progress";
import Space from "antd/es/space";

import InfoCircleOutlined from "@ant-design/icons/InfoCircleOutlined";
import NotificationFilled from "@ant-design/icons/NotificationFilled";

const EmptyItem: React.FC = () => (
    <div className='status-item'>
        <div className="empty-message">
            <Space>
                <InfoCircleOutlined /> No Task Running
            </Space>
        </div>
    </div>
);

interface TaskItemProps {
    task: Task;
}

const TaskItem: React.FC<TaskItemProps> = ({ task }) => {
    const progress = task.progress ? Math.round(task.progress) : 0.0;

    return (
        <>
            <tr>
                <td>
                    {task.id}
                </td>
                <td>
                    {task.taskType.name}
                </td>
                <td>
                    {task.label}
                </td>
                <td>
                    {userFullName(task.user)}
                </td>
                <td>
                    {task.project.name}
                </td>
            </tr>
            <tr>
                <td colSpan={5} className="progress">
                    <Progress percent={progress} size="small" />
                </td>
            </tr>

        </>
    );
};

const StatusContentContainer: React.FC<PropsWithChildren<any>> = ({ children }) => {
    return (
        <div className="status-indicator-content">
            <div className="info">
                {children}
            </div>
        </div>
    );
};

const renderContent = (tasks: Task[]): React.ReactNode => {
    if (tasks.length === 0) {
        return (
            <StatusContentContainer>
                <EmptyItem />
            </StatusContentContainer>
        );
    }

    const items = tasks.map(t => <TaskItem key={t.id} task={t} />);

    return (
        <StatusContentContainer>
            <table >
                <thead>
                    <tr>
                        <th>
                            Id
                        </th>
                        <th>
                            Type
                        </th>
                        <th>
                            Label
                        </th>
                        <th>
                            User
                        </th>
                        <th>
                            Project
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {items}
                </tbody>
            </table>
        </StatusContentContainer>
    );
};

const StatusIndicator: React.FC = () => {
    const { runningTasks: tasks } = useContext(AppContext);

    return (
        <Popover
            overlayClassName="status-indicator-overlay"
            content={() => renderContent(tasks)}
            overlayStyle={{ position: "fixed" }}
            trigger={["click"]}
            destroyTooltipOnHide={true}
        >
            <Badge
                dot={tasks.length > 0}
                style={{ top: "7px" }}
                color="blue"
            >
                <NotificationFilled
                    className="notification"
                />
            </Badge>
        </Popover>
    );
};

export default StatusIndicator;
