import React from 'react';
import dayjs from 'dayjs';

import ReactJson from 'react-json-view';

interface JsonProps {
    json: any;
}

const JSON_CLASSNAME = {
    propertyClassName: 'json-property',
    colonClassName: 'json-colon',
    className: 'json',
    tabSpaceClassName: 'json-tab',
    numberClassName: 'json-number',
    stringClassName: 'json-string',
    booleanClassName: 'json-boolean',
    trueClassName: 'json-true',
    falseClassName: 'json-false',
    nullClassName: 'json-null',
    commaClassName: 'json-comma',
    braceClassName: 'json-brace',
    bracketClassName: 'json-bracket'
};

const JSon: React.FC<JsonProps> = ({ json }) => <ReactJson
    src={json}
    enableClipboard={false}
    displayDataTypes={false}
    displayObjectSize={false}
/>;

const datetime = (text: string | undefined) => text ? dayjs(text).format('DD-MM-YYYY HH:mm') : '---';

const date = (text: string, def: any = '---') => text ? dayjs(text).format('DD-MM-YYYY') : def;

export default {
    JSon,
    datetime,
    date
};
