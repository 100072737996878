import React, { useCallback, useEffect, useState } from 'react';

import {
    GrammarItem,
    GrammarMetadata,
    RankingGrammarEditorProps,
    RankingGrammarLightEditorProps,
    SynomymAddMode,
} from './types';
import { Grammar, GrammarState } from './grammar';

import Alert from 'antd/es/alert';
import Button from 'antd/es/button';
import Space from 'antd/es/space';
import Spin from 'antd/es/spin';

import PushpinOutlined from '@ant-design/icons/PushpinOutlined';
import DeleteButton from '../../DeleteButton';
import { Select } from 'antd';
import { RANKING_GROUP_RELEVANCE_CONFIG } from '../../../constants';
import cloneDeep from 'lodash/cloneDeep';
import Tooltip from 'antd/es/tooltip';
import { RankingRelevance } from '../../../constants/types';
import EditableCell from './EditableCell';
import LightEditableCell from './LightEditableCell';
import { ErrorBoundary } from 'react-error-boundary';
import { DEFAULT_GROUP_RELEVANCE_ID } from './constants';

interface DeleteGroupButtonCellProps {
    item: GrammarItem;
    onClick: (item: GrammarItem) => void;
}

const DeleteGroupButtonCell: React.FC<DeleteGroupButtonCellProps> = ({ item, onClick }) => {
    return <DeleteButton size="middle" onConfirm={() => onClick(item)} />;
};

interface EditableTableProps {
    items: GrammarItem[];
    onCellChange: (rowIndex: number, columnId: string, value: number | string) => void;
    onRowDelete?: (itemIndex: number) => void;
    onSynonymAddInNewRow: (synonym: string) => void;
    rankingRelevance?: RankingRelevance | null;
}

const EditableTable = ({
    items,
    onCellChange,
    onRowDelete,
    onSynonymAddInNewRow,
    rankingRelevance,
}: EditableTableProps) => {
    const isValidWeight = rankingRelevance
        ? rankingRelevance.validityrange.isInRange.bind(rankingRelevance.validityrange)
        : () => true;
    const message = rankingRelevance ? rankingRelevance.validityrange.errorMessage() : '';

    return (
        <table className="grammar-table">
            <tbody>
                {items.map((item: GrammarItem, itemIndex: number) => {
                    const isValid = isValidWeight(item.weight);
                    const status = !isValid ? 'error' : undefined;
                    return (
                        <tr key={`${itemIndex}`}>
                            <td style={{ verticalAlign: 'top' }}>
                                <ErrorBoundary
                                    fallbackRender={({ error, resetErrorBoundary }) => {
                                        return (
                                            <div role="alert">
                                                <p>Something went wrong:</p>
                                                <pre style={{ color: 'red' }}>{error.message}</pre>
                                            </div>
                                        );
                                    }}
                                >
                                    <div style={{ display: 'flex' }}>
                                        <LightEditableCell
                                            value={item.text}
                                            onCellChange={(value) => onCellChange(itemIndex, 'text', value)}
                                            onSynonymAddInNewRow={(synonym: string) => onSynonymAddInNewRow(synonym)}
                                        />
                                    </div>
                                </ErrorBoundary>
                            </td>
                            <td style={{ verticalAlign: 'top', width: '96px' }}>
                                <Tooltip visible={!isValid} title={message} color="red">
                                    <EditableCell
                                        status={status}
                                        number
                                        value={item.weight}
                                        onCellChange={(value) => onCellChange(itemIndex, 'weight', value)}
                                    />
                                </Tooltip>
                            </td>
                            <td style={{ verticalAlign: 'top', width: '32px' }}>
                                <DeleteGroupButtonCell
                                    item={item}
                                    onClick={() => onRowDelete && onRowDelete(itemIndex)}
                                />
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

const createEmptyGrammarMetadata = (): GrammarMetadata => {
    return {
        groups: {},
    };
};

const getGroupRelevanceConfig = (grammarMetadata: GrammarMetadata, groupNum: number): RankingRelevance | null => {
    if (grammarMetadata.groups && grammarMetadata.groups[groupNum]) {
        const relevanceId = grammarMetadata.groups[groupNum]?.relevanceId;
        if (relevanceId && RANKING_GROUP_RELEVANCE_CONFIG.has(relevanceId)) {
            return RANKING_GROUP_RELEVANCE_CONFIG.get(relevanceId) || null;
        }
    }

    return null;
};

const getGroupWeight = (grammarMetadata: GrammarMetadata, groupNum: number): number => {
    const relevanceConfig = getGroupRelevanceConfig(grammarMetadata, groupNum);
    if (relevanceConfig) {
        return relevanceConfig.baseValue;
    }

    return 1;
};

const setGrammarMetadataGroupRelevance = (
    grammarMetadata: GrammarMetadata,
    groupNum: number,
    relevanceId: number
): GrammarMetadata => {
    const gm = cloneDeep(grammarMetadata);

    gm.groups[groupNum] = { relevanceId };

    return gm;
};

/*
const appendSynonymToGrammar = (grammarMetadata: GrammarMetadata, groupNum: number, rowIndex: number, columnId: number, addingType: SynomymAddMode, synonym: string): GrammarMetadata => {
    const gm = cloneDeep(grammarMetadata);

    gm.groups.set(groupNum, { relevanceId });

    return gm;
};*/

const RankingGrammarLightEditor: React.FC<RankingGrammarLightEditorProps> = ({
    loading,
    value: grammar,
    error,
    onChange,
    metadata: grammarMetadata,
    onMetadataChange: setGrammarMetadata,
}) => {
    const [showErrorDetails, setShowErrorDetails] = useState<boolean>(true);
    const [stickyGroup, setStickyGroup] = useState<number>(-1);

    const setGrammar = useCallback(
        (grm: Grammar) => {
            onChange(grm);
        },
        [onChange]
    );

    const deleteGroup = useCallback(
        (groupNum: number) => {
            if (groupNum < stickyGroup) {
                setStickyGroup(stickyGroup - 1);
            }
            if (groupNum === stickyGroup) {
                setStickyGroup(-1);
            }
            const grm = GrammarState.deleteGroup(grammar, groupNum);
            setGrammar(grm);
        },
        [grammar, setGrammar, stickyGroup, setStickyGroup]
    );

    const insertGroup = useCallback(() => {
        const grm = GrammarState.addGroup(grammar);

        const groupNum = GrammarState.lastGroupNun(grm);

        const newGrammap = setGrammarMetadataGroupRelevance(grammarMetadata, groupNum, DEFAULT_GROUP_RELEVANCE_ID);

        setGrammarMetadata(newGrammap);

        setGrammar(grm);
    }, [grammar, setGrammar, grammarMetadata, setGrammarMetadata]);

    const addItem = useCallback(
        (groupNum: number) => {
            const weight = getGroupWeight(grammarMetadata, groupNum);
            setGrammar(GrammarState.addItem(grammar, groupNum, weight));
        },
        [grammar, setGrammar, grammarMetadata]
    );

    const deleteItem = useCallback(
        (groupNum: number, itemIndex: number) => {
            setGrammar(GrammarState.deleteItem(grammar, groupNum, itemIndex));
        },
        [grammar, setGrammar]
    );

    const onCellChange = useCallback(
        (groupNum: number, rowIndex: number, columnId: string, val: string | number) => {
            setGrammar(GrammarState.update(grammar, groupNum, rowIndex, columnId, val));
        },
        [grammar, setGrammar]
    );

    const toggleSticky = (groupNum: number) => {
        if (stickyGroup === groupNum) {
            setStickyGroup(-1);
        } else {
            setStickyGroup(groupNum);
        }
    };

    const groupClassName = (groupNum: number): string => {
        return stickyGroup === groupNum ? 'grammar-group sticky' : 'grammar-group';
    };

    const toggleErrorDetails = () => {
        setShowErrorDetails(!showErrorDetails);
    };

    const relevanceOptions = Array.from(RANKING_GROUP_RELEVANCE_CONFIG).map(([k, rc]) => ({
        value: k,
        label: rc.label,
    }));

    const updateGroupRelevance = (groupNum: number, relevanceId: number) => {
        const newGrammap = setGrammarMetadataGroupRelevance(grammarMetadata, groupNum, relevanceId);
        const weight = RANKING_GROUP_RELEVANCE_CONFIG.get(relevanceId)?.baseValue || 1;

        setGrammarMetadata(newGrammap);

        setGrammar(GrammarState.setGroupWeights(grammar, groupNum, weight));
    };

    const onSynonymAddInNewRow = (synonym: string, groupNum: number) => {
        const weight = getGroupWeight(grammarMetadata, groupNum);

        const item: GrammarItem = {
            text: synonym,
            annotation: '',
            weight,
        };

        const newGrm = GrammarState.addItem(grammar, groupNum, weight, item);

        setGrammar(newGrm);
    };

    return (
        <Spin spinning={loading} size="large">
            <div className="grammar-container">
                {grammar.map((items: GrammarItem[], groupNum: number) => {
                    const relevanceId =
                        grammarMetadata.groups[groupNum] && grammarMetadata.groups[groupNum].relevanceId
                            ? grammarMetadata.groups[groupNum].relevanceId
                            : DEFAULT_GROUP_RELEVANCE_ID;

                    return (
                        <div key={groupNum} className={groupClassName(groupNum)}>
                            <div className="grammar-group-header">
                                <Space>
                                    <strong>Group #{groupNum + 1}</strong>
                                    <Select
                                        size="small"
                                        defaultValue={DEFAULT_GROUP_RELEVANCE_ID}
                                        value={relevanceId}
                                        style={{ width: 120 }}
                                        onChange={(value) => updateGroupRelevance(groupNum, value)}
                                        options={relevanceOptions}
                                    />
                                </Space>
                                <Space className="controls">
                                    <DeleteButton onConfirm={() => deleteGroup(groupNum)}>Delete Group</DeleteButton>
                                    <Button
                                        size="small"
                                        type={stickyGroup === groupNum ? 'primary' : 'default'}
                                        icon={<PushpinOutlined />}
                                        onClick={() => toggleSticky(groupNum)}
                                    />
                                </Space>
                            </div>
                            <div className="grammar-group-content">
                                <EditableTable
                                    items={items}
                                    onCellChange={(rowIndex, columnId, val) =>
                                        onCellChange(groupNum, rowIndex, columnId, val)
                                    }
                                    onSynonymAddInNewRow={(synonym: string) => onSynonymAddInNewRow(synonym, groupNum)}
                                    onRowDelete={(itemIndex) => deleteItem(groupNum, itemIndex)}
                                    rankingRelevance={getGroupRelevanceConfig(grammarMetadata, groupNum)}
                                />
                                <div className="grammar-group-footer">
                                    <Button size="small" onClick={() => addItem(groupNum)}>
                                        Add Row
                                    </Button>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="param-edit-footer">
                <Button size="small" onClick={() => insertGroup()}>
                    Add Group
                </Button>
            </div>
        </Spin>
    );
};

export default RankingGrammarLightEditor;
