import React, { useContext, useState } from "react";
import { AppContext } from "../contexts";

import Select from "antd/es/select";
import Input from "antd/es/input";
import Divider from "antd/es/divider";

const ProjectSelector: React.FC = () => {
    const {
        projects,
        activeProjectId,
        onProjectChange,
    } = useContext(AppContext);
    const [filter, setFilter] = useState<string | null | undefined>(null);

    const items =
        projects
            .filter(
                (project) => {
                    return filter
                        ? project.label.toLowerCase().search(filter) !== -1
                        : true;
                }
            );

    return (
        <Select
            className="project-selector"
            size="small"
            placeholder="Projects"
            onChange={onProjectChange}
            dropdownStyle={{ position: "fixed" }}
            value={activeProjectId as number}
            dropdownRender={menu => (
                <>
                    <div
                        style={{
                            padding: '4px 8px',
                        }}
                    >
                        <Input.Search
                            placeholder='Search...'
                            style={{
                                width: '100%',
                            }}
                            allowClear={true}
                            onChange={(e) => setFilter(e.target.value.toLowerCase())}
                        />
                    </div>
                    <Divider style={{ margin: '8px 0' }} />
                    {menu}
                </>
            )}
            options={items}
        />
    );
};

export default ProjectSelector;
