import React from 'react';

import { MetadataInterface } from '../constants/types';

import Tag from 'antd/es/tag';

interface MetadataProps {
    obj: MetadataInterface | undefined;
}

const Metadata: React.FC<MetadataProps> = ({ obj }) =>
    obj && obj.metadata && obj.metadata.patents ? <Tag color="gold">{`patents: ${obj.metadata.patents}`}</Tag> : null;

export default Metadata;
