import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import Alert from "antd/es/alert";

import { PageWrapper } from "../../components/PageWrapper";
import { Parameter } from "../../constants/types";
import { getParameter } from "../../api";
import { ParameterComponents } from "../../components/parameters";

const crumbs = [
    { label: "Parameters", path: "/parameters" },
    { label: "Edit Parameter" },
];

const ParametersEdit: React.FC = ({ }) => {
    const { id } = useParams<"id">();
    const [param, setParam] = useState<Parameter>();

    useEffect(() => {
        if (id) {
            getParameter(id)
                .then((p) => setParam(p));
        }
    }, [id]);

    const paramTypeDefinition = param
        ? ParameterComponents.edit[param.paramType.name]
        : {
            component: () => null,
            props: {}
        };

    return (
        <PageWrapper crumbs={crumbs}>
            {
                param && (
                    ParameterComponents.edit[param.paramType.name]
                        ? React.createElement(
                            paramTypeDefinition.component,
                            {
                                param,
                                ...paramTypeDefinition.props
                            }
                        )
                        : <Alert message={`${param.paramType.name} edit not implemented`} type="error" />
                )
            }
        </PageWrapper>
    );
};

export default ParametersEdit;
