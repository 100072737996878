
import { TaskComponentMap } from '../../constants/types';
import PatentSetFilterForm from './PatentSetFilter';
import SolrSearchFilterForm from './SolrSearchFilter';
import { TaskFormRendererProps } from './types';

import RunTaskDialog from './RunTaskDialog';
import TaskEditor from './TaskEditor';
import useTaskForm from './useTaskForm';

const TaskFormComponents: TaskComponentMap<TaskFormRendererProps> = {
    PatentSetFilter: {
        component: PatentSetFilterForm,
        props: {}
    },
    SolrSearchFilter: {
        component: SolrSearchFilterForm,
        props: {}
    }
};

export {
    useTaskForm,
    RunTaskDialog,
    TaskEditor,
    TaskFormComponents
};
