import { Dayjs } from "dayjs";
import { Parameter, PatentDocument } from "../../../constants/types";
import { RankingRecord } from "../RankingOutput/types";

export interface RankingReportHeader {
    title: string;
    type: string;
    author: string;
    language: string;
    date: Dayjs | null;
}

export interface RankingReportImage {
    uid: string;
    name?: string;
    path?: string;
    data?: File;
}

export interface RankingReportPatent extends PatentDocument, RankingRecord {
    rankingOutputId?: number;
    rating: number;
    claim: string;
    comment: string;
    images: RankingReportImage[];
}

export interface RankingOutput {
    parameter: Parameter,
    data: RankingRecord[]
}

export interface RankingOutputEntry {
    parameter: Parameter,
    data: RankingRecord[]
}

export interface RankingReportHeader {
    title: string;
    type: string;
    author: string;
    language: string;
    date: Dayjs | null;
}

export enum ImageRotation {
    Left,
    Right
}
