import React, { useContext, useEffect, useState } from 'react';

import Button from 'antd/es/button';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Modal from 'antd/es/modal';
import Skeleton from 'antd/es/skeleton';
import Space from 'antd/es/space';
import Table from 'antd/es/table';

import CloudDownloadOutlined from '@ant-design/icons/CloudDownloadOutlined';

import { ColumnsType } from 'antd/lib/table';

import { Parameter, ParameterEditProps, PatentDocument } from '../../../constants/types';

import {
    downloadParameterFile,
    fetchPatent,
    getParameterFile,
    getRelatedByType,
    saveParamAndValue,
} from '../../../api';

import { parseRankingOutput } from '../../../utils';

import { RankingRecord } from './types';

import { PatentViewer } from '../../Patent';

import { Grammar, GrammarState } from '../RankingGrammar/grammar';
import RankingGrammarPreview from '../RankingGrammar/RankingGrammarPreview';
import { AppContext } from '../../../contexts';

const RankingOutputEdit: React.FC<ParameterEditProps> = ({ param: initialParam }) => {
    const { downloadToken } = useContext(AppContext);
    const [param, setParam] = useState<Parameter>(initialParam);
    const [loading, setLoading] = useState<boolean>(false);
    const [records, setRecords] = useState<RankingRecord[]>([]);
    const [grammar, setGrammar] = useState<Grammar | null>(null);
    const [selectedPatentId, setSelectedPatentId] = useState<string | null>(null);
    const [patentLoading, setPatentLoading] = useState<boolean>(false);
    const [patent, setPatent] = useState<PatentDocument | null>(null);

    useEffect(() => {
        let isSubscribed = true;
        setLoading(true);
        getParameterFile(initialParam.id!).then((data) => {
            if (isSubscribed) {
                setLoading(false);
                setRecords(parseRankingOutput(data));
            }
        });
        getRelatedByType(initialParam.id!, 'RankingGrammar').then((grammarParams) => {
            if (isSubscribed && grammarParams.length > 0) {
                const grammarParam = grammarParams[0];
                getParameterFile(grammarParam.id!).then((data) => {
                    if (isSubscribed) {
                        const [grm] = GrammarState.createFromString(data);
                        setGrammar(grm);
                    }
                });
            }
        });
        return () => {
            isSubscribed = false;
        };
    }, [initialParam]);

    useEffect(() => {
        let isSubscribed = true;
        if (selectedPatentId === null) {
            setPatent(null);
            return;
        }
        setPatentLoading(true);
        fetchPatent(selectedPatentId).then((p) => {
            if (isSubscribed) {
                setPatentLoading(false);
                setPatent(p);
            }
        });
        return () => {
            isSubscribed = false;
        };
    }, [selectedPatentId]);

    const save = () => {
        saveParamAndValue(param)
            .then(() => {
                message.success('Saved parameter');
            })
            .catch((error) => {
                message.error('Unable to save param');
            });
    };

    const showPatent = (patentId: string) => {
        setSelectedPatentId(patentId);
    };

    const hidePatent = () => {
        setSelectedPatentId(null);
    };

    const groups =
        records && records.length > 0
            ? records[0].grank.map((g: any, idx: number) => idx)
            : [];

    const columns: ColumnsType<RankingRecord> = [
        {
            title: 'Patent ID',
            dataIndex: 'id',
            key: 'id',
            fixed: 'left',
            width: 175,
            sorter: (a: any, b: any) => (a.id > b.id ? -1 : 1),
            sortDirections: ['descend', 'ascend'],
            render: (id) => (
                <Button type="link" onClick={() => showPatent(id)}>
                    {id}
                </Button>
            ),
        },
        {
            title: 'Global Ranking',
            dataIndex: 'rank',
            key: 'rank',
            fixed: 'left',
            width: 150,
            sorter: (a: any, b: any) => a.rank - b.rank,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'Group Rankings',
            dataIndex: 'grank',
            children: groups.map((group: number) => {
                return {
                    title: `G${group + 1}`,
                    dataIndex: ['grank', group],
                    width: 75,
                    sorter: (a: any, b: any) => a.grank[group] - b.grank[group],
                    render: (value) => (
                        <RankingGrammarPreview
                            grammar={grammar}
                            group={group}
                            value={value}
                        />
                    ),
                };
            }),
        },
    ];

    return (
        <div className="param-edit param-ranking-output">
            <div className="param-edit-header">
                <Input
                    value={param.label}
                    onChange={(e) => setParam({ ...param, label: e.target.value })}
                />
            </div>

            <div className="param-edit-content">
                <Table
                    bordered
                    dataSource={records}
                    columns={columns}
                    rowKey="id"
                    size="small"
                    scroll={{ x: 'max-content' }}
                    pagination={{
                        defaultPageSize: 50,
                        responsive: true,
                        size: 'default',
                        showSizeChanger: true,
                    }}
                />
            </div>

            <div className="param-edit-footer">
                <Space>
                    <Button type="primary" onClick={() => save()}>
                        Save
                    </Button>
                    <Button
                        onClick={() =>
                            param.id && downloadParameterFile(param.id, downloadToken)
                        }
                        icon={<CloudDownloadOutlined />}
                    >
                        Download
                    </Button>
                </Space>
            </div>

            <Modal
                wrapClassName="patent-viewer-modal-wrap"
                className={'patent-viewer-modal'}
                width={'80vw'}
                visible={!!selectedPatentId}
                onCancel={hidePatent}
                footer={null}
                closable={false}
            >
                {patentLoading ? (
                    <Skeleton paragraph={{ rows: 6 }} active={patentLoading} />
                ) : (
                    <PatentViewer loading={patentLoading} patent={patent} />
                )}
            </Modal>
        </div>
    );
};

export default RankingOutputEdit;
