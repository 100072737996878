import React, { useRef, useState } from "react";
import { Resizable } from "re-resizable";

import {
    Editor,
    EditorState,
    ContentState,
    RichUtils,
    convertFromHTML
} from "draft-js";
import "draft-js/dist/Draft.css";

import { convertToHTML } from "draft-convert";

import Button from "antd/es/button";
import Space from "antd/es/space";

import UnderlineOutlined from "@ant-design/icons/UnderlineOutlined";
import BoldOutlined from "@ant-design/icons/BoldOutlined";
import ItalicOutlined from "@ant-design/icons/ItalicOutlined";
import StrikethroughOutlined from "@ant-design/icons/StrikethroughOutlined";

interface TitleProps {
    title?: string;
}

const Title: React.FC<TitleProps> = ({ title }) => {
    return title
        ? <div className="title" onMouseDown={e => e.preventDefault()}>{title}</div>
        : null;
};

interface MarkButtonProps {
    style: string;
    currentStyle: Immutable.Set<string>;
    icon: React.ReactNode;
    onToggle: (style: string) => void
}

const ToolBarButton: React.FC<MarkButtonProps> = ({ style, currentStyle, icon, onToggle }) => {
    const type = currentStyle.contains(style) ? "primary" : "default";
    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        onToggle(style);
    };
    return (
        <Button size="small" type={type} icon={icon} onMouseDown={onClick} />
    );
};

interface ToolBarProps {
    currentStyle: Immutable.Set<string>;
    onStyleToggle: (style: string) => void
}

const ToolBar: React.FC<ToolBarProps> = ({ currentStyle, onStyleToggle }) => (
    <div className="toolbar" onMouseDown={e => e.preventDefault()}>
        <Space>
            <ToolBarButton
                style="BOLD"
                currentStyle={currentStyle}
                icon={<BoldOutlined />}
                onToggle={onStyleToggle}
            />
            <ToolBarButton
                style="ITALIC"
                currentStyle={currentStyle}
                icon={<ItalicOutlined />}
                onToggle={onStyleToggle}
            />
            <ToolBarButton
                style="UNDERLINE"
                currentStyle={currentStyle}
                icon={<UnderlineOutlined />}
                onToggle={onStyleToggle}
            />
            <ToolBarButton
                style="STRIKETHROUGH"
                currentStyle={currentStyle}
                icon={<StrikethroughOutlined />}
                onToggle={onStyleToggle}
            />
        </Space>
    </div>
);

interface RichTextEditorProps {
    title?: string;
    value: string | null;
    resizable?: boolean;
    onChange?: (value: string) => void
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({ title, value, resizable = false, onChange }) => {
    const blocksFromHTML = convertFromHTML(value || "");
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
    );

    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const editor = useRef<Editor>(null);
    const [focus, setFocus] = useState<boolean>(false);

    const focusEditor = () => {
        if (editor && "current" in editor && editor.current) {
            editor.current.focus();
        }
        setFocus(true);
    };

    const toHtml = convertToHTML({
        styleToHTML: (style) => { },
        blockToHTML: (block) => {
            if (['PARAGRAPH', 'unstyled'].includes(block.type)) {
                return <div />;
            }
        }
    });

    const onBlur = () => {
        setFocus(false);
        if (onChange) {
            const v = toHtml(editorState.getCurrentContent());
            onChange(v);
        }
    };

    const toggleStyle = (style: string) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };

    const currentStyle = editorState.getCurrentInlineStyle();
    const className = focus
        ? "rich-text-editor focus"
        : "rich-text-editor";


    const EditArea = (
        <div className="edit-area">
            <Editor
                ref={editor}
                editorState={editorState}
                onChange={setEditorState}
                placeholder=""
            />
        </div>
    );

    const EditAreaWrapper = resizable
        ? (
            <Resizable
                className="edit-area-wrapper resizable"
                handleWrapperClass="handle"
                maxWidth="100%"
                minWidth="100%"
                defaultSize={{ width: "100%", height: 150 }}
                enable={{
                    top: true,
                    right: false,
                    bottom: true,
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false
                }}>
                {EditArea}
            </Resizable>
        )
        : (
            <div className="edit-area-wrapper">
                {EditArea}
            </div>
        );

    return (
        <div className={className}
            onClick={focusEditor}
            onBlur={onBlur}>
            <div className="editor">
                <Title title={title} />
                <ToolBar currentStyle={currentStyle} onStyleToggle={toggleStyle} />
                {EditAreaWrapper}
            </div>
        </div>
    );
};

export default RichTextEditor;
